import { QuantumMetrics } from '../../../../../common/utils/hydration';
import { isQuantumEligible } from './specifications';

const SCRIPT_ID = 'quantum-script';

function removeExistingScript() {
    const currentScript = document.getElementById(SCRIPT_ID);
    if (currentScript) {
        currentScript.remove();
    }
}

function createQuantumScript(quantumMetrics: QuantumMetrics) {
    const script = document.createElement('script');

    script.setAttribute('id', SCRIPT_ID);
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('async', 'true');
    script.setAttribute('src', quantumMetrics?.scriptUrl);
    script.setAttribute('integrity', quantumMetrics?.integrity);
    script.setAttribute('crossorigin', 'anonymous');

    return script;
}

function addQuantumScript(quantumMetrics: QuantumMetrics) {
    const quantumScript = createQuantumScript(quantumMetrics);
    document.body.appendChild(quantumScript);
}

export function loadQuantumScript(quantumMetrics?: QuantumMetrics) {
    if (!quantumMetrics || !isQuantumEligible(quantumMetrics)) {
        return;
    }

    removeExistingScript();
    addQuantumScript(quantumMetrics);
}
