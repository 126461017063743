export function LoadingItemCard() {
    return (
        <div className="merch-loading-item-card">
            <div className="merch-loading merch-loading--image" />
            <div className="merch-loading merch-loading--text" />
            <div className="merch-loading merch-loading--text" />
            <div className="merch-loading merch-loading--text merch-loading--text-partial" />
            <div className="merch-loading merch-loading--text merch-loading--text-badge" />
        </div>
    );
}
