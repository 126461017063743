import classNames from 'classnames';
import { TEST_INFO_KEYS, TEST_IDS } from '../../../../utils/test-ids';
import { BulkActionsHookData } from '../../../bulk-actions/types';
import { useBulkActionsContext } from '../../../bulk-actions/context';
import { LineItemActions } from '../line-item-actions';
import { LineItemSummary } from '../line-item-summary';
import { ListingSummaryProps } from './types';

export function ListingSummary({
    actions,
    disabled,
    handleAction,
    lineItem,
    listingSummary,
    moduleName,
    firstLineItem,
    isInvoice = false,
    seller
}: ListingSummaryProps) {
    const { cartBulkXoEnabled }: BulkActionsHookData = useBulkActionsContext();
    // Show the bulkXoCheckbox at LineItem only if the lineItem is invoiced and the user is opted into the cartBulkXOEnabled EP
    const showLineItemCheckbox = cartBulkXoEnabled && !!lineItem?.bulkActionSelection && isInvoice;

    const listSummaryInfo = JSON.stringify({
        [TEST_INFO_KEYS.itemId]: listingSummary.listingId,
        [TEST_INFO_KEYS.itemVarId]: listingSummary.variationId
    });

    return (
        <div key={listingSummary.listingId} className={classNames('listsummary', { 'listsummary--bulk-invoice': showLineItemCheckbox })}
            data-test-id={TEST_IDS.listSummary} data-test-info={listSummaryInfo}>
            <LineItemSummary
                bulkActionSelection={lineItem?.bulkActionSelection}
                disabled={disabled}
                hasBulkActions={!!lineItem?.bulkActionSelection}
                listingSummary={listingSummary}
                lineItemId={lineItem?.lineItemId || ''}
                moduleName={moduleName}
                onAction={handleAction}
                firstLineItem={firstLineItem}
                isInvoice={isInvoice}
                seller={seller}
            />
            <LineItemActions
                actions={actions}
                onAction={handleAction}
            />
        </div>
    );
}
