export enum SilhouetteTypes {
    Avatar = 'avatar',
    Button = 'button',
    ButtonLarge = 'button--large',
    ButtonSmall = 'button--small',
    Image = 'image',
    Text = 'text',
    Textbox = 'textbox',
    TextLarge = 'text--large',
    TextMultiLine = 'text--multiline',
}

export interface SilhouetteType {
    type?: SilhouetteTypes;
}
