import { ChangeEvent } from 'react';
import { Action } from '@ebay/experience-types-ebay';
import { BulkActionNames, BulkActionsModules, BulkActionsHookData } from '../types';
import { BulkAction, BulkActionParamsTypes, BulkActionType } from '../actions/types';
import { LineItem } from '../../../../../common/utils/hydration';
import { Spinner } from '../../spinner/types';
import { transformMapToArrayOfLineItemIds } from './utils';

export const bulkActionsHandler = (
    bulkActionContext: BulkActionsHookData,
    event: React.MouseEvent<Element, MouseEvent> | ChangeEvent<HTMLInputElement> | MouseEvent,
    lineItems: LineItem[],
    moduleName: BulkActionsModules,
    onBulkAction: (action?: BulkAction) => Promise<void>,
    spinner: Spinner,
    setEditable: (editable: boolean) => void,
    action?: Action,
): void => {
    if (action && event) {
        const { name } = action;

        const {
            bulkActionItemsMap,
            onBulkActionActiveModuleChange,
            onSelectAllClick,
            resetState,
        } = bulkActionContext;

        const target = event?.target as HTMLInputElement;
        const isChecked = target?.checked;
        switch (name) {
            case BulkActionNames.EnableBulkMode:
                setEditable(true);
                onBulkActionActiveModuleChange(moduleName);
                break;
            case BulkActionNames.BulkEditSelectAll:
                // TODO : replace with the bulk xo service call method once integrated in next PR
                onSelectAllClick(lineItems, isChecked);
                break;
            case BulkActionNames.DisableBulkMode:
                setEditable(false);
                resetState();
                break;
            case BulkActionNames.BulkRemoveSelected:
                if (onBulkAction && bulkActionItemsMap?.size) {
                    spinner.show();
                    void onBulkAction(
                        {
                            actionType: BulkActionType.Remove,
                            params: {
                                lineItemIds: transformMapToArrayOfLineItemIds(bulkActionItemsMap)
                            } as BulkActionParamsTypes
                        }
                    ).then(() => {
                        spinner.hide();
                        resetState();
                        setEditable(false);
                    });
                }
                break;
            default:
                break;
        }
    }
};