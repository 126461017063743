// Ref: https://github.corp.ebay.com/AuthSignin/identity-components/tree/main/lib/vanilla-components
import { AuthModal, AuthModalConfig } from '@ebay/identity-components';

class OTPAuthModal {
    authModalInstance?: AuthModal;

    initialize(config?: AuthModalConfig) {
        if (!this.authModalInstance) {
            this.authModalInstance = new AuthModal(Object.assign(this.getDefaultConfig(), config));
        }
    }

    open(config: AuthModalConfig) {
        this.initialize(config);
        this.authModalInstance?.open();
    }

    getDefaultConfig() {
        return {
            action: 'checkout',
            flowType: 'HBI',
            isGuestCheckoutEligible: true,
            ru: window?.location?.href,
            subFlowType: 'CART',
            useCase: 'AUTH',
        };
    }
}

export const authModal = new OTPAuthModal();