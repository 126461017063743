import {
    getSignalByIdAndType,
    signalsTypes
} from '@ebay/signals-components-provider/utils';
import { useI18n } from '../../../utils/i18n';
import { useSignalsContext } from '../context';
import { SellerSignalsProps } from '../types';
import { TextDisplay } from '../../text-display';
import { buildProgramBadgeLocalizedText } from '../utils';
import { useAppMetaContextData } from '../../../utils/app-meta-context';
import './styles.less';

export function SellerSignals({
    positiveFeedback,
    sellerId
}: SellerSignalsProps) {
    const { getSignalsLocales } = useI18n();
    const { appMeta } = useAppMetaContextData();
    const {
        isFGQLCallLoading,
        mappedSignals
    } = useSignalsContext();

    if (!sellerId) {
        return null;
    } else if (isFGQLCallLoading) {
        return (
            <div className="seller-signals silhouettes">
                <div className="silhouette-box">
                    <div className="silhouette-box silhouette-loading" />
                </div>
            </div>
        );
    }

    const signalsBundleText = getSignalsLocales();
    const mappedProgramBadge = getSignalByIdAndType(sellerId, signalsTypes.PROGRAM_BADGE, mappedSignals);
    const ProgramBadgeComponent = mappedProgramBadge?.Component;
    if (!ProgramBadgeComponent && !positiveFeedback) {
        return null;
    }


    const programBadgeLocalizedText = buildProgramBadgeLocalizedText(
        signalsBundleText, mappedProgramBadge?.signalName, appMeta?.isMobile
    );
    return (
        <div className="seller-signals">
            <ul>
                { !!positiveFeedback && (
                    <li>
                        <TextDisplay data={positiveFeedback} />
                    </li>
                ) }
                { ProgramBadgeComponent && mappedProgramBadge?.signalName && (
                    <li>
                        <ProgramBadgeComponent
                            localizedText={programBadgeLocalizedText}
                            programType={mappedProgramBadge.signalName}
                            variant="small"
                        />
                    </li>
                )}
            </ul>
        </div>
    );
}
