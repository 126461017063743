import classNames from 'classnames';
import { useEffect } from 'react';
import { TEST_IDS } from '../../utils/test-ids';
import { PageMaskProps } from './types';

import './styles.less';

export function PageMask({ show, onloadSpinner }: PageMaskProps) {
    useEffect(() => {
        if (show) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [show]);

    useEffect(() => {
        function disableActions(event: Event) {
            if (document.body.classList.contains('no-scroll')) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        document.addEventListener('keydown', disableActions, { passive: false });
        document.addEventListener('touchmove', disableActions, { passive: false });

        return () => {
            document.removeEventListener('keydown', disableActions);
            document.removeEventListener('touchmove', disableActions);
        };
    }, []);

    if (!show) {
        return null;
    }

    return (
        <span data-test-id={TEST_IDS.pageMask}>
            <div className={classNames('page-mask', onloadSpinner && 'gray-mask')}>
                {onloadSpinner && (
                    <span className="spinner spinner--large" aria-label="Busy" role="img" />
                )}
            </div>
        </span>
    );
}
