import { trackPulsar, TrackingData, DEFAULT_CLICK_TRACKING_DATA, MODULE_TRACKING_DATA } from '../../../../common/utils/tracking';
import { getCartPageId, getSID } from '../../../../common/utils/tracking-utils';

const BANNER_CARD = 'BANNER_CARD';
const trackingData = MODULE_TRACKING_DATA[BANNER_CARD];

export function trackClickCobrandedAction(isGuest?: boolean, shoppingCartId?: string) {
    const pageId = getCartPageId(isGuest);
    const cobrandedClick: TrackingData = {
        ...DEFAULT_CLICK_TRACKING_DATA,
        eventProperty: {
            actionKind: 'CLICK',
            sid: getSID(String(pageId), BANNER_CARD),
            pge: pageId,
            ebc: shoppingCartId
        }
    };

    trackPulsar(cobrandedClick);
}

export function trackViewCobrandedAction(isGuest?: boolean, shoppingCartId?: string) {
    const pageId = getCartPageId(isGuest);
    const cobrandedView: TrackingData = {
        eventFamily: 'CART',
        eventAction: 'VIEW',
        flushImmediately: false,
        eventProperty: {
            moduledtl: `mi:${trackingData?.mid}`,
            pge: pageId,
            ebc: shoppingCartId
        }
    };

    trackPulsar(cobrandedView);
}
