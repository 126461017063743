import { SilhouetteTypes, SilhouetteType } from './types';

export function InlineSilhouette({ type }: SilhouetteType) {
    const silhouetteType = type || SilhouetteTypes.Text;
    return (
        <div className="skeleton">
            <div className={`skeleton__${silhouetteType}`}>
            </div>
        </div>
    );
}