import { useState, useEffect, useLayoutEffect } from 'react';
import { AppConfig, CartClientState } from '../../../common/utils/hydration';
import { redirectIfRequired, resetGlobalHeaderCartValue } from './domain/services';

export function useUpdateGlobalHeaderCartValue(cartState?: CartClientState) {
    useEffect(() => {
        resetGlobalHeaderCartValue(cartState);
    }, [cartState]);
}

export function useRedirectIfRequiredOnMount(cartState?: CartClientState, appConfig?: AppConfig) {
    const [isRedirectRequired, setIsRedirectRequired] = useState(true);

    useLayoutEffect(() => {
        if (cartState) {
            setIsRedirectRequired(redirectIfRequired(cartState, appConfig));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isRedirectRequired;
}
