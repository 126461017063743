import { createContext } from 'react';
import { AppMetaContextData } from '../../../../common/utils/hydration/types';
import { AppMetaContextProps } from './types';

export const AppMetaContext = createContext<AppMetaContextData>({});
export function AppMetaContextProvider({ initialState, children }: AppMetaContextProps) {
    const appConfig = initialState && initialState?.appConfig;
    const appMeta = initialState && initialState?.appMeta;
    const meta = initialState && initialState?.meta;
    return (
        <AppMetaContext.Provider value={{ appConfig, appMeta, meta }}>
            {children}
        </AppMetaContext.Provider>
    );
}