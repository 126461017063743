import { Fragment } from 'react';
import classNames from 'classnames';
import { VerticalLine } from '../../../../../common/components/vertical-line';
import { TextDisplayWithInfo } from '../../../../../common/components/text-display-with-info';
import { getTestId } from '../domain/specifications';
import { useHandleAction } from '../hooks';
import type { ActionsMenuProps } from '../types';

import './styles.less';

export function ActionsMenu({ className, actions, onPayOnlyThisSeller, sellerId }: ActionsMenuProps) {
    const handleAction = useHandleAction({ onPayOnlyThisSeller });

    return (
        <div className={classNames('actions-menu no-wrap', className)}>
            {actions?.map((menuAction, index) => (
                <Fragment key={index}>
                    <TextDisplayWithInfo
                        data={menuAction}
                        variant="fake-link"
                        onClick={handleAction}
                        sellerId={sellerId}
                        testId={getTestId(menuAction)} />
                    {index < actions.length - 1 && <VerticalLine />}
                </Fragment>
            ))}
        </div>
    );
}
