import { Action } from '@ebay/experience-types-ebay';
import { CartClientState } from '../../../../../../../common/utils/hydration';
import { AjaxCallMaker } from '../../../../../../common/utils/ajax';
import { RemoveItemRequest, SavedForLaterRequest } from '../types';

export function containsLineItemId(data?: RemoveItemRequest | SavedForLaterRequest) {
    return !!data?.lineItemId;
}

export async function savedForLater(makeAjaxCall: AjaxCallMaker<SavedForLaterRequest>, action?: Action): Promise<CartClientState> {
    const data = action?.params as SavedForLaterRequest;
    if (!containsLineItemId(data)) {
        throw new Error('[addBackToCart]: LineItemId missing!');
    }

    return makeAjaxCall('/api/c2s', data);
}

export async function removeItemFromCart(makeAjaxCall: AjaxCallMaker<RemoveItemRequest>, action?: Action): Promise<CartClientState> {
    const data = action?.params as RemoveItemRequest;
    if (!containsLineItemId(data)) {
        throw new Error('[removeItem]: LineItemId missing!');
    }

    return makeAjaxCall('/api/rm', data);
}