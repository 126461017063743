import { createContext, useContext } from 'react';
import { useSignals } from '../hooks';
import { SignalsHookData, SignalsContextProviderProps } from '../types';

export const SignalsContext = createContext<SignalsHookData>({});

export function SignalsContextProvider({ cartState, children }: SignalsContextProviderProps) {
    const signals = useSignals({ cartState });
    return (
        <SignalsContext.Provider value={signals}>
            {children}
        </SignalsContext.Provider>
    );
}

export function useSignalsContext() {
    return useContext(SignalsContext);
}
