import { TEST_IDS } from '../../common/utils/test-ids';
import { PageTitleProps } from './types';

import './styles.less';

export function PageTitle({ title }: PageTitleProps) {
    if (!title) {
        return null;
    }

    return (
        <h1 className="main-title font-title-1" data-test-id={TEST_IDS.mainTitle}>{title}</h1>
    );
}
