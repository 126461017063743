import classNames from 'classnames';
import { Banner as BannerType } from 'src/common/utils/hydration';
import { TEST_IDS } from '../../common/utils/test-ids';
import { useAppMetaContextData } from '../../common/utils/app-meta-context';
import { useFeatureFlagsData } from '../../common/utils/experiments/hooks';
import { isCreditCardApplicationBanner } from './utils';
import { BannerCard } from './components/banner-card';
import { MiniBannerCard } from './components/mini-banner-card';
import { BannerProps } from './types';

import './styles.less';


const getBannerType = (banner: BannerType, dWebBannerReposition: boolean, miniBanner: boolean) => {
    const isCreditCardApplication = isCreditCardApplicationBanner(banner?.bannerCard?.action?.name );

    if (dWebBannerReposition && isCreditCardApplication) {
        return miniBanner ? <MiniBannerCard bannerCard={banner.bannerCard} /> : null;
    }

    return miniBanner ? null : <BannerCard bannerCard={banner.bannerCard} />;
};

export function Banner({ banner, miniBanner = false }: BannerProps) {
    const { appConfig } = useAppMetaContextData();
    const featureFlagsData = useFeatureFlagsData();
    const dWebBannerReposition = appConfig?.dWebBannerReposition || featureFlagsData?.dWebBannerReposition || false;
    if (!banner) {
        return null;
    }

    const BannerCardComponent = getBannerType(banner, dWebBannerReposition, miniBanner);
    if (!BannerCardComponent) {
        return null;
    }

    const inMCBannerEP = Boolean((dWebBannerReposition && miniBanner && isCreditCardApplicationBanner(banner?.bannerCard?.action?.name)));
    const { backgroundColor } = banner;

    // For MC banners, we don't want to use backgroundColor from service response. We are overriding it using client styles.
    const bannerStyles = inMCBannerEP ? {} : { backgroundColor };

    return (
        <div className={classNames('banner',
            {
                'mini-banner-wrapper': inMCBannerEP,
            })}
        data-test-id={TEST_IDS.bannerCard}
        style={bannerStyles}>
            {BannerCardComponent}
        </div>
    );
}
