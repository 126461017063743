import { useEffect } from 'react';
import { extractLineItemsFromSellerBuckets } from '../../common/components/bulk-actions/utils';
import { TEST_IDS } from '../../common/utils/test-ids';
import { CartBucket } from '../cart-bucket';
import { EmptyCart } from '../empty-cart';
import { EmptyCartWithWatchlist } from '../empty-cart-with-watchlist';
import { useAjax } from '../../common/utils/ajax';
import { SpinnerContainer } from '../../common/components/spinner';
import { BulkActions } from '../../common/components/bulk-actions';
import { makeBulkAction } from '../../common/components/bulk-actions/actions/domain/service';
import { RemoveBulkItemsAction, BulkActionType } from '../../common/components/bulk-actions/actions/types';
import { useFocusOnActionContext } from '../../common/utils/focus-on-action/context';
import { useFeatureFlagsData } from '../../common/utils/experiments';
import { manualTagDropper } from '../../../common/utils/experimentation/manual-tag-dropper';
import { useAppMetaContextData } from '../../common/utils/app-meta-context/hooks';
import { useBulkActionsContext } from '../../common/components/bulk-actions/context/context';
import { isPageLoadEventFired, getPageLoadEventName } from '../../common/utils/dom/dom';
import { shouldShowMerchOnEmptyCart } from '../../utils/experimentation-utils';
import { trackImpressions } from '../../common/utils/tracking/tracking';
import { isBulkCheckoutEnabled } from '../../common/components/bulk-actions/utils/utils';
import type { CartProps } from './types';
import './styles.less';



export function Cart({
    cartDetails,
    onBulkAction,
    onBuyItNow,
    onQuantityUpdate,
    onPayOnlyThisSeller,
    onSavedForLater,
    onRemoveItem
}: CartProps) {
    const featureFlagsData = useFeatureFlagsData();
    const { focusOnCartContainer } = useFocusOnActionContext();
    const { appMeta } = useAppMetaContextData();
    const { allItemsMap } = useBulkActionsContext();
    const showBulkSelection = featureFlagsData?.shoppingCartBulkActionsTourTip || isBulkCheckoutEnabled(featureFlagsData?.cartBulkXoEnabled, appMeta?.isGuest, allItemsMap?.size);
    const showMerchOnEmptyCart = shouldShowMerchOnEmptyCart(featureFlagsData);
    const cartDetailsTrackingList = cartDetails?.meta?.trackingList || [];
    const makeAjaxCall = useAjax();

    const dropXTTagsForEmptyCartMerch = () => {
        const showEmptyCartMerch = featureFlagsData?.showEmptyCartMerch || {};
        const { treatmentId, value } = showEmptyCartMerch;
        if (typeof value === 'object') {
            if (treatmentId) {
                manualTagDropper(treatmentId, featureFlagsData?.qtags, appMeta?.pageId);
            }
        }
    };

    const dropImpressionsForModules = () => {
        // Dispatch any module level tracking here
        const trackingList = cartDetails?.meta?.trackingList || [];
        trackImpressions(trackingList);
    };

    useEffect(() => {
        if (cartDetails && cartDetails?.emptyCart) {
            if (!isPageLoadEventFired()) {
                window.addEventListener(getPageLoadEventName(), dropXTTagsForEmptyCartMerch);
            } else {
                dropXTTagsForEmptyCartMerch();
            }
        }


        if (!isPageLoadEventFired()) {
            window.addEventListener(getPageLoadEventName(), dropImpressionsForModules);
        } else {
            dropImpressionsForModules();
        }
    }, []);


    if (!cartDetails) {
        return null;
    }
    const { sellerBuckets = [] } = cartDetails;
    const lineItems = extractLineItemsFromSellerBuckets(sellerBuckets);
    const handleBulkAction = (action?: RemoveBulkItemsAction): Promise<void> => {
        switch (action?.actionType) {
            case BulkActionType.Remove:
                return makeBulkAction(makeAjaxCall, action)
                    .then(onBulkAction)
                    .catch((error: Error) => {
                        console.error('[BulkAction] Error in the request', error?.message);
                    });
            default:
                return Promise.resolve();
        }
    };

    let CartModules;
    const buildEmptyCartModule = sellerBuckets.length === 0 || false;
    if (cartDetails.emptyCart || buildEmptyCartModule) {
        if (showMerchOnEmptyCart) {
            CartModules = <EmptyCartWithWatchlist emptyCart={cartDetails.emptyCart} />;
        } else {
            CartModules = <EmptyCart emptyCart={cartDetails.emptyCart} buildModule={buildEmptyCartModule}/>;
        }
    } else {
        CartModules = (
            <>
                {
                    showBulkSelection && (
                        <BulkActions
                            data={{ ...cartDetails?.bulkActions }}
                            lineItems={lineItems}
                            moduleName={'CART'}
                            onBulkAction={handleBulkAction}
                            title={cartDetails?.moduleTitle}
                        />
                    )
                }
                {
                    cartDetails.sellerBuckets?.map((sellerBucket, index) => (
                        <CartBucket
                            key={index}
                            sellerBucket={sellerBucket}
                            onBuyItNow={onBuyItNow}
                            onQuantityUpdate={onQuantityUpdate}
                            onPayOnlyThisSeller={onPayOnlyThisSeller}
                            onSavedForLater={onSavedForLater}
                            onRemoveItem={onRemoveItem}
                            firstCartBucket={index === 0}
                        />
                    ))
                }
            </>
        );
    }

    return (
        <div
            className="app-cart"
            data-test-id={TEST_IDS.appCart}
            tabIndex={focusOnCartContainer ? 0 : -1}
            data-view={JSON.stringify(cartDetailsTrackingList)}
        >
            <SpinnerContainer>
                { CartModules }
            </SpinnerContainer>
        </div>
    );
}