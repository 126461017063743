import { TextDisplay } from '../text-display';
import { useBulkActionsContext } from '../bulk-actions/context';
import { useAppMetaContextData } from '../../utils/app-meta-context/hooks';
import { SellerInformationProps } from './types';
import './styles.less';

export function SellerInformation({
    data,
    sellerId
}: SellerInformationProps) {
    const { activeBulkActionsModule, cartBulkXoEnabled } = useBulkActionsContext();
    const { appMeta } = useAppMetaContextData();

    if (!data) {
        return null;
    }

    const sellerLinkTextDisplay = (
        <span>
            <TextDisplay
                disabled={activeBulkActionsModule !== null && !cartBulkXoEnabled}
                data={data?.sellerName} sellerId={sellerId}
                openNewWindow={appMeta?.isMobile ? '_self' : '_blank'}
            />
        </span>
    );

    return (
        <h2 className="seller-ctr text-truncate">
            {
                data.sellerLabel && (
                    <span className="font-weight-light seller-label">
                        <TextDisplay disabled={activeBulkActionsModule !== null} data={data?.sellerLabel} sellerId={sellerId} />
                    </span>
                )
            }
            {
                data?.sellerName && (
                    <span className="black-link font-title-3">
                        &nbsp;
                        {sellerLinkTextDisplay}
                    </span>
                )
            }
        </h2>
    );
}
