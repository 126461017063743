import { useEffect, useState } from 'react';
import { addPageLoadEventListener } from '../../../utils/dom';
import {
    CurrentQuickViewData,
    QuickViewContextProviderProps,
    QuickViewHookData,
    QuickViewLoadingState
} from '../types';
import { hasValidListingId } from '../utils/listing-details';
import { startLazyLoadingQuickView } from '../components/quick-view-popup-lazy/utils/lazy-load-utils';
import { useAppMetaContextData } from '../../../utils/app-meta-context';
import {
    dropXTTagsForQuickView,
    getDeviceAndMembershipQuickViewEP,
    isInQuickViewTreatment
} from '../utils/experimentation';
import { useFeatureFlagsData } from '../../../utils/experiments';
import { trackPulsar } from '../../../utils/tracking';
import { getActionTrackingData } from '../../../utils/tracking-utils';

export function useQuickView({ cartState }: QuickViewContextProviderProps): QuickViewHookData {
    const featureFlagsData = useFeatureFlagsData();
    const qtags = featureFlagsData?.qtags;
    const { appMeta, meta } = useAppMetaContextData();
    const [loadingState, setLoadingState] = useState(QuickViewLoadingState.NotStarted);
    const [isFirstTagDrop, setIsFirstTagDrop] = useState(true);
    const [currentQuickViewData, setCurrentQuickViewData] = useState<CurrentQuickViewData>(null);

    const isQuickViewEPData = getDeviceAndMembershipQuickViewEP(featureFlagsData, appMeta);
    const isValidSession = hasValidListingId(cartState?.modules?.cartDetails);

    const dropXTTagsOnlyOnce = () => {
        if (isFirstTagDrop) {
            setIsFirstTagDrop(false);
            dropXTTagsForQuickView(isQuickViewEPData, qtags);
        }
    };

    useEffect(() => {
        if (isValidSession && isQuickViewEPData) {
            addPageLoadEventListener(() => {
                dropXTTagsOnlyOnce();
                if (isInQuickViewTreatment(isQuickViewEPData)) {
                    startLazyLoadingQuickView();
                    if (loadingState === QuickViewLoadingState.NotStarted) {
                        setLoadingState(QuickViewLoadingState.PageLoaded);
                    }
                }
            });
        }
    }, [isValidSession]);

    const shouldShowQuickViewAction = () => loadingState === QuickViewLoadingState.Done;
    const shouldShowQuickViewPopup = () => isInQuickViewTreatment(isQuickViewEPData) && (
        loadingState === QuickViewLoadingState.PageLoaded
        || loadingState === QuickViewLoadingState.Done
    );
    const setShouldShowQuickViewAction = () => {
        if (loadingState !== QuickViewLoadingState.Done) {
            setLoadingState(QuickViewLoadingState.Done);
        }
    };

    const getCurrentQuickViewData = () => currentQuickViewData;

    const openQuickViewByIds = (listingId?: string, variationId?: string) => {
        if (listingId) {
            setCurrentQuickViewData({ listingId, variationId });
        }
    };

    const closeQuickView = () => {
        const trackingData = getActionTrackingData({
            refActionName: 'CLOSE_QUICK_VIEW',
            isGuest: appMeta?.isGuest,
            shoppingCartId: meta?.shoppingCartId
        });
        trackPulsar(trackingData?.[0]);
        setCurrentQuickViewData(null);
    };


    return {
        closeQuickView,
        getCurrentQuickViewData,
        openQuickViewByIds,
        setShouldShowQuickViewAction,
        shouldShowQuickViewAction,
        shouldShowQuickViewPopup
    };
}
