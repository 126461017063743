import { LineItem } from '../../../../../common/utils/hydration';
import { ActionFromLineItemDataType, LineItemActionType } from '../../../../common/components/grid-line-item/components/line-item-actions';
import { TEST_IDS } from '../../../../common/utils/test-ids';

export function extractActionsFromLineItemSFL(lineItem: LineItem): ActionFromLineItemDataType[] {
    let outputActions: ActionFromLineItemDataType[] = [];
    const goToSimilarItemsData = lineItem?.actions?.[LineItemActionType.GoToSimilarItems] && {
        data: lineItem?.actions?.[LineItemActionType.GoToSimilarItems],
        testId: TEST_IDS.savedForLaterFindSimilarItems
    };
    const addBackToCartData = lineItem?.actions?.[LineItemActionType.AddBackToCart] && {
        data: lineItem?.actions?.[LineItemActionType.AddBackToCart],
        testId: TEST_IDS.savedForLaterToCart
    };
    const removeItemData = {
        data: lineItem?.actions?.[LineItemActionType.RemoveItem],
        testId: TEST_IDS.savedForLaterRemoveItem
    };
    outputActions = [addBackToCartData, goToSimilarItemsData, removeItemData];
    return outputActions;
}
