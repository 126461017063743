import { useState, useEffect, ChangeEvent } from 'react';
import classNames from 'classnames';
import { Action } from '@ebay/experience-types-ebay';
import { useBulkActionsContext } from '../context';
import { getActiveState } from '../utils';
import { useSpinner } from '../../spinner';
import { bulkActionsHandler } from '../utils/bulk-actions-handler';
import { BulkActionsTitle } from '../bulk-actions-title';
import { BulkActionsContent } from './bulk-action-content';
import type { BulkActionsProps } from '../types';
import './bulk-actions-desktop.less';

export function BulkActions({ data, moduleName, onBulkAction, title, lineItems = [] }: BulkActionsProps) {
    const [editable, setEditable] = useState<boolean>(false);
    const spinner = useSpinner();
    const bulkActionContext = useBulkActionsContext();
    const {
        activeBulkActionsModule,
        bulkActionItemsMap,
        selectAllCheckboxChecked,
        selectedBulkActionItems,
        setSelectAllCheckboxChecked,
        cartBulkXoEnabled
    } = bulkActionContext;
    useEffect(() => {
        if (activeBulkActionsModule === moduleName) {
            if (bulkActionItemsMap?.size !== lineItems.length && !cartBulkXoEnabled) { // SelectAll is checked by default in Bulk XO usecase
                setSelectAllCheckboxChecked(false);
            } else if (!selectAllCheckboxChecked && bulkActionItemsMap?.size === lineItems.length ) {
                setSelectAllCheckboxChecked(true);
            }
        }
    }, [bulkActionItemsMap]);

    const activeState = getActiveState(activeBulkActionsModule, moduleName);

    if (!data) {
        return null;
    }
    const handleAction = (
        event: React.MouseEvent<Element, MouseEvent> | ChangeEvent<HTMLInputElement>,
        action?: Action
    ): void => {
        bulkActionsHandler(
            bulkActionContext,
            event,
            lineItems,
            moduleName,
            onBulkAction,
            spinner,
            setEditable,
            action,
        );
    };

    return (
        <div className={classNames({
            'cart-bulk-actions': activeBulkActionsModule === null,
            'cart-bulk-actions--sticky': activeBulkActionsModule !== null,
            'cart-bulk-actions--disabled': activeBulkActionsModule !== moduleName
        })}>
            {title && !cartBulkXoEnabled && <BulkActionsTitle title={title}/>}
            <BulkActionsContent
                activeState={activeState}
                activeBulkActionsModule={activeBulkActionsModule}
                bulkActionItemsMap={bulkActionItemsMap}
                moduleName={moduleName}
                data={data}
                title={title}
                editable={editable || cartBulkXoEnabled}
                handleAction={(handleAction)}
                selectedBulkActionItems={selectedBulkActionItems}
                selectAllCheckboxChecked={selectAllCheckboxChecked}
                onBulkAction={onBulkAction}
            />
        </div>
    );
}