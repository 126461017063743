import { useState } from 'react';
import { CartClientState } from '../../../../common/utils/hydration';
import { ClientStateHookData } from './types';

export function useCartState(initialState?: CartClientState): ClientStateHookData {
    const [cartState, setCartState] = useState<CartClientState | undefined>(initialState);

    return {
        cartState,
        setCartState
    };
}
