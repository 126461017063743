import { MappedSignals, mapSignalsModuleToComponents } from '@ebay/signals-components-provider/utils';
import { FeatureFlagsData, Signals, SignalsExperimentTreatments } from '../../../../../common/utils/hydration';
import { manualTagDropper } from '../../../../../common/utils/experimentation/manual-tag-dropper';
import { getCartPageId } from '../../../utils/tracking-utils';
import {
    doesFgqlSignalsHaveTopRatedPlus,
    doesFgqlSignalsHaveTopRatedSeller,
    doesVasSignalsExist,
    mergeSignalsState, removeTopRatedPlusFromFgqlSignals, removeTopRatedSellerFromFgqlSignals
} from './utils';

export const getIsInFGQLSignalsControl = (featureFlagsData?: FeatureFlagsData) => {
    return featureFlagsData?.enableFGQLSignals?.value?.['web.xoBundledSignals'] === SignalsExperimentTreatments.Control;
};

export const getIsInFGQLSignalsTreatment = (featureFlagsData?: FeatureFlagsData) => {
    return featureFlagsData?.enableFGQLSignals?.value?.['web.xoBundledSignals'] && [
        SignalsExperimentTreatments.TreatmentOne,
        SignalsExperimentTreatments.TreatmentTwo,
        SignalsExperimentTreatments.TreatmentThree
    ].includes(featureFlagsData.enableFGQLSignals?.value?.['web.xoBundledSignals']);
};

export const getIsInFGQLSignalsEP = (featureFlagsData?: FeatureFlagsData) => {
    return !!featureFlagsData?.enableFGQLSignals?.value?.['web.xoBundledSignals'];
};

export function dropXTTagsForFGQLSignals(featureFlagsData?: FeatureFlagsData) {
    const enableFGQLSignals = featureFlagsData?.enableFGQLSignals;
    if (enableFGQLSignals) {
        const { value = {}, treatmentId } = enableFGQLSignals;
        const { 'web.xoBundledSignals': fgqlSignalsValue } = value;
        if (fgqlSignalsValue && treatmentId) {
            manualTagDropper(treatmentId, featureFlagsData?.qtags, String(getCartPageId(true)));
        }
    }
}

export function hasValidTagDroppingConditions(fgqlSignals: Signals, vasSignals?: Signals): boolean {
    return doesVasSignalsExist(vasSignals)
        || doesFgqlSignalsHaveTopRatedSeller(fgqlSignals)
        || doesFgqlSignalsHaveTopRatedPlus(fgqlSignals);
}

export function buildMappedSignalsForTreatmentOne(fgqlSignals: Signals, vasSignals?: Signals): MappedSignals {
    const cleanedFgqlSignals = removeTopRatedPlusFromFgqlSignals(fgqlSignals);
    const mergedSignalsState = mergeSignalsState(cleanedFgqlSignals, vasSignals);
    return mapSignalsModuleToComponents(mergedSignalsState);
}

export function buildMappedSignalsForTreatmentTwo(fgqlSignals: Signals, vasSignals?: Signals): MappedSignals {
    const cleanedFgqlSignals = removeTopRatedSellerFromFgqlSignals(fgqlSignals);
    const mergedSignalsState = mergeSignalsState(cleanedFgqlSignals, vasSignals);
    return mapSignalsModuleToComponents(mergedSignalsState);
}

export function buildMappedSignalsForTreatmentThree(fgqlSignals: Signals, vasSignals?: Signals): MappedSignals {
    const mergedSignalsState = mergeSignalsState(fgqlSignals, vasSignals);
    return mapSignalsModuleToComponents(mergedSignalsState);
}

export function buildMappedSignalsBasedOnTreatment(featureFlagsData: FeatureFlagsData, fgqlSignals: Signals, vasSignals?: Signals): MappedSignals {
    const treatment = featureFlagsData?.enableFGQLSignals?.value?.['web.xoBundledSignals'];
    switch (treatment) {
        case SignalsExperimentTreatments.TreatmentOne:
            return buildMappedSignalsForTreatmentOne(fgqlSignals, vasSignals);
        case SignalsExperimentTreatments.TreatmentTwo:
            return buildMappedSignalsForTreatmentTwo(fgqlSignals, vasSignals);
        case SignalsExperimentTreatments.TreatmentThree:
            return buildMappedSignalsForTreatmentThree(fgqlSignals, vasSignals);
        default:
            return {};
    }
}
