import { EbayField, EbayLabel } from '@ebay/ui-core-react/ebay-field';
import { extractText } from '@ebay/ebayui-app-react/ebay-textual-display';
import { useUniqueId } from '../../../../utils/unique-id';
import { AlternativeQuantityProps } from './types';

export function AlternativeItemQuantity({ label, text = '' }: AlternativeQuantityProps) {
    const alternativeQuantityUuid = useUniqueId('textbox');
    if (!label) {
        return null;
    }
    return (
        <div className="quantity-display">
            <EbayField>
                <EbayLabel htmlFor={alternativeQuantityUuid}>
                    {extractText(label)}
                </EbayLabel>
                <span>
                    {text}
                </span>
            </EbayField>
        </div>
    );
}