import { useEffect, useMemo, createContext } from 'react';
import { useAjax } from '../ajax';
import { ClientLogger } from './client-logger';
import { ClientLoggerProviderProps, Logger } from './types';

export const ClientLoggerContext = createContext<Logger | null>(null);

const CLIENT_CONSOLE_SCRIPT_ERROR = 'script error';

export function ClientLoggerProvider({ children }: ClientLoggerProviderProps) {
    const makeAjaxCall = useAjax();
    const clientLogger = useMemo(() => new ClientLogger(makeAjaxCall), [makeAjaxCall]);

    useEffect(() => {
        function errorHandler(event: ErrorEvent) {
            const message = event.message || '';

            /**
             * Logs only CartFE error that occurs in bundled JS file
             * Ignores external JS errors as we don't receive useful log information due to CORS limitation
            */
            if (message.toLowerCase().indexOf(CLIENT_CONSOLE_SCRIPT_ERROR) === -1) {
                clientLogger.error('Client console error', {
                    message,
                    filename: event.filename,
                    line: event.lineno,
                    column: event.colno,
                    errObj: encodeURIComponent(JSON.stringify(event))
                });
            }
        }

        window.addEventListener('error', errorHandler);

        return () => {
            window.removeEventListener('error', errorHandler);
        };
    }, [clientLogger]);

    return (
        <ClientLoggerContext.Provider value={clientLogger}>
            {children}
        </ClientLoggerContext.Provider>
    );
}
