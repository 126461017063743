import { Action } from '@ebay/experience-types-ebay';
import { TextualDisplayWithInfoBubble } from '../../../../../../common/utils/hydration/types';
import { TEST_IDS } from '../../../../../common/utils/test-ids';
import { Actions } from '../types';

export function isPayOnlyThisSeller(action?: Action) {
    return action?.name === Actions.PayOnlyThisSeller;
}

export function isRedirectToCheckout(action?: Action) {
    return action?.name === Actions.RedirectToCheckout;
}

export const getTestId = (menuAction: TextualDisplayWithInfoBubble) => {
    // When Request Total has infoBubble, then action will not be available.
    if (!menuAction.action && menuAction.infoBubble) {
        return TEST_IDS.requestTotalInfo;
    }
    const testId = menuAction?.action?.name === 'REDIRECT_TO_CHECKOUT'
        ? 'PAY_ONLY_THIS_SELLER'
        : menuAction?.action?.name;
    return testId;
};
