import { Action } from '@ebay/experience-types-ebay';
import { AddToCartRequest, SavedForLaterLineItemActionType, RemoveItemRequest } from '../types';

export function isAddBackToCart(action?: Action) {
    return action?.name === SavedForLaterLineItemActionType.AddBackToCart;
}

export function isRemoveItem(action?: Action) {
    return action?.name === SavedForLaterLineItemActionType.RemoveItem;
}

export function containsLineItemId(data?: RemoveItemRequest | AddToCartRequest) {
    return !!data?.lineItemId;
}
