import { useEffect, useState } from 'react';
import { mapSignalsModuleToComponents } from '@ebay/signals-components-provider/utils';
import { Signals } from '../../../../../common/utils/hydration';

export const useSignalsMapper = (signals?: Signals) => {
    const [mappedSignals, setMappedSignals] = useState(signals ? mapSignalsModuleToComponents(signals) : undefined);
    useEffect(() => {
        setMappedSignals(signals ? mapSignalsModuleToComponents(signals) : undefined);
    }, [signals]);

    return {
        mappedSignals
    };
};
