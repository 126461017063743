import { Action } from '@ebay/experience-types-ebay';
import { TEST_IDS } from '../../../../../utils/test-ids';
import { LineItemActionType } from '../../line-item-actions';
import { ConstructQuantityDataActionOutput } from './types';

export function constructQuantityDataAction(eventParams: Action): ConstructQuantityDataActionOutput {
    return {
        action: {
            name: LineItemActionType.EditQuantity,
            params: { ...eventParams?.params }
        },
        testId: TEST_IDS.quantityDropdown

    };
}
