import { createContext } from 'react';
import { FeatureFlagsData } from '../../../../common/utils/hydration/types';
import { FeatureFlagsDataProps } from './types';

export const FeatureFlagsDataContext = createContext<FeatureFlagsData>({});

export function FeatureFlagsDataProvider({ initialState, children }: FeatureFlagsDataProps) {
    const featureFlagsData = initialState?.featureFlagsData || {};

    return (
        <FeatureFlagsDataContext.Provider value={featureFlagsData}>
            {children}
        </FeatureFlagsDataContext.Provider>
    );
}
