import { useEffect, useState } from 'react';
import { UseFocusOnActionProps, UseFocusOnActionHookData, HandleFocusOnActionProps } from '../types';
import { FocusOnActionActionNames } from '../../../../../common/utils/hydration';

export function useFocusOnAction({ cartState }: UseFocusOnActionProps): UseFocusOnActionHookData {
    const [previousAction, setPreviousAction] = useState(cartState?.appMeta?.previousAction || null);
    const [focusOnCartContainer, setFocusOnCartContainer] = useState(false);
    const [focusOnSFLContainer, setFocusOnSFLContainer] = useState(false);

    useEffect(() => {
        if (previousAction) {
            const cartContainer = document.querySelector('.app-cart');
            const savedForLaterContainer = document.querySelector('.saved-for-later-container');
            switch (previousAction) {
                case FocusOnActionActionNames.MoveToCart:
                case FocusOnActionActionNames.RemoveItemFromSFL:
                    if (savedForLaterContainer && savedForLaterContainer?.querySelector('.black-link a')) {
                        (savedForLaterContainer?.querySelector('.black-link a') as HTMLElement)?.focus();
                    } else if (savedForLaterContainer) {
                        setFocusOnSFLContainer(true);
                        (savedForLaterContainer as HTMLElement)?.focus();
                    } else if (cartContainer) {
                        (cartContainer as HTMLElement)?.focus();
                    }
                    break;
                case FocusOnActionActionNames.MoveToSFL:
                case FocusOnActionActionNames.RemoveItemFromCart:
                    if (cartContainer && cartContainer?.querySelector('.black-link a')) {
                        (cartContainer?.querySelector('.black-link a') as HTMLElement)?.focus();
                    } else if (cartContainer) {
                        setFocusOnCartContainer(true);
                        (cartContainer as HTMLElement)?.focus();
                    } else if (savedForLaterContainer) {
                        (savedForLaterContainer as HTMLElement)?.focus();
                    }
                    break;
                default:
                    setFocusOnCartContainer(false);
                    setFocusOnSFLContainer(false);
                    setPreviousAction(null);
                    break;
            }
        }
        return () => {
            setFocusOnCartContainer(false);
            setFocusOnSFLContainer(false);
            setPreviousAction(null);
        };
    }, [previousAction]);

    const handleFocusOnAction = ({ actionName, moduleName }: HandleFocusOnActionProps) => {
        switch (actionName) {
            case FocusOnActionActionNames.MoveToCart:
                setPreviousAction(FocusOnActionActionNames.MoveToCart);
                return;
            case FocusOnActionActionNames.MoveToSFL:
                setPreviousAction(FocusOnActionActionNames.MoveToSFL);
                return;
            case FocusOnActionActionNames.RemoveItem:
                if (moduleName === 'CART') {
                    setPreviousAction(FocusOnActionActionNames.RemoveItemFromCart);
                } else if (moduleName === 'SAVE_FOR_LATER') {
                    setPreviousAction(FocusOnActionActionNames.RemoveItemFromSFL);
                }
                return;
            default:
                setPreviousAction(null);
                return;
        }
    };

    return {
        handleFocusOnAction,
        focusOnCartContainer,
        focusOnSFLContainer
    };
}