import { TextualDisplay } from '@ebay/experience-types-ebay';
import { ReactNode } from 'react';
import { MappedSignals } from '@ebay/signals-components-provider/utils';
import { CartClientState, FGQLActions } from '../../../../common/utils/hydration';

export interface SellerSignalsProps {
    positiveFeedback?: TextualDisplay;
    sellerId?: string;
}

export interface ItemSignalsProps {
    listingId?: string;
    variationId?: string;
}

export interface SignalsContextProviderProps {
    children?: ReactNode;
    cartState?: CartClientState;
}

export interface SignalsHookData {
    isInFGQLSignalsEP?: boolean;
    isInFGQLSignalsControl?: boolean;
    isInFGQLSignalsTreatment?: boolean;
    mappedSignals?: MappedSignals;
    isFGQLCallLoading?: boolean;
}

export interface GetSignalsDataRequest {
    action: FGQLActions.GetSignals;
    params: { listingIds: string[] };
}

export enum SignalsLoadingState {
    Loading = 'LOADING',
    DoneAndDataExists = 'DONE_AND_DATA_EXISTS',
    DoneAndDataMissing = 'DONE_AND_DATA_IS_MISSING',
}
