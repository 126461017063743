import { Action } from '@ebay/experience-types-ebay';
import classNames from 'classnames';
import { useAjax, ROUTES } from '../../../../common/utils/ajax';
import { SpinnerContainer } from '../../../../common/components/spinner';
import { Adjustments } from '../adjustments';
import { LineItem as GridLineItem } from '../../../../common/components/grid-line-item';
import { LineItemActionType } from '../../../../common/components/grid-line-item/components/line-item-actions';
import { extractActionsFromLineItemCart, isLineItemDisabled } from './utils';
import { removeItemFromCart, savedForLater } from './actions/domain/service';
import type { CartBucketLineItemProps } from './types';
import './styles.less';

export function CartBucketLineItem({
    lineItem,
    disabledBucket,
    onBuyItNow,
    onQuantityUpdate,
    onSavedForLater,
    onRemoveItem,
    sellerId,
    firstLineItem,
    seller
}: CartBucketLineItemProps) {
    const makeAjaxCall = useAjax();

    if (!lineItem) {
        return null;
    }
    const disabled = !disabledBucket && isLineItemDisabled(lineItem);
    const lineItemActions = extractActionsFromLineItemCart(lineItem);
    const handleAction = (action?: Action): Promise<void> => {
        switch (action?.name ) {
            case LineItemActionType?.SaveForLater:
                return savedForLater(makeAjaxCall, action)
                    .then(onSavedForLater)
                    .catch((error: Error) => {
                        console.error('[savedForLater] Error in the request', error?.message);
                    });
                break;
            case LineItemActionType?.RemoveItem:
                return removeItemFromCart(makeAjaxCall, action)
                    .then(onRemoveItem)
                    .catch((error: Error) => {
                        console.error('[removeItemFromCart] Error in the request', error?.message);
                    });
                break;
            case LineItemActionType?.EditQuantity:
                return makeAjaxCall(ROUTES.updateQuantity, action?.params)
                    .then(onQuantityUpdate)
                    .catch((error: Error) => {
                        console.error('[updateQuantity] Error in the request', error?.message);
                    });
                break;
            case LineItemActionType?.BuyItNow:
                return makeAjaxCall(ROUTES.buyItNow, action)
                    .then(onBuyItNow)
                    .catch((error: Error) => {
                        console.error('[buyItNow] Error in the request', error?.message);
                    });
            default:
                return Promise.resolve();
        }
    };

    return (
        <>
            <div className={classNames('cart-bucket-lineitem', disabled && 'cart-bucket-lineitem--disabled')}>
                <SpinnerContainer>
                    <GridLineItem
                        actions={lineItemActions}
                        disabled={isLineItemDisabled(lineItem)}
                        lineItem={lineItem}
                        moduleName='CART'
                        onAction={handleAction}
                        firstLineItem={firstLineItem}
                        seller={seller}
                    />
                </SpinnerContainer>
            </div>
            <Adjustments adjustments={lineItem.adjustments} sellerId={sellerId}/>
        </>
    );
}
