import { useEffect, useRef } from 'react';
import { useFeatureFlagsData } from '../../../../common/utils/experiments/hooks';
import { ImageDisplay } from '../../../../common/components/image-display';
import { TextDisplay } from '../../../../common/components/text-display';
import { useAppMetaContextData } from '../../../../common/utils/app-meta-context';
import { trackClickCobrandedAction, trackViewCobrandedAction } from './tracking';
import { BannerCardProps } from './types';

import './styles.less';

export function BannerCard({ bannerCard }: BannerCardProps) {
    const alreadyTrackedView = useRef(false);
    const { appMeta, meta } = useAppMetaContextData();
    const featureFlagsData = useFeatureFlagsData();
    const showMiniBanner = featureFlagsData?.dWebBannerReposition;

    useEffect(() => {
        if (bannerCard?.action && !alreadyTrackedView.current && !showMiniBanner) {
            alreadyTrackedView.current = true;
            trackViewCobrandedAction(appMeta?.isGuest, meta?.shoppingCartId);
        }
    }, [bannerCard?.action, appMeta, meta]);

    if (!bannerCard?.action || showMiniBanner) {
        return null;
    }

    const { title, subTitle, slug, brandImage, action } = bannerCard;
    const handleClick = () => {
        trackClickCobrandedAction(appMeta?.isGuest, meta?.shoppingCartId);
    };

    return (
        <a target="_blank" href={action.URL} rel="noreferrer" onClick={handleClick}>
            <div className="banner-card">
                <div className="banner-content banner-image">
                    {brandImage && (
                        <ImageDisplay
                            title={brandImage.title}
                            url={brandImage.URL}
                            lazyOnLoad
                        />
                    )}
                </div>

                <div className="banner-content banner-text">
                    <span className="bold-text">
                        <TextDisplay data={title} />
                    </span>

                    <span className="medium-text text-padding">
                        <TextDisplay data={subTitle} />
                    </span>
                    <span className="small-text text-padding">
                        <TextDisplay data={slug} />
                    </span>
                </div>
            </div>
        </a>
    );
}
