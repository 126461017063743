import { TextualDisplay } from '@ebay/experience-types-ebay';
import { ShoppingCartListingSummary, TextualDisplayWithInfoBubble } from '../../../../../common/utils/hydration';
import { CartStateCallback } from '../../../../common/types/cart-state-handler';

export enum SavedForLaterLineItemActionType {
    RemoveItem = 'REMOVE_ITEM',
    AddBackToCart = 'ADD_BACK_TO_CART',
    GoToSimilarItems = 'GO_TO_SIMILAR_ITEMS'
}
export interface SavedForLaterLineItemType {
    actions?: { [key in SavedForLaterLineItemActionType]?: TextualDisplayWithInfoBubble | TextualDisplay };
    listingSummaries?: ShoppingCartListingSummary [];
}

export interface SavedForLaterLineItemProps {
    lineItem?: SavedForLaterLineItemType;
    onAddBackToCart?: CartStateCallback;
    onRemoveItem?: CartStateCallback;
}

export interface AddToCartRequest {
    lineItemId?: string;
}

export interface RemoveItemRequest {
    lineItemId?: string;
}

export interface SavedForLaterActionButtonProps {
    action?: TextualDisplay;
    onActionSuccess?: CartStateCallback;
}
