import React from 'react';
import { ClientLoggerContext } from '../../common/utils/client-logger';
import { AdsErrorBoundaryProps, AdsErrorBoundaryState } from './types';

export class AdsErrorBoundary extends React.Component<AdsErrorBoundaryProps, AdsErrorBoundaryState> {
    static contextType = ClientLoggerContext;
    context!: React.ContextType<typeof ClientLoggerContext>;

    constructor(props: AdsErrorBoundaryProps) {
        super(props);

        this.state = {
            error: false
        };
    }

    componentDidCatch(error: Error) {
        this.setState({
            error: true
        });

        this.context?.error?.('ADS_RENDERING_ERROR', {
            name: error?.name,
            message: error?.message,
            stack: error?.stack || ''
        });
    }

    render() {
        if (this.state.error) {
            return null;
        }

        return this.props.children;
    }
}

