import { useContext, useMemo } from 'react';
import { PageMaskContext } from './context';

export function usePageMask() {
    const setPageMaskLoading = useContext(PageMaskContext);
    const pageMask = useMemo(() => ({
        show: () => {
            if (setPageMaskLoading) {
                setPageMaskLoading(true);
            }
        },
        hide: () => {
            if (setPageMaskLoading) {
                setPageMaskLoading(false);
            }
        },
    }), [setPageMaskLoading]);

    return pageMask;
}
