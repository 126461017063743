import { LayoutProps } from '../../../../common/utils/hydration';

import { DefaultLayout } from './default-layout';
import { SingleColumnLayout } from './single-column-layout';

import './styles.less';

export function Layout({ initialState }: LayoutProps) {
    if (initialState?.experienceConfig?.renderSingleColumnLayout) {
        return <SingleColumnLayout initialState={initialState} />;
    }
    return <DefaultLayout initialState={initialState} />;
}
