import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { TRACKING_PLACEHOLDERS } from '../../../../../../common/constants/tracking-placeholders';
import { getActionTrackingData } from '../../../../utils/tracking-utils';
import { TRACKING_DATA_MAPPER } from '../../../../utils/tracking';
import { useAppMetaContextData } from '../../../../utils/app-meta-context';
import { DynamicBannerFallbackProps } from './types';

// This is not a long term solution! This should only be here while we switch from local content (here on cartfe) to
// CMS based content. See, cartfe is set up to only have the i18n bundle on the server side. We just set everything
// up and pass just strings to the client. But for this HTML text, we need dynamic data (e.g. injecting remaining amount)
// or in this case, tracking data. So instead we are going to keep these keys in the HTML so that we can set up
// everything on the server and then just replace the keys with the actual data on the client side.
//
// The other (better) way would to completely rework on tracking is built so that it can be done on the client and
// server. I started doing this to just realize it would take a LONG time to refactor, and then it would only be for
// this one use case which is going to be removed next week. So, for now, please bare with me. <3 kris (@krollert)

// ** This is a temporary solution to replace the data-track attribute with data-click. I put the wrong one in the
// content (data-track) and it's not worth updating the content if we are going to switch to CMS so soon. So the hacky
// solution is to just replace the attribute here.

export function DynamicBannerFallback({ fallbackHtml }: DynamicBannerFallbackProps) {
    const { appMeta, meta } = useAppMetaContextData();
    if (!fallbackHtml) return null;
    else if (fallbackHtml.includes(TRACKING_PLACEHOLDERS.EBAY_BALANCE_LEARN_MORE)) {
        const ebayBalanceLearnMoreTrackingData = getActionTrackingData({
            refActionName: TRACKING_DATA_MAPPER.DYNAMIC_BANNER_EBAY_BALANCE_LEARN_MORE,
            isGuest: appMeta?.isGuest,
            shoppingCartId: meta?.shoppingCartId
        });
        const removeDataTrack = fallbackHtml
            .replace(`data-track="${TRACKING_PLACEHOLDERS.EBAY_BALANCE_LEARN_MORE}"`, '');
        return (
            <EbayTrackingClick trackingList={ebayBalanceLearnMoreTrackingData}>
                <span dangerouslySetInnerHTML={{ __html: removeDataTrack }} />
            </EbayTrackingClick>
        );
    }
    return (<span dangerouslySetInnerHTML={{ __html: fallbackHtml }} />);
}

