export interface SignInChooserData {
    baseUrl?: string;
    enableGuestOTPAuthFlow?: boolean;
    isMobile?: boolean;
    locale?: string;
    returnUrl?: string;
    signInUrlBase?: string;
    gxoUrl?: string;
}

export interface SignInChooserProps {
    open?: boolean;
    data?: SignInChooserData;
    pageId?: string;
}

export enum ClickTrackId {
    Close = '46355',
    SignInButton = '46357',
    GuestCheckoutButton = '46358',
    Open = '46356'
}
