import { Adjustment } from '../../../../../../common/utils/hydration';

export interface AdjustmentProps {
    adjustment?: Adjustment;
}

export enum BrandingType {
    EbayPlus = 'EBAY_PLUS'
}

export interface BrandingConfig {
    iconClass: string;
}
