import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

export function useUniqueId(prefix?: string) {
    const [uniqueId, setUniqueId] = useState('');

    useEffect(() => {
        const id = [
            prefix,
            uuidv4()
        ].filter(Boolean).join('-');

        setUniqueId(id);
    }, [prefix]);

    return uniqueId;
}
