import { TextualDisplay } from '@ebay/experience-types-ebay';

export function hasEmojis(textualDisplay?: TextualDisplay): boolean {
    if (!textualDisplay) {
        return false;
    }

    const { textSpans = [] } = textualDisplay;
    const reg = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
    for (let i = 0; i < textSpans.length; i++) {
        const text = textSpans[i].text;
        if (text?.search(reg) !== -1) {
            return true;
        }
    }

    return false;
}
