import { useContext, useMemo } from 'react';
import { usePageMask } from '../page-mask';
import { SpinnerContext } from './context';
import type { Spinner, SpinnerStateHandler } from './types';

export function useSpinner(): Spinner {
    const pageMask = usePageMask();
    const setIsLoading = useContext<SpinnerStateHandler>(SpinnerContext);
    const spinner = useMemo<Spinner>(() => ({
        show: () => {
            pageMask.show();
            if (setIsLoading) {
                setIsLoading(true);
            }
        },
        hide: () => {
            pageMask.hide();
            if (setIsLoading) {
                setIsLoading(false);
            }
        }
    }), [setIsLoading, pageMask]);

    return spinner;
}
