import { EbayIcon } from '@ebay/ui-core-react/ebay-icon';
import { TextDisplay } from '../../../text-display';
import * as listingSummarySpecs from '../line-item-summary/utils';
import { ItemBadges } from '../../../../../../common/utils/hydration/types';

import './styles.less';


export function ItemBadge({ title }: ItemBadges) {
    const iconName = listingSummarySpecs.getIconName(title?.icon);
    return (
        <div className="grid-item-badge-details">
            {
                iconName ? <EbayIcon name={iconName} /> : null
            }
            <TextDisplay data={title?.text} />
        </div>
    );
}
