import { Fragment, useState, useRef, useEffect, KeyboardEvent } from 'react';
import classNames from 'classnames';
import { EbayField, EbayLabel } from '@ebay/ui-core-react/ebay-field';
import { EbayTextbox } from '@ebay/ui-core-react/ebay-textbox';
import { extractText } from '@ebay/ebayui-app-react/ebay-textual-display';
import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { Action, Message } from '@ebay/experience-types-ebay';
import { TextDisplay } from '../text-display';
import { KeyCodes } from '../../utils/key-codes';
import { copyObject } from '../../../../common/utils/copy';
import { ParamValueTypeForTextbox } from '../../../../common/utils/hydration';
import { useUniqueId } from '../../utils/unique-id';
import { TextboxesProps, TextboxesState, InputType, ParamValueType } from './types';
import { getValidationMessages } from './domain/validations';
import { MAX_INPUT_VAL, SUPPORTED_INPUT_TYPES_MAP } from './domain/specifications';
import './styles.less';

export function Textboxes({ group, actions, textboxTestId, quantityRef, textboxActionTestId, onAction, onMinValue }: TextboxesProps) {
    const [showActions, setShowActions] = useState<boolean>(false);
    const [textboxesState, setTextboxesState] = useState<TextboxesState>([]);
    const textboxUuid = useUniqueId('textbox');
    const textboxAlertUuid = useUniqueId('textbox-alert');
    const showActionsElement = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (group) {
            const initialTextboxesState = copyObject<TextboxesState>(group);
            group.map((textbox, index) => {
                if (!textbox.originalValue) {
                    initialTextboxesState[index].originalValue = textbox.paramValue;
                    setShowActions(false);
                } else {
                    setShowActions(true);
                }
            });
            setTextboxesState(initialTextboxesState);
        }
    }, [group]);

    const handleChange = (value: ParamValueTypeForTextbox, paramKey?: string) => {
        if (!group) {
            return;
        }

        const newTextboxesState = copyObject<TextboxesState>(textboxesState);
        let isOriginalValues = true;
        let isErrorFree = true;

        textboxesState?.map((textbox, index) => {
            let validationMessages: Message[] = [];
            if (textbox.paramKey === paramKey && textbox.paramValue?.toString() !== value.toString()) {
                isOriginalValues &&= value?.toString() === textbox.originalValue?.toString();
                newTextboxesState[index].paramValue = value;
                validationMessages = getValidationMessages(value, textbox.validations);
            } else {
                validationMessages = textbox.validationMessages || [];
                isOriginalValues &&= textbox.paramValue?.toString() === textbox.originalValue?.toString();
            }
            isErrorFree &&= validationMessages.length === 0;
            newTextboxesState[index].validationMessages = validationMessages;
        });

        setShowActions(!isOriginalValues && isErrorFree);
        setTextboxesState(newTextboxesState);
    };

    const handleUpdateLinkClick = (action?: Action) => {
        const data = action && copyObject<Action>(action) || {};
        const params = data.params || {};

        // Check if any textbox should be converted to dropdown
        textboxesState.map(textbox => {
            const paramKey = textbox.paramKey;
            const paramValue = textbox.paramValue?.toString() || '';
            if (paramKey) {
                params[paramKey] = paramValue;
                const paramValueAsNumber = parseInt(paramValue, 10);
                if (!isNaN(paramValueAsNumber) && paramValueAsNumber < MAX_INPUT_VAL && onMinValue) {
                    onMinValue({
                        paramKey,
                        paramValue
                    });
                }
            }
        });

        if (onAction) {
            onAction(data);
        }
    };

    const clickUpdateLink = () => {
        if (showActionsElement?.current) {
            showActionsElement.current.click();
        }
    };

    return (
        <div className="text-boxes">
            {
                textboxesState?.map((textbox, index) => (
                    <Fragment key={index}>
                        <EbayField key={index}>
                            <EbayLabel htmlFor={textboxUuid}>
                                { extractText(textbox.label) }
                            </EbayLabel>
                            <EbayTextbox
                                aria-describedby={textbox?.validationMessages?.length ? textboxAlertUuid : undefined}
                                data-test-id={textboxTestId}
                                id={textboxUuid}
                                min="0"
                                ref={quantityRef}
                                name={textbox.paramKey}
                                defaultValue={textbox.paramValue?.toString()}
                                onInputChange={(event) => {
                                    // React doesn't set the attribute "value" causing issues on webdriver automation test cases
                                    event.target.setAttribute('value', event.target.value);
                                    handleChange(event.target.value, textbox.paramKey);
                                }}
                                type={textbox.paramValueType && SUPPORTED_INPUT_TYPES_MAP[textbox.paramValueType as ParamValueType] || InputType.Text}
                                onKeyDown={(event: KeyboardEvent) => {
                                    if (event.key === KeyCodes.Enter) {
                                        event.preventDefault();
                                        clickUpdateLink();
                                    }
                                }}
                            />
                        </EbayField>
                        {
                            textbox.validationMessages?.map((message, index) => {
                                return (
                                    <div className={classNames('validation-msg', message.messageType)} key={index} role="alert">
                                        <TextDisplay errMsgId={textboxAlertUuid} data={message.title} />
                                    </div>
                                );
                            })
                        }
                    </Fragment>
                ))
            }
            {
                showActions && (
                    <div className="text-box-actions">
                        {
                            Object.entries(actions || {}).map(([key, actionDisplay]) => (
                                <EbayTrackingClick trackingList={actionDisplay.action?.trackingList} key={key}>
                                    <TextDisplay
                                        ref={showActionsElement}
                                        variant="fake-link"
                                        testId={textboxActionTestId}
                                        data={actionDisplay}
                                        onClick={(e, action) => handleUpdateLinkClick(action)}
                                    />
                                </EbayTrackingClick>
                            ))
                        }
                    </div>
                )}
        </div>
    );
}
