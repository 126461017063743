import { useI18n } from '../../utils/i18n';
import { Alerts } from './alerts';
import { createFatalErrorAlert } from './domain/factories';
import { AlertLevel } from './types';

export function FatalErrorAlerts() {
    const i18n = useI18n();

    const errorMessageText = i18n.getGenericErrorMessage();
    const notifications = createFatalErrorAlert(errorMessageText);

    return (
        <Alerts notifications={notifications} level={AlertLevel.Page} errorState />
    );
}
