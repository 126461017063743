import classNames from 'classnames';
import ProgramBadges from '@ebay/payments-program-badges-react-expsvc';
import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { getActionTrackingData } from '../../common/utils/tracking-utils';
import { useAppMetaContextData } from '../../common/utils/app-meta-context/hooks';
import { HorizontalLine } from '../../common/components/horizontal-line';
import { SpinnerContainer } from '../../common/components/spinner';
import { TEST_IDS } from '../../common/utils/test-ids';
import { useBulkActionsContext } from '../../common/components/bulk-actions/context';
import { useFeatureFlagsData } from '../../common/utils/experiments/hooks';
import { CartSummaryCallToAction } from './components/cart-summary-call-to-action';
import { CartSummaryLineItem } from './components/cart-summary-line-item';
import { FormOfPayments } from './components/form-of-payments';
import { UserAgreements } from './components/user-agreements';
import { Rewards } from './components/rewards';
import type { CartSummaryProps } from './types';
import './styles.less';


export function CartSummary({
    cartSummary,
    compactCart,
    callToActionAbove,
    callToActionBelow,
    rewards,
    continueShoppingUrl,
    fixedPosition,
    fopIcons,
    onGoToCheckout
}: CartSummaryProps) {
    const { activeBulkActionsModule, isSessionCTADisabled, selectAllCheckboxChecked } = useBulkActionsContext();
    const bulkActionsContext = useBulkActionsContext();
    const { appMeta, meta } = useAppMetaContextData();
    const featureFlagsData = useFeatureFlagsData();
    const {
        bulkActionItemsMap,
        bulkXoOrderTotal = '',
        buttonQuantitySelected,
        cartBulkXoEnabled,
        showItemDiscountsPricelinesLoadingState,
        showItemPricelinesLoadingState
    } = bulkActionsContext;
    if (!cartSummary) {
        return null;
    }
    const isButtonDisabled = isSessionCTADisabled?.() || false;
    const cartSummaryTestId = compactCart ? TEST_IDS.cartSummaryTop : TEST_IDS.cartSummary;
    const disabledMessage = cartSummary.callToActions?.CHECK_OUT_CART?.disabledMessages?.SELECT_ITEM;
    const isCartItemsMapEmpty = bulkActionItemsMap?.size === 0 && !selectAllCheckboxChecked;
    const showBulkXoControlledFields = selectAllCheckboxChecked || (cartBulkXoEnabled && !isCartItemsMapEmpty) || !cartBulkXoEnabled;

    const getBadgeData = () => {
        const badgeData = cartSummary?.badges;
        if (!appMeta?.isMobile && badgeData && badgeData?.length > 0) {
            delete badgeData?.[0]?.title?.text?.textSpans?.[1]?.action?.trackingList;
        }
        return badgeData;
    };

    const clientTrackingData = getActionTrackingData({
        isGuest: appMeta?.isGuest,
        shoppingCartId: meta?.shoppingCartId,
        refActionName: 'CART_EMBG_LEARN_MORE'
    });

    return (
        <div
            className={
                classNames('cartsummary order-summary-optimization',
                    {
                        'compact-cart': compactCart,
                        'cartsummary--bulk-actions-active': activeBulkActionsModule !== null && !cartBulkXoEnabled,
                        'sticky': fixedPosition,
                        'cart-summary--bulk-xo-active': cartBulkXoEnabled,
                        'cart-summary--grey-background': featureFlagsData?.mobileEMBGWithGreyBackgroundSummary,
                    })
            }
            data-test-id={cartSummaryTestId}>
            <SpinnerContainer>
                {cartSummary.moduleTitle && !callToActionAbove && (
                    <h2 className="clipped">{cartSummary.moduleTitle}</h2>
                )}
                {callToActionAbove && (
                    <CartSummaryCallToAction
                        continueShoppingUrl={continueShoppingUrl}
                        disabled={isButtonDisabled}
                        onGoToCheckout={onGoToCheckout}
                        callToActions={cartSummary.callToActions}
                        callToActionAbove />
                )}
                {
                    cartBulkXoEnabled && isCartItemsMapEmpty && disabledMessage &&
                    (
                        <div className='bulk-xo-disabled-text'>
                            <span>
                                {disabledMessage}
                            </span>
                        </div>
                    )
                }

                <div className={
                    classNames('table',
                        {
                            'hidden': (cartBulkXoEnabled && isCartItemsMapEmpty)
                        })
                }>
                    { Boolean(cartSummary.summaryItems?.length) && (
                        <>
                            {cartSummary.summaryItems?.map((summaryItem, index) => (
                                <CartSummaryLineItem
                                    key={index}
                                    cartSummaryItem={summaryItem}
                                    showItemDiscountsPricelinesLoadingState={showItemDiscountsPricelinesLoadingState}
                                    showItemPricelinesLoadingState={showItemPricelinesLoadingState}
                                    shouldSplitItemsCountFromLabel={cartBulkXoEnabled}
                                    quantity={cartBulkXoEnabled && !selectAllCheckboxChecked ? buttonQuantitySelected : undefined}
                                />
                            ))}

                            <HorizontalLine />
                        </>
                    )}
                    {
                        showBulkXoControlledFields && (
                            <CartSummaryLineItem cartSummaryItem={cartSummary.total} tdClass="total-row"
                                quantity={cartBulkXoEnabled && !selectAllCheckboxChecked ? buttonQuantitySelected : undefined}
                                bulkXoOrderTotal={selectAllCheckboxChecked ? '' : bulkXoOrderTotal}
                                shouldSplitItemsCountFromLabel={cartBulkXoEnabled}
                                showItemDiscountsPricelinesLoadingState={showItemDiscountsPricelinesLoadingState}
                                showItemPricelinesLoadingState={showItemPricelinesLoadingState}
                            />
                        )
                    }

                    {/*
                        TODO: @vshrivastav Once Bulk XO EP is completed we need to revisit if this message is still needed
                        {cartBulkXoEnabled && !isCartItemsMapEmpty && cartSummary.messages ? (cartSummary.messages.map((message, index) => (
                            <div key={index} className="messages">
                                <TextDisplay data={message} />
                            </div>
                        ))) : null}
                    */}
                </div>
                {
                    !(appMeta?.isMobile) && (
                        <FormOfPayments icons={fopIcons} />
                    )
                }

                {
                    showBulkXoControlledFields && (
                        <UserAgreements userAgreements={cartSummary.userAgreements} />
                    )
                }
                <Rewards rewards={rewards} />
                {callToActionBelow && (
                    <>
                        {
                            <FormOfPayments icons={fopIcons} />
                        }
                        <CartSummaryCallToAction
                            continueShoppingUrl={continueShoppingUrl}
                            disabled={isButtonDisabled}
                            onGoToCheckout={onGoToCheckout}
                            callToActions={cartSummary.callToActions}
                            callToActionAbove={false}
                        />
                    </>
                )}
                {
                    showBulkXoControlledFields && (
                        <EbayTrackingClick trackingList={clientTrackingData} >
                            <ProgramBadges
                                type="buyer-protection"
                                data={getBadgeData()}
                                testIds={TEST_IDS} />
                        </EbayTrackingClick>
                    )
                }
            </SpinnerContainer>
        </div>
    );
}