import { SellerBucket } from '../../../../common/utils/hydration';
import type { CartBucketActions } from '../types';

export function createMenuActions(sellerBucket: SellerBucket): CartBucketActions | null {
    if (!sellerBucket?.actionMenu) {
        return null;
    }

    const payOnlyThisSellerAction = sellerBucket.actionMenu.actions?.PAY_ONLY_THIS_SELLER;
    const requestTotalAction = sellerBucket.actionMenu.actions?.REQUEST_TOTAL;

    const actions = [];

    if (payOnlyThisSellerAction) {
        actions.push(payOnlyThisSellerAction);
    }

    if (requestTotalAction) {
        actions.push(requestTotalAction);
    }

    return {
        actions,
        cancelAction: sellerBucket.actionMenu.actions?.CLOSE_ACTION_MENU,
        title: sellerBucket.actionMenu.menuTitle
    };
}
