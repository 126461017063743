import { TEST_IDS } from '../../../../common/utils/test-ids';
import { TextDisplay } from '../../../../common/components/text-display';
import { RewardsProps } from './types';

import './styles.less';

export function Rewards({ rewards }: RewardsProps) {
    if (!rewards?.rewardDetails?.length) {
        return null;
    }

    return (
        <div className="rewards" data-test-id={TEST_IDS.rewards}>
            {rewards.rewardDetails.map((rewardDetail, index) => (
                <TextDisplay key={index} data={rewardDetail.text} />
            ))}
        </div>
    );
}
