import { EbayTourtip, EbayTourtipContent, EbayTourtipHost } from '@ebay/ui-core-react/ebay-tourtip';
import { BulkActionsData } from '../../../../../common/utils/hydration';
import { useI18n } from '../../../../common/utils/i18n';
import { useFeatureFlagsData } from '../../../../common/utils/experiments';
import type { BulkActionTourtipInactiveContentProps } from '../types';
import './bulk-actions-tourtip-content.less';

export function BulkActionTourtipInactiveContent({
    children,
    pointer,
    data,
    onCollapse,
    overlayStyle
}: BulkActionTourtipInactiveContentProps) {
    const i18n = useI18n();
    let bulkModeEnabled = false;
    const { cartBulkXoEnabled = false } = useFeatureFlagsData();
    if ((!data || Object.keys(data).length === 0) && !cartBulkXoEnabled) {
        return null;
    }

    if (data) {
        const { enableBulkMode }: BulkActionsData = data;
        bulkModeEnabled = Object.keys(enableBulkMode).length > 0;
    }

    {/* TODO once interface is done change a11yCloseText */}
    const showBulkActionTourTip = (bulkModeEnabled || cartBulkXoEnabled);
    if (showBulkActionTourTip) {
        return (
            <EbayTourtip a11yCloseText={i18n.getBulkActionsTourtipA11YClose()} pointer={pointer} onCollapse={onCollapse} overlayStyle={overlayStyle}>
                <EbayTourtipHost>
                    {children}
                </EbayTourtipHost>
                <EbayTourtipContent>
                    { cartBulkXoEnabled ?
                        i18n.getBulkCheckoutTourTipContent() :
                        i18n.getBulkActionsTourtipContent()
                    }
                </EbayTourtipContent>
            </EbayTourtip>
        );
    }
    return null;
}
