import { HtmlModule } from '@ebay/nuts-js-client/src/server';
import { AdsData } from '../../../../common/utils/hydration';
import { AjaxCallMaker } from '../../../common/utils/ajax';

export async function fetchAds(makeAjaxCall: AjaxCallMaker<AdsData>, adsData: AdsData): Promise<HtmlModule | undefined | null> {
    return makeAjaxCall('/api/merch', adsData, undefined, false)
        .then(response => {
            return response?.modules?.adsListings;
        })
        .catch(() => {
            console.error('Error loading ads');
            return null;
        });
}
