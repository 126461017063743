import { BrandingConfig, BrandingType } from '../types';

const BRANDING_CONFIGS: { [key in BrandingType]: BrandingConfig } = {
    [BrandingType.EbayPlus]: {
        iconClass: 'ebay-plus'
    }
};

export function getIconClass(iconName: BrandingType): string | undefined {
    return BRANDING_CONFIGS[iconName]?.iconClass;
}
