import '@ebay/cookies-browser';
import '@ebay/site-speed-ebay/client/sitespeed';
import 'site-speed-above-the-fold-timer';
import { FunctionComponent } from 'react';
import { clearInitialState, getInitialState, initApp, LayoutProps } from '../../common/utils/hydration';
import { flushTrackingEvents } from '../common/utils/tracking';
import { trackPageImpressions } from '../common/utils/tracking-utils';
import { AjaxProvider, createPlainAjaxCallMaker, getAjaxContext } from '../common/utils/ajax';
import { ClientLogger, ClientLoggerProvider } from '../common/utils/client-logger';
import { loadQuantumScript } from '../common/utils/quantum';
import { addWindowLoadEvent, refreshPageOnBackButton, addWindowUnLoadEvent } from '../common/utils/dom';
import { showApp } from '../../common/utils/hydration/hydration';
import { prefetchXOResource } from '../common/utils/resource-preload';
import { ErrorBoundary } from '../common/components/error-boundary';

// For dynamically loaded chunks webpack needs to be aware of the full url from which to load these resources.
// Because the URL is not known during build time we must set the public path dynamically based on the results
// of @ebay/resource-client in the __webpack_public_path__ variable.
if (window.PUBLIC_PATH) {
    __webpack_public_path__ = window.PUBLIC_PATH;
}

function removeSilhouettes() {
    const silhouettes = document.querySelector('.silhouettes');

    if (silhouettes) {
        silhouettes.parentNode?.removeChild(silhouettes);
    }
}


export function browserInit(Layout: FunctionComponent<LayoutProps>) {
    const initialState = getInitialState();
    document.title = initialState?.meta?.pageTitle || '';
    const ajaxContext = getAjaxContext(initialState);
    const makeAjaxCall = createPlainAjaxCallMaker(ajaxContext);
    const clientLogger = new ClientLogger(makeAjaxCall);

    initApp(
        <ErrorBoundary clientLogger={clientLogger} genericErrorMessage={initialState?.i18n?.genericErrorMessage}>
            <AjaxProvider initialState={initialState}>
                <ClientLoggerProvider>
                    <Layout initialState={initialState} />
                </ClientLoggerProvider>
            </AjaxProvider>
        </ErrorBoundary>
    );
    removeSilhouettes();
    showApp();

    addWindowLoadEvent(() => {
        loadQuantumScript(initialState?.appSiteConfig?.quantumMetrics);
        if (initialState?.appConfig?.xoPrefetchEnabled) {
            prefetchXOResource(initialState, clientLogger);
        }
        trackPageImpressions({
            ebc: initialState?.meta?.shoppingCartId,
            isGuest: initialState?.appMeta?.isGuest
        });
    });

    addWindowUnLoadEvent(() => {
        flushTrackingEvents();
    });
    refreshPageOnBackButton();
    clearInitialState();
}

