import { ReactNode } from 'react';

export enum ClientLogType {
    Error = 'error',
    Exception = 'exception',
    Info = 'info',
    Warning = 'warning'
}

export type ClientLogData = Record<string, number | string>;

export interface ClientLog extends ClientLogData {
    logType: ClientLogType;
    logName: string;
}

export interface LoggerFunction {
    (name: string, data?: ClientLogData): void;
}

export interface Logger {
    error: LoggerFunction;
    exception: LoggerFunction;
    info: LoggerFunction;
    warning: LoggerFunction;
}

export interface ClientLoggerProviderProps {
    children: ReactNode;
}
