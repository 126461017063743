import classNames from 'classnames';
import { useBulkActionsContext } from '../context';
import { BulkActionEditableContent } from './bulk-action-editable-content';
import { BulkActionInactiveContent } from './bulk-action-inactive-content';
import type { BulkActionsContentProps } from '../types';
import './bulk-actions-desktop.less';

export function BulkActionsContent({
    activeState,
    activeBulkActionsModule,
    moduleName,
    bulkActionItemsMap,
    data,
    editable,
    handleAction,
    selectAllCheckboxChecked
}: BulkActionsContentProps) {

    const bulkActionContext = useBulkActionsContext();
    const {
        buttonQuantitySelected,
        shoppingCartBulkActionsTourTip,
        shouldRenderBulkActionsTourtip,
        setShouldRenderBulkActionsTourtip,
        setTourtipManuallyClosed,
    } = bulkActionContext;

    if (!data) {
        return null;
    }

    const handleOnTourtipCollapse = () => {
        setShouldRenderBulkActionsTourtip(false);
        setTourtipManuallyClosed(true);
    };

    return (
        <div className={classNames('cart-bulk-actions__content', editable && 'cart-bulk-actions__content--editable')}>
            {editable ? (
                <BulkActionEditableContent
                    activeState= {activeState}
                    bulkActionItemsMap={bulkActionItemsMap}
                    buttonQuantitySelected={buttonQuantitySelected}
                    data={data}
                    handleAction={handleAction}
                    selectAllCheckboxChecked={selectAllCheckboxChecked}
                    moduleName={moduleName}
                />
            ) : (
                <BulkActionInactiveContent
                    activeState= {activeState}
                    activeBulkActionsModule={activeBulkActionsModule}
                    shoppingCartBulkActionsTourTip={shoppingCartBulkActionsTourTip}
                    data={data}
                    handleAction={handleAction}
                    handleOnTourtipCollapse={handleOnTourtipCollapse}
                    shouldRenderTourtip={shouldRenderBulkActionsTourtip}
                    moduleName={moduleName}
                />
            )}
        </div>
    );
}