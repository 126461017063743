import { useEffect, useState } from 'react';
import { SpinnerContext } from './context';
import { Spinner } from './spinner';
import type { SpinnerContainerProps } from './types';

export function SpinnerContainer({ children, show = false }: SpinnerContainerProps) {
    const [isLoading, setIsLoading] = useState(show);

    useEffect(() => {
        setIsLoading(show);
    }, [show]);

    return (
        <SpinnerContext.Provider value={setIsLoading}>
            {isLoading && <Spinner />}
            {children}
        </SpinnerContext.Provider>
    );
}
