import { Action } from '@ebay/experience-types-ebay';
import { EbayLabel } from '@ebay/ui-core-react/ebay-field';
import { extractText } from '@ebay/ebayui-app-react/ebay-textual-display';
import { AlertLevel, Alerts } from '../alerts';
import { BulkActionsCheckbox } from '../../../common/components/bulk-actions/bulk-actions-checkbox';
import { SpinnerContainer, useSpinner } from '../spinner';
import { useFocusOnActionContext } from '../../../common/utils/focus-on-action/context';
import { FocusOnActionActionNames } from '../../../../common/utils/hydration';
import { BulkActionsHookData } from '../bulk-actions/types';
import { useBulkActionsContext } from '../bulk-actions/context';
import { isAttributeValueTrue, buildNotificationsFromAttributes } from '../../../common/components/grid-line-item/utils';
import { useI18n } from '../../utils/i18n';
import { ListingSummary } from './components/listing-summary';
import { LineItemProps } from './types';

export function LineItem(
    {
        actions,
        disabled,
        lineItem,
        moduleName,
        onAction,
        bulkActionSelection,
        firstLineItem,
        seller
    }: LineItemProps
) {
    const { activeBulkActionsModule = null, cartBulkXoEnabled }: BulkActionsHookData = useBulkActionsContext();
    const spinner = useSpinner();
    const { handleFocusOnAction } = useFocusOnActionContext();
    const i18n = useI18n();

    if (!lineItem) return null;

    const handleAction = async(action?: Action) => {
        if (onAction) {
            spinner.show();
            await onAction(action);
            handleFocusOnAction?.({
                actionName: action?.name as FocusOnActionActionNames,
                moduleName: moduleName
            });
            spinner.hide();
        }
    };

    const isInvoice = lineItem?.listingSummaries && lineItem?.listingSummaries?.length > 1;
    const showLineItemCheckbox = cartBulkXoEnabled && !!lineItem?.bulkActionSelection && isInvoice;

    const showLocalNotification =
        isAttributeValueTrue(lineItem.attributes?.uiAttributes, 'showLocalNotification');
    const notifications = showLocalNotification ?
        buildNotificationsFromAttributes(lineItem.attributes, i18n) :
        lineItem.notifications;

    return (
        <SpinnerContainer>
            <div className='line-item-ctr'>
                {notifications && (
                    <div className="notifications">
                        <Alerts notifications={notifications} level={AlertLevel.Inline} />
                    </div>
                )}
                {showLineItemCheckbox && activeBulkActionsModule && (
                    <div className="line-item-ctr__bulk-invoice-checkbox">
                        <BulkActionsCheckbox
                            bulkActionSelection={lineItem?.bulkActionSelection}
                            itemDisabled={disabled}
                            lineItemId={lineItem?.lineItemId || ''}
                            firstLineItem={firstLineItem}
                            lineItemSummaries={lineItem?.listingSummaries || []}
                            seller={seller}
                        >
                            <EbayLabel>
                                <span> {extractText(lineItem?.bulkActionSelection?.label)} </span>
                            </EbayLabel>
                        </BulkActionsCheckbox>
                    </div>
                )}
                {
                    lineItem.listingSummaries?.map((listingSummary) => {
                        return (
                            <ListingSummary
                                actions={actions}
                                disabled={disabled}
                                isInvoice={isInvoice}
                                firstLineItem={firstLineItem}
                                handleAction={handleAction}
                                key={listingSummary.listingId}
                                lineItem={lineItem}
                                listingSummary={listingSummary}
                                moduleName={moduleName}
                                seller={seller}
                            />
                        );
                    })
                }
            </div>
        </SpinnerContainer>
    );
}
