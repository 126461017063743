import { Action } from '@ebay/experience-types-ebay';
import { TEST_IDS, testIdKeyFromAction } from '../../../../common/utils/test-ids';
import { CallToAction } from '../../../../common/components/call-to-action';
import { CallToActionNames } from '../../../../../common/utils/hydration';
import { useAjax, ROUTES } from '../../../../common/utils/ajax';
import { usePageMask } from '../../../../common/components/page-mask';
import { useBulkActionsContext } from '../../../../common/components/bulk-actions/context';
import { useAppMetaContextData } from '../../../../common/utils/app-meta-context/hooks';
import { useFeatureFlagsData } from '../../../../common/utils/experiments';
import { manualTagDropper } from '../../../../../common/utils/experimentation/manual-tag-dropper';
import { dropXTTagsForGuestOTPAuthFlow } from '../../../../common/utils/auth-modal/util';
import { CartSummaryCallToActionProps } from './types';
import { createGoToCheckoutParams, createGoToBulkCheckoutUrl } from './domain/factories';
import { SUPPORTED_ORDERED_ACTIONS } from './domain/constants';
import { navigateToUrl } from './domain/services';
import './styles.less';

export function CartSummaryCallToAction({ disabled = false, callToActionAbove, callToActions, onGoToCheckout, continueShoppingUrl }: CartSummaryCallToActionProps) {
    const pageMask = usePageMask();
    const makeAjaxCall = useAjax();
    const { appMeta, meta } = useAppMetaContextData();
    const featureFlagsData = useFeatureFlagsData();
    const isDesktop = !appMeta?.isMobile;

    let checkOutCartTestId = callToActionAbove ? TEST_IDS.callToActionTop : TEST_IDS.callToActionBottom;
    if (isDesktop) {
        checkOutCartTestId = TEST_IDS.callToActionTop;
    }

    const bulkActionContext = useBulkActionsContext();
    const { bulkActionItemsMap, cartBulkXoEnabled } = bulkActionContext;

    const handleAction = (action?: Action) => {
        pageMask.show();
        if (cartBulkXoEnabled) {
            const bulkCheckoutRedirectUrl = createGoToBulkCheckoutUrl(appMeta?.baseUrl, bulkActionItemsMap, meta?.shoppingCartId);
            if (!bulkCheckoutRedirectUrl) {
                window.location.reload();
                return;
            }
            navigateToUrl(bulkCheckoutRedirectUrl);
        } else if (action?.name === CallToActionNames.CheckoutCart) {
            if (appMeta?.isGuest && featureFlagsData?.enableGuestOTPAuthFlow) {
                dropXTTagsForGuestOTPAuthFlow(featureFlagsData);

                // Hide the applied page mask when user closes the HBI Modal
                pageMask.hide();
            }
            const params = createGoToCheckoutParams(action);
            makeAjaxCall(ROUTES.goToCheckout, params)
                .then(response => {
                    if (onGoToCheckout) {
                        onGoToCheckout(response);
                    }
                })
                .catch((error: Error) => {
                    pageMask.hide();
                    console.error('[onGoToCheckout] Error in the request', error?.message);
                });
        } else if (action?.name === CallToActionNames.ContinueShopping) {
            navigateToUrl(continueShoppingUrl || action.URL);
        } else if (action?.name === CallToActionNames.RedirectToCheckout) {
            if (featureFlagsData?.preRenderCheckoutFromEdge) {
                manualTagDropper(featureFlagsData.preRenderCheckoutFromEdge.treatmentId, featureFlagsData.qtags, appMeta?.pageId);

                if (featureFlagsData?.preRenderCheckoutFromEdge?.value) {
                    action.URL = `${action.URL || ''}&edge=1`;
                }
            }
            navigateToUrl(action.URL);
        }
    };

    return (
        <div className='cart-summary-call-to-action'>
            {
                SUPPORTED_ORDERED_ACTIONS.map((actionName, index) => {
                    const cartCallToAction = callToActions?.[actionName];
                    const testId = TEST_IDS[testIdKeyFromAction[actionName]];
                    const isButtonDisabled = cartCallToAction?.disabled || disabled;
                    if (cartCallToAction) {
                        return (
                            <CallToAction
                                {...cartCallToAction}
                                key={index}
                                className={(index > 0 && 'additional-call-to-action') || ''}
                                disabled={isButtonDisabled}
                                testId={actionName === CallToActionNames.CheckoutCart ? checkOutCartTestId : testId}
                                onAction={handleAction}
                                isCTAOnBottom={!callToActionAbove}
                            />
                        );
                    }
                    return null;
                })
            }
        </div>
    );
}
