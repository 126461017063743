import classNames from 'classnames';
import { TextDisplay } from '../../../text-display';
import { BrandingType } from '../../../../../../common/utils/hydration';
import type { BrandingConfig, BrandingProps } from './types';

import './styles.less';

const BRANDING_CONFIGS: { [key in BrandingType]: BrandingConfig } = {
    [BrandingType.EbayFastAndFree]: {
        iconClass: 'fast-n-free',
        messageClass: 'fast-n-free-msg'
    },
    [BrandingType.EbayPlusApplied]: {
        iconClass: 'ebay-plus'
    },
    [BrandingType.FastAndFreeGuaranteed]: {
        iconClass: 'fast-n-free',
        messageClass: 'fast-n-free-msg'
    }
};

export function ItemBranding({ brandingType, message }: BrandingProps) {
    const brandingConfig = brandingType
        ? BRANDING_CONFIGS[brandingType]
        : undefined;

    if (!message && !brandingConfig?.iconClass) {
        return null;
    }

    return (
        <div className="branding">
            {brandingConfig?.iconClass ? (
                <span className={brandingConfig.iconClass} />
            ) : null}

            {message ? (
                <span className={classNames('branding-message', brandingConfig?.messageClass)}>
                    <TextDisplay data={message} />
                </span>
            ) : null}
        </div>
    );
}
