import { Notification } from '../../../../common/utils/hydration';

export enum AlertLevel {
    Inline = 'inline',
    Page = 'page'
}

export enum AlertStatus {
    Attention = 'attention',
    Confirmation = 'confirmation',
    Information = 'information'
}

export interface AlertsProps {
    notifications?: Notification[];
    testId?: string;
    errorState?: boolean;
    level?: AlertLevel;
}
