import { FeatureFlagsData } from '../../../../../common/utils/hydration';
import { manualTagDropper } from '../../../../../common/utils/experimentation/manual-tag-dropper';
import { getCartPageId } from '../../../utils/tracking-utils';

export const getIsInFGQLDynamicBannerEPTreatment = (featureFlagsData?: FeatureFlagsData) =>
    featureFlagsData?.enableDynamicBanner?.value?.xoBundledDynamicFopBanner === 'true';

export const getIsInFGQLDynamicBannerEPControl = (featureFlagsData?: FeatureFlagsData) =>
    featureFlagsData?.enableDynamicBanner?.value?.xoBundledDynamicFopBanner === 'false';

export const getIsInFGQLDynamicBannerEP = (featureFlagsData?: FeatureFlagsData) =>
    getIsInFGQLDynamicBannerEPTreatment(featureFlagsData) || getIsInFGQLDynamicBannerEPControl(featureFlagsData);

export function dropXTTagsForFGQLDynamicBanner(featureFlagsData?: FeatureFlagsData, isGuest = false) {
    const treatmentId = featureFlagsData?.enableDynamicBanner?.treatmentId;
    if (getIsInFGQLDynamicBannerEP(featureFlagsData) && treatmentId) {
        manualTagDropper(treatmentId, featureFlagsData?.qtags, String(getCartPageId(isGuest)));
    }
}
