import classNames from 'classnames';
import { ReactEventHandler, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { getPageLoadEventName, isElementInViewport, isPageLoadEventFired } from '../../utils/dom';
import { addSiteSpeedATFTimer } from '../../utils/site-speed/site-speed-page-load';
import { ImageDisplayProps } from './types';

import './styles.less';

const ERROR_CLASS = 'img-err';
const ERROR_URL = 'https://p.ebaystatic.com/aw/shoppingcart/image_broken.svg';
const VIEWPORT_MARGIN = 150;

export function ImageDisplay({ title, url, className, lazyOnLoad, ...rest }: ImageDisplayProps) {
    const ref = useRef<HTMLImageElement>(null);
    const timeoutId = useRef<number>(0);
    const [errorClass, setErrorClass] = useState('');
    const [imageUrl, setImageUrl] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState(true);

    const loadImage = useCallback((): boolean => {
        if (url && isElementInViewport(ref.current, VIEWPORT_MARGIN)) {
            setImageUrl(url);
            return true;
        }

        return false;
    }, [url]);

    const handleLazyLoad = useCallback(() => {
        if (!timeoutId.current) {
            const imageLoadStarted = loadImage();

            if (imageLoadStarted) {
                timeoutId.current = window.setTimeout(() => {
                    timeoutId.current = 0;
                }, 500);

                window.removeEventListener('scroll', handleLazyLoad);
                window.removeEventListener('resize', handleLazyLoad);
            }
        }
    }, [loadImage]);

    // Use useLayoutEffect since page load event can happen before "useEffect"
    // This issue is more visible on iOS devices
    useLayoutEffect(() => {
        window.addEventListener('scroll', handleLazyLoad);
        window.addEventListener('resize', handleLazyLoad);

        if (lazyOnLoad && !isPageLoadEventFired()) {
            window.addEventListener(getPageLoadEventName(), handleLazyLoad);
        } else {
            handleLazyLoad();
        }

        return () => {
            window.removeEventListener('scroll', handleLazyLoad);
            window.removeEventListener('resize', handleLazyLoad);
            window.removeEventListener(getPageLoadEventName(), handleLazyLoad);
        };
    }, [lazyOnLoad, handleLazyLoad]);

    const handleImageLoadError = () => {
        setErrorClass(ERROR_CLASS);
        setImageUrl(ERROR_URL);
        setIsLoading(false);
    };

    const handleOnLoad: ReactEventHandler<HTMLImageElement> = (event) => {
        addSiteSpeedATFTimer(event?.currentTarget);
        setIsLoading(false);
    };

    return (
        <div className="image-display">
            {isLoading && <div className="image-display__placeholder" />}
            <img
                ref={ref}
                alt={title}
                src={imageUrl}
                className={classNames(className, isLoading && 'img-loading', errorClass)}
                onError={handleImageLoadError}
                onLoad={handleOnLoad}
                {...rest}
            />
        </div>
    );
}
