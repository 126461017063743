import { TextDisplay } from '../../../../common/components/text-display';
import { UserAgreementProps } from './types';

import './styles.less';

export function UserAgreement({ userAgreement }: UserAgreementProps) {
    const { legalText, termsAndConditions = [] } = userAgreement || {};
    if (!termsAndConditions.length && !legalText) { return null; }
    return (
        <div className="user-agreement">
            { termsAndConditions.map((data, i) => <p key={i}><TextDisplay data={data} /></p>) }
            { legalText && <p><span dangerouslySetInnerHTML={{ __html: legalText }} /></p>}
        </div>
    );

}

