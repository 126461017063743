import { ItemDetails } from '../item-details';
import { PriceAndQuantity } from '../price-and-quantity-details';
import './styles/styles.less';
import type { ItemPriceAndQuantityProps } from './types';

export function ItemPriceAndQuantity({
    hasBulkActions,
    listingSummary,
    lineItemId,
    onAction,
    moduleName,
}: ItemPriceAndQuantityProps) {

    if (!listingSummary) {
        return null;
    }

    return (
        <>
            {listingSummary.title && (
                <ItemDetails
                    listingSummary={listingSummary}
                    lineItemId={lineItemId}
                    hasBulkActions={hasBulkActions}
                    moduleName={moduleName}
                />
            )}
            <PriceAndQuantity
                hasBulkActions={hasBulkActions}
                listingSummary={listingSummary}
                onAction={onAction}
            />
        </>
    );
}