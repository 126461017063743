import { trackRover } from '../../../common/utils/tracking/tracking';
import { ClickTrackId } from '../types';

function fireRoverTrackingEvent(pageId?: string, clickTrackId?: ClickTrackId): void {
    if (!pageId || !clickTrackId) {
        return;
    }

    const MODULE_ID = '43896';
    const trackingData = { sid: `p${pageId}.m${MODULE_ID}.l${clickTrackId}` };

    trackRover(trackingData);
}

export function trackOpen(pageId?: string) {
    fireRoverTrackingEvent(pageId, ClickTrackId.Open);
}

export function trackClose(pageId?: string) {
    fireRoverTrackingEvent(pageId, ClickTrackId.Close);
}

export function trackSignInButtonClick(pageId?: string) {
    fireRoverTrackingEvent(pageId, ClickTrackId.SignInButton);
}

export function trackGuestCheckoutButtonClick(pageId?: string) {
    fireRoverTrackingEvent(pageId, ClickTrackId.GuestCheckoutButton);
}
