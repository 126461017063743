import { Action } from '@ebay/experience-types-ebay';

export function isNavigationType(action?: Action) {
    return action?.type === 'NAV';
}

export function isOperationType(action?: Action) {
    return action?.type === 'OPERATION';
}

export function getUrl(action?: Action) {
    return action?.URL;
}

export function getTrackingList(action?: Action) {
    return action?.trackingList;
}
// Use OPEN_WINDOW to open in a new page/tab following what Native implementation does
// https://docs.google.com/document/d/1gyZBrJLfO6F8nuybhkSHs00cpPtIzZRr7njbXdXM_NE/edit#heading=h.d20znhum60xm
export const shouldOpenLinkInANewPage = (action?: Action) =>
    action?.clientPresentationMetadata?.presentationType === 'OPEN_WINDOW';
