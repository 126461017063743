import cx from 'classnames';
import { EbayIcon } from '@ebay/ui-core-react/ebay-icon';
import { TextDisplay } from '../../../text-display';
import * as listingSummarySpecs from '../line-item-summary/utils';
import { useBulkActionsContext } from '../../../bulk-actions/context';
import { ItemQuantity } from '../item-quantity';
import { ItemPriceDetails } from '../item-price-details';
import { ItemLogisticsDetails } from '../item-logistics-details';
import { ItemLogisticsCosts } from '../item-logistics-costs';
import { ItemBranding } from '../item-branding';
import { PriceAndQuantityProps } from './types';
import './styles/styles.less';

export function PriceAndQuantity({
    hasBulkActions,
    listingSummary,
    onAction,
    priceQtyClass,
}: PriceAndQuantityProps) {

    const { activeBulkActionsModule } = useBulkActionsContext();
    if (!listingSummary) {
        return null;
    }
    const onlyLogistics = listingSummary.__sc?.logistics?.logisticsDetails && !listingSummary.__sc?.brandingMessages?.length;
    const logisticDetailsAndItemBranding = listingSummary.__sc?.logistics?.logisticsDetails && listingSummary.__sc?.brandingMessages?.length;
    const itemDisabled = listingSummary?.__sc?.disabled || false;
    const statusMessagePosition = itemDisabled ? 'item-disabled' : 'last-row';

    return (
        <div className={cx('grid-item-price-and-quantity', priceQtyClass, {
            'grid-item-price-and-quantity--active-bulk-actions': hasBulkActions && activeBulkActionsModule,
            'grid-item--disabled': itemDisabled,
        })} >
            <div className="page-grid">
                {(listingSummary.quantity || listingSummary.__sc?.quantityForm) && (
                    <div className="grid-item-quantity">
                        <ItemQuantity
                            entryForm={listingSummary.__sc?.quantityForm?.entryForm}
                            selectionForm={listingSummary.__sc?.quantityForm?.selectionForm}
                            quantity={listingSummary.quantity}
                            onAction={onAction} />
                    </div>
                )}
                {(listingSummary.displayPrice || listingSummary.additionalPrice) && (
                    <div className="grid-item-price">
                        <ItemPriceDetails
                            price={listingSummary.displayPrice}
                            originalPrice={listingSummary.additionalPrice}
                            additionalPrices={listingSummary.__sc?.additionalPrices}
                            discountMessage={listingSummary?.__sc?.discountMessage}
                            priceChangePercentage={listingSummary?.__sc?.priceChangePercentage} />
                    </div>
                )}
                {(listingSummary.quantity || listingSummary.__sc?.quantityForm) && (
                    <div className="grid-item-quantity-lower">
                        <ItemQuantity
                            entryForm={listingSummary.__sc?.quantityForm?.entryForm}
                            selectionForm={listingSummary.__sc?.quantityForm?.selectionForm}
                            onAction={onAction}
                            quantity={listingSummary.quantity} />
                    </div>
                )}
                <div className='grid-item-left-section'>
                    {listingSummary.__sc?.logistics?.logisticsDetails && (
                        <div className='grid-item-logistic-details'>
                            <ItemLogisticsDetails logisticsDetails={listingSummary.__sc?.logistics?.logisticsDetails} />
                        </div>
                    )}
                </div>
                <div className='grid-item-right-section'>
                    {listingSummary.__sc?.logisticsCosts?.length && (
                        <div className={
                            cx(
                                'grid-item-logistic-cost',
                                {
                                    'grid-item-logistic-cost--only-logistics': onlyLogistics,
                                    'grid-item-logistic-cost--logistics-and-branding': logisticDetailsAndItemBranding,
                                }
                            )
                        }>
                            <ItemLogisticsCosts logisticsCosts={listingSummary.__sc.logisticsCosts} />
                        </div>
                    )}
                    {listingSummary.__sc?.logistics?.logisticsDetails && (
                        <div className="grid-item-logistic-details-lower">
                            <ItemLogisticsDetails logisticsDetails={listingSummary.__sc?.logistics.logisticsDetails} />
                        </div>
                    )}
                    {listingSummary.__sc?.statusMessages?.length && (
                        <div className={`grid-cart-status-msg grid-cart-status-msg--${statusMessagePosition}`}>
                            {listingSummary.__sc?.statusMessages?.map((statusMessage, index) => (
                                <div key={index}>
                                    <TextDisplay data={statusMessage} />
                                </div>
                            ))}
                        </div>
                    )}
                    {listingSummary.__sc?.brandingMessages?.length && (
                        <div className='grid-item-branding'>
                            {listingSummary.__sc?.brandingMessages?.map((brandingMessage, index) => (
                                <div key={index}>
                                    <ItemBranding {...brandingMessage} />
                                </div>
                            ))}
                        </div>
                    )}
                    {listingSummarySpecs.getAdditionalInfo(listingSummary)?.map((additionalInfo, index) => {
                        const iconName = listingSummarySpecs.getIconName(additionalInfo?.icon);
                        return (
                            <div key={index} className="item-additional-info grid-item-additional-info">
                                {
                                    iconName ? <EbayIcon name={iconName} /> : null
                                }
                                <TextDisplay data={additionalInfo.text} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}