import { useEffect, useState, useRef, FC, Ref, RefObject } from 'react';
import { Action } from '@ebay/experience-types-ebay';
import { extractText } from '@ebay/ebayui-app-react/ebay-textual-display';
import { EbayTextboxProps } from '@ebay/ui-core-react/ebay-textbox';
import { EbaySelectProps } from '@ebay/ui-core-react/ebay-select';
import { Textboxes } from '../../../textboxes';
import { Dropdown, ModifiedField } from '../../../dropdown';
import { TextDisplay } from '../../../text-display';
import { useBulkActionsContext } from '../../../bulk-actions/context';
import { shouldNotRenderLineItemActions } from '../../../bulk-actions/utils';
import { TEST_IDS } from '../../../../utils/test-ids';
import { copyObject } from '../../../../../../common/utils/copy';
import { EntryFormType } from '../../../../../../common/utils/hydration';
import { AlternativeItemQuantity } from './alternative-quantity';
import { constructQuantityDataAction } from './utils';
import type { QuantityProps } from './types';
import './styles.less';

export function ItemQuantity({ selectionForm, entryForm, quantity, onAction }: QuantityProps) {
    const [showSelectionForm, setShowSelectionForm] = useState(!!selectionForm);
    const [entryFormState, setEntryFormState] = useState(entryForm);
    const bulkActionContext = useBulkActionsContext();
    const shouldNotRenderLineItemsAction = shouldNotRenderLineItemActions(bulkActionContext);

    const {
        setPricingCostSummary,
        setCostSummary
    } = bulkActionContext;
    // we should show quantity as dropdown if present in page/ajax response
    useEffect(() => {
        setShowSelectionForm(!!selectionForm);
    }, [selectionForm]);

    const quantityElementRefence = useRef<Ref<FC<EbaySelectProps>> | Ref<FC<EbayTextboxProps>> >(null);

    // reset entryForm state to object from response and clear 'originalValues'
    useEffect(() => {
        setEntryFormState(entryForm);
    }, [entryForm]);

    const changeToTextbox = ({ paramKey, paramValue }: ModifiedField) => {
        if (entryForm?.group) {
            setShowSelectionForm(false);
            const updatedEntryForm = entryFormState && copyObject<EntryFormType>(entryFormState);
            updatedEntryForm?.group?.forEach(textbox => {
                if (textbox.paramKey === paramKey) {
                    textbox.originalValue = textbox.paramValue;
                    textbox.paramValue = paramValue;
                }
            });
            setEntryFormState(updatedEntryForm);
            setTimeout(() => {
                (quantityElementRefence as RefObject<HTMLElement>)?.current?.focus?.();
            }, 10);
        }
    };

    const handleAction = (eventData: Action) => {
        const dataAction = constructQuantityDataAction(eventData);
        if (onAction) {
            if (setPricingCostSummary) {
                setPricingCostSummary({});
            }

            if (setCostSummary) {
                setCostSummary({});
            }
            onAction(dataAction?.action).then(() => {
                setTimeout(() => {
                    (quantityElementRefence as RefObject<HTMLElement>)?.current?.focus?.();
                }, 10);
            }).catch(() => {
                // ignore
            });
        }
    };

    const renderedComponent = () => {
        if (selectionForm && showSelectionForm) {
            if (shouldNotRenderLineItemsAction) {
                return (
                    <AlternativeItemQuantity
                        label={selectionForm?.label}
                        text={extractText(selectionForm.selected)}
                    />
                );
            }
            return (
                <Dropdown
                    {...selectionForm}
                    onMaxValue={changeToTextbox}
                    onAction={handleAction}
                    quantityRef={quantityElementRefence as Ref<FC<EbaySelectProps>>}
                    testId={TEST_IDS.quantityDropdown}
                />
            );
        } else if (entryForm && !showSelectionForm) {
            if (shouldNotRenderLineItemsAction) {
                return (
                    <AlternativeItemQuantity
                        label={entryForm.group?.[0]?.label}
                        text={entryForm.group?.[0]?.paramValue}
                    />
                );
            }
            return (
                <Textboxes
                    {...entryFormState}
                    onAction={handleAction}
                    textboxTestId={TEST_IDS.quantityTextbox}
                    quantityRef={quantityElementRefence as Ref<FC<EbayTextboxProps>>}
                    textboxActionTestId={TEST_IDS.quantityTextboxAction}
                />
            );
        }
        return (
            <div className="quantity-display">
                <TextDisplay data={quantity} />
            </div>
        );
    };

    return (
        <div className="quantity">
            { renderedComponent() }
        </div>
    );
}