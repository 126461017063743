import { hydrateRoot } from 'react-dom/client';
import { CartClientState } from './types';
import type { ReactNode } from 'react';

/**
 * Generate initial state script for components re-hydration on browser.
 * This is to be used on the server-side.
 *
 * @param initialState
 */
export function generateInitialStateScript(initialState: any): string {
    return `window.__APP_INITIAL_STATE__ = ${JSON.stringify(initialState).replace(
        /</g,
        '\\u003c'
    )}`;
}

/**
 * Get initial state for rehydration on browser
 */
export function getInitialState(): CartClientState | undefined {
    return window.__APP_INITIAL_STATE__;
}

/**
 * Remove initial state, do this after getInitialState()
 */
export function clearInitialState() {
    delete window.__APP_INITIAL_STATE__;
}

export const ROOT_ID = 'mainContent';

export function initApp(app: ReactNode) {
    const mainContent = document.getElementById(ROOT_ID);

    if (!mainContent) {
        return;
    }

    hydrateRoot(mainContent, app);
}

export function showApp() {
    const mainContent = document.getElementById(ROOT_ID);

    if (!mainContent) {
        return;
    }

    mainContent.style.display = 'block';
}