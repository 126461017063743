import { Icon } from '@ebay/experience-types-ebay';
import { ShoppingCartListingSummary } from '../../../../../../common/utils/hydration';

export function getCondition(listingSummary?: ShoppingCartListingSummary) {
    return listingSummary?.__sc?.condition;
}

export function hasCondition(listingSummary?: ShoppingCartListingSummary) {
    return Boolean(getCondition(listingSummary));
}

export function getTitle(listingSummary?: ShoppingCartListingSummary) {
    return listingSummary?.title;
}

export function hasTitle(listingSummary?: ShoppingCartListingSummary) {
    return Boolean(getTitle(listingSummary));
}

export function getItemVariations(listingSummary?: ShoppingCartListingSummary) {
    return listingSummary?.aspectValuesList;
}

export function hasItemVariations(listingSummary?: ShoppingCartListingSummary) {
    return Boolean(getItemVariations(listingSummary)?.length);
}

export function getEEKRating(listingSummary?: ShoppingCartListingSummary) {
    return listingSummary?.EEKRating;
}

export function hasEEKRating(listingSummary?: ShoppingCartListingSummary) {
    return Boolean(getEEKRating(listingSummary));
}

export function getHotness(listingSummary?: ShoppingCartListingSummary) {
    return listingSummary?.hotness;
}

export function hasHotness(listingSummary?: ShoppingCartListingSummary) {
    return Boolean(getHotness(listingSummary));
}
export function hasHotnessSignal(listingSummary?: ShoppingCartListingSummary) {
    return Boolean(getHotnessSignal(listingSummary));
}

export function getHotnessSignal(listingSummary?: ShoppingCartListingSummary) {
    return listingSummary?.hotnessSignal;
}

export function getAdditionalInfo(listingSummary?: ShoppingCartListingSummary) {
    return listingSummary?.additionalInfo;
}

export function getBadges(listingSummary?: ShoppingCartListingSummary) {
    return listingSummary?.itemBadges;
}

export function getIconName(icon?: Icon) {
    switch (icon?.name) {
        case 'BADGE_RIBBON_WITH_STAR':
            return 'legacyTopRatedSeller48Colored';
        default:
            return '';
    }
}

export function hasAdditionalInfo(listingSummary?: ShoppingCartListingSummary) {
    return Boolean(getAdditionalInfo(listingSummary)?.length);
}

export function getItemSpecificMessages(listingSummary?: ShoppingCartListingSummary) {
    return listingSummary?.itemSpecificMessages;
}

export function hasItemSpecificMessages(listingSummary?: ShoppingCartListingSummary) {
    return Boolean(getItemSpecificMessages(listingSummary)?.length);
}
