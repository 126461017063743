import React, { useRef, useEffect } from 'react';
import { EbayButton } from '@ebay/ui-core-react/ebay-button';
import { EbayLabel } from '@ebay/ui-core-react/ebay-field';
import { EbayCheckbox } from '@ebay/ui-core-react/ebay-checkbox';
import { extractText } from '@ebay/ebayui-app-react/ebay-textual-display';
import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { CallToAction } from '@ebay/experience-types-ebay';
import { getTrackingList } from '../../../utils/experience-service-types/action';
import { buildBulkActionButtonText, buildBulkActionButtonTextBasedOnQuantity } from '../utils';
import { TextSpans } from '../../text-spans';
import { getButtonType } from '../../../utils/button';
import { useBulkActionsContext } from '../context';
import { BulkActionNames, BulkActionsActiveState } from '../types';
import { BulkActionsData } from '../../../../../common/utils/hydration';
import type { BulkActionEditableContentProps } from '../types';
import './bulk-actions-desktop.less';

export function BulkActionEditableContent({
    activeState,
    buttonQuantitySelected,
    bulkActionItemsMap,
    data,
    handleAction,
    selectAllCheckboxChecked,
    moduleName
}: BulkActionEditableContentProps) {
    const selectAllCheckboxRef = useRef<HTMLInputElement>();
    const bulkActionContext = useBulkActionsContext();
    const { cartBulkXoEnabled } = bulkActionContext;

    useEffect(() => {
        if (activeState === BulkActionsActiveState.Active) {
            selectAllCheckboxRef?.current?.focus();
        }
    }, [activeState]);

    if (!data || Object.keys(data).length === 0) {
        return null;
    }

    const {
        bulkModeActions,
        disableBulkMode,
        selectAll
    }: BulkActionsData = data;

    const showBulkActions = bulkModeActions && disableBulkMode && !cartBulkXoEnabled;

    return (
        <>
            { selectAll && (
                <div className="cart-bulk-actions__checkbox-action">
                    <EbayTrackingClick trackingList={getTrackingList(selectAll?.action)}>
                        <EbayCheckbox
                            aria-label={selectAll?.accessibilityText}
                            checked={selectAllCheckboxChecked}
                            data-test-id={BulkActionNames.BulkEditSelectAll}
                            id={selectAll?.paramKey || 'selectAll'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAction(event, selectAll.action)}
                            inputRef={selectAllCheckboxRef as React.LegacyRef<HTMLInputElement>}
                            size='large'
                        >
                            <EbayLabel>
                                {extractText(selectAll.label)}
                            </EbayLabel>
                        </EbayCheckbox>
                    </EbayTrackingClick>
                </div>
            )}
            { showBulkActions && (
                <div className="cart-bulk-actions__actions">
                    {
                        bulkModeActions.map((bulkModeAction: CallToAction, index: number) => (
                            <EbayTrackingClick trackingList={getTrackingList(bulkModeAction?.action)} key={index}>
                                <EbayButton
                                    aria-label={bulkModeAction?.accessibilityText}
                                    data-test-id={BulkActionNames.BulkRemoveSelected}
                                    disabled={!bulkActionItemsMap?.size}

                                    onClick={(event: React.MouseEvent<Element, MouseEvent> ) => handleAction( event, bulkModeAction.action)}
                                    priority={getButtonType(bulkModeAction?.type)}
                                    variant={bulkModeAction?.action?.name === BulkActionNames.BulkRemoveSelected ? 'destructive' : 'standard'}
                                >
                                    {
                                        moduleName === 'CART' ?
                                            buildBulkActionButtonTextBasedOnQuantity(bulkModeAction.text, buttonQuantitySelected) :
                                            buildBulkActionButtonText(bulkModeAction.text, bulkActionItemsMap)
                                    }
                                </EbayButton>
                            </EbayTrackingClick>
                        ))
                    }
                    <EbayTrackingClick trackingList={getTrackingList(disableBulkMode?.action)}>
                        <EbayButton
                            aria-label={disableBulkMode?.accessibilityText}
                            data-test-id={BulkActionNames.DisableBulkMode}
                            priority='secondary'
                            onClick={(event: React.MouseEvent<Element, MouseEvent> ) => handleAction(event, disableBulkMode?.action)}
                        >
                            <TextSpans textSpans={disableBulkMode?.textSpans}/>
                        </EbayButton>
                    </EbayTrackingClick>
                </div>
            )}
        </>
    );
}