import { QuantumMetrics } from '../../../../../common/utils/hydration';

function isInsideSamplingRate(samplingRate: number) {
    return (new Date().getTime() % 100) < samplingRate;
}
export function isQuantumEligible(quantumMetrics?: QuantumMetrics) {
    if (!quantumMetrics) {
        return false;
    }

    const {
        scriptUrl,
        integrity,
        enabled,
        samplingRate
    } = quantumMetrics;
    return enabled && isInsideSamplingRate(samplingRate) && scriptUrl && integrity;
}
