import { EbayButton } from '@ebay/ui-core-react/ebay-button';
import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { useState, useEffect } from 'react';
import { getActionTrackingData } from '../../utils/tracking-utils';
import { useAppMetaContextData } from '../../utils/app-meta-context';
import type { CallToActionProps } from './types';

export function CallToAction({ disabled, text, type, action, className, onAction, testId, isCTAOnBottom }: CallToActionProps) {
    const [isDisabled, setIsDisabled] = useState(disabled);
    const [displayText, setDisplayText] = useState(text);
    const [actionInProgress, setActionInProgress] = useState<boolean>(false);
    const { appMeta, meta } = useAppMetaContextData();
    const isDesktop = !appMeta?.isMobile;

    useEffect(() => {
        setIsDisabled(disabled);
        setDisplayText(text);
        setActionInProgress(false);
    }, [disabled, text, action]);

    const handleClick = () => {
        setIsDisabled(true);
        setDisplayText('');
        setActionInProgress(true);
        if (onAction) {
            onAction(action);
        }
    };
    const clientTrackingData = getActionTrackingData({
        action,
        isGuest: appMeta && appMeta.isGuest,
        shoppingCartId: meta && meta.shoppingCartId,
        refActionName: isCTAOnBottom && action && action.name && !isDesktop ? `${action?.name}_BOTTOM` : undefined
    });

    return (
        <EbayTrackingClick trackingList={clientTrackingData}>
            <EbayButton
                className={className}
                priority={type === 'PRIMARY' ? 'primary' : 'none'}
                bodyState={actionInProgress ? 'loading' : undefined}
                type="button"
                partiallyDisabled={isDisabled}
                size='large'
                onClick={handleClick}
                disabled={isDisabled}
                data-test-id={testId}
                data-track={JSON.stringify(clientTrackingData)}
            >
                {displayText}
            </EbayButton>
        </EbayTrackingClick>
    );
}