import { useEffect, useRef, useState } from 'react';
import { TEST_IDS } from '../../../../common/utils/test-ids';
import { useAppMetaContextData } from '../../../../common/utils/app-meta-context';
import { getActionTrackingData } from '../../../../common/utils/tracking-utils';
import { Adjustment } from './adjustment';
import { AdjustmentsProps } from './types';
import { handleAdjustmentActionClick } from './domain/service';

import './styles.less';

export function Adjustments({ adjustments, sellerId }: AdjustmentsProps) {
    const { appMeta, meta } = useAppMetaContextData();

    const isFirstRender = useRef(true);
    const [isAdjustmentsUpdated, setIsAdjustmentsUpdated] = useState(false);

    useEffect(() => {
        if (!isFirstRender.current) {
            setIsAdjustmentsUpdated(true);
        }

        isFirstRender.current = false;

        return () => {
            setIsAdjustmentsUpdated(false);
        };
    }, [adjustments]);

    if (!adjustments?.length) {
        return null;
    }

    return (
        <div>
            {adjustments.map(((adjustment, index) => {
                if (adjustment.action) {
                    const clientTrackingData = getActionTrackingData({
                        action: adjustment?.action,
                        sellerId,
                        isGuest: appMeta?.isGuest,
                        shoppingCartId: meta?.shoppingCartId,
                    });
                    return (
                        <a
                            key={index}
                            className="lineitem-adjustments adjustments-link"
                            href="#"
                            onClick={event => handleAdjustmentActionClick(event, adjustment)}
                            data-test-id={TEST_IDS.moreDeals}
                            data-click={clientTrackingData && JSON.stringify(clientTrackingData[0])}
                        >
                            <Adjustment adjustment={adjustment} />
                        </a>
                    );
                }

                const accessibilityProps = isAdjustmentsUpdated ? {
                    'aria-live': 'polite' as const,
                    'role': 'status'
                } : {};

                return (
                    <div key={index} className="lineitem-adjustments" {...accessibilityProps}>
                        <Adjustment adjustment={adjustment} />
                    </div>
                );
            }))}
        </div>
    );
}
