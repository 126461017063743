import { Action } from '@ebay/experience-types-ebay';
import { CartClientState } from '../../../../../../common/utils/hydration';
import { AjaxCallMaker } from '../../../../../common/utils/ajax';
import { PayOnlyThisSellerRequest } from '../types';

export async function payOnlyThisSeller(makeAjaxCall: AjaxCallMaker<PayOnlyThisSellerRequest>, action?: Action): Promise<CartClientState> {
    const data = action?.params as PayOnlyThisSellerRequest;
    if (!data?.sellerName) {
        throw new Error('[payOnlyThisSeller]: Seller name missing!');
    }

    return makeAjaxCall('/api/pots', data);
}
