import { ComponentType, ReactNode } from 'react';
import { CartClientState } from '../../../../common/utils/hydration';

export type HeroData = object;

export interface QuickViewPopupProps {
    isOpen: boolean;
    listingId?: string;
    onClose: () => void;
    onRender: () => void;
    variationId?: string;
}

export interface QuickViewActionProps {
    listingId?: string;
    variationId?: string;
    children: ReactNode;
}

export interface QuickViewContextProviderProps {
    children?: ReactNode;
    cartState?: CartClientState;
}

export interface QuickViewHookData {
    getCurrentQuickViewData: () => CurrentQuickViewData;
    closeQuickView: () => void;
    openQuickViewByIds: (listingId?: string, variationId?: string) => void;
    setShouldShowQuickViewAction: () => void;
    shouldShowQuickViewAction: () => boolean;
    shouldShowQuickViewPopup: () => boolean;
}

export interface QuickViewPopupModule {
    QuickViewPopup: ComponentType<QuickViewPopupProps>;
}

export interface ListingHeroDataRequest {
    listingIds: string[];
}

export enum QuickViewLoadingState {
    NotStarted = 'NOT_STARTED',
    PageLoaded = 'PAGE_LOADED',
    Done = 'DONE'
}

// See x-vi-quick-view-react for more information on the parameters
// https://github.corp.ebay.com/ux-components/ux-app/tree/master/components/x-vi-quick-view
export interface MarkoItemQuickViewParams {
    // Parameters relevant to cart
    var?: string; // variation id
    module_groups?: string; // currently CART_HERO_MODULE, might be deprecated soon, will move to 'modules'.

    // Maybe be used by cart in the future
    quantity?: string; // This might be a number! Preselect quantity on dialog open (Multi quantity items only)
    modules?: string; // Request additional modules (ex: 'COUPON,LOYALTY,QUANTITY').

    // Other parameters, unlikely to be used by cart/
    itmmeta?: string; // tracking bypass for ad-blockers
    fits?: string; // pass on fits tokens for continuity & better UX
    chn?: string; // paid marketing channels
    mkrid?: string; // paid marketing rotation - identifies campaign / promotion
    mkcid?: string; // paid marketing channel - if used outside of ebay, identifies external source
}

export type CurrentQuickViewData = {
    listingId?: string;
    variationId?: string;
} | null;
