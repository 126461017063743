import { manualTagDropper } from '../../../../common/utils/experimentation/manual-tag-dropper';
import { AppContext, FeatureFlagsData } from '../../../../common/utils/hydration';
import { getCartPageId } from '../tracking-utils';
import { authModal } from './otp-auth-modal';

export function triggerSignInOTPAuthFlow(isFromBanner?: boolean, appMeta?: AppContext, redirectUrl?: string, featureFlagsData?: FeatureFlagsData) {
    dropXTTagsForGuestOTPAuthFlow(featureFlagsData);
    const enableGuestOTPAuthFlow = isGuestOTPAuthFlowEnabled(featureFlagsData);
    if (enableGuestOTPAuthFlow) {
        try {
            authModal.open({
                url: redirectUrl || '',
                action: isFromBanner ? 'CART_BANNER' : 'CART_SIGNIN_CTA',
                isGuestCheckoutEligible: false,
                localeString: appMeta?.locale || 'en-US',
                isMweb: appMeta?.isMobile
            });
            return;
        } catch (e) {
        // If there is an error, we will fallback to the default behavior
        }
    }
    if (redirectUrl) {
        window.location.href = redirectUrl;
    }
}

export function triggerCheckoutOTPAuthFlow(redirectUrl: string, appMeta?: AppContext, memberCheckoutUrl?: string, guestCheckoutUrl?: string) {
    try {
        authModal.open({
            url: redirectUrl,
            ru: memberCheckoutUrl,
            action: 'CART_GOTO_CHECKOUT',
            memberCheckoutUrl,
            guestCheckoutUrl,
            isGuestCheckoutEligible: !!guestCheckoutUrl,
            localeString: appMeta?.locale,
            isMweb: appMeta?.isMobile
        });
    } catch (e) {
        // If there is an error, we will fallback to the default behavior.
    }
    return;
}

export function dropXTTagsForGuestOTPAuthFlow(featureFlagsData?: FeatureFlagsData) {
    const enableGuestOTPAuthFlow = featureFlagsData?.enableGuestOTPAuthFlow;
    if (enableGuestOTPAuthFlow) {
        const { value, treatmentId } = enableGuestOTPAuthFlow;
        if (typeof value === 'object' && treatmentId) {
            manualTagDropper(treatmentId, featureFlagsData?.qtags, String(getCartPageId(true)));
        }
    }
}

export function isGuestOTPAuthFlowEnabled(featureFlagsData?: FeatureFlagsData) {
    if (featureFlagsData?.enableGuestOTPAuthFlow) {
        const { value } = featureFlagsData.enableGuestOTPAuthFlow;
        if (typeof value === 'boolean') {
            return value;
        } else if (typeof value === 'object') {
            const { enableOTPAuthFlowForCart } = value;
            // To Handle Variant value is configured as string in the feature flag
            if (enableOTPAuthFlowForCart && typeof enableOTPAuthFlowForCart === 'string') {
                return enableOTPAuthFlowForCart === 'true' || false;
            }
            return enableOTPAuthFlowForCart as boolean || false;
        }
    }
    return false;
}