import React, { useContext, } from 'react';
import { useLineItem } from '../hooks';
import { LineItemContextProviderProps, LineItemContextHookData } from '../types';

export const LineItemContext = React.createContext<LineItemContextHookData>({});

export function LineItemContextProvider({ cartState, deviceMode, children }: LineItemContextProviderProps) {
    const lineItemsContextState = useLineItem({ cartState, deviceMode });
    return (
        <LineItemContext.Provider value={lineItemsContextState}>
            {children}
        </LineItemContext.Provider>
    );
}

export const useLineItemContext = (): LineItemContextHookData => {
    const lineItemsContextState: LineItemContextHookData = useContext(LineItemContext);
    return lineItemsContextState;
};