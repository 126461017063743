import { MessageType, Notification } from '../../../../../common/utils/hydration';
import { AlertStatus } from '../types';

export function getAlertStatus(notification: Notification): AlertStatus {
    switch (notification?.messageType) {
        case MessageType.Error:
            return AlertStatus.Attention;

        case MessageType.Success:
            return AlertStatus.Confirmation;

        default:
            return AlertStatus.Information;
    }
}
