import { EbayIcon } from '@ebay/ui-core-react/ebay-icon';
import { TEST_IDS } from '../../../../common/utils/test-ids';
import { useFeatureFlagsData } from '../../../../common/utils/experiments';
import { getFOPIconName } from './utils/fop-icon-mapper';
import { FormOfPaymentsProps } from './types';

import './styles.less';

export function FormOfPayments({ icons }: FormOfPaymentsProps) {
    const { displaySummaryPaymentIcons = false } = useFeatureFlagsData();
    if (!icons || !icons?.length || !displaySummaryPaymentIcons) {
        return null;
    }
    return (
        <div className="cart-summary-form-of-payments" data-test-id={TEST_IDS.cartSummaryFormOfPayments}>
            {
                icons.map((icon, index) => (
                    <EbayIcon key={index} a11yText={icon.accessibilityText} name={getFOPIconName(icon.name)}/>
                ))
            }
        </div>
    );
}
