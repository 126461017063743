import React from 'react';
import { EbayPageNotice, EbayPageNoticeTitle, EbayNoticeContent } from '@ebay/ui-core-react/ebay-page-notice';
import { ErrorBoundaryProps, ErrorBoundaryState } from './types';

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);

        this.state = {
            error: false
        };
    }

    componentDidCatch(error: Error) {
        this.setState({
            error: true
        });

        this.props.clientLogger.error('RENDERING_ERROR', {
            name: error?.name,
            message: error?.message,
            stack: error?.stack || ''
        });
    }

    render() {
        const { genericErrorMessage } = this.props;

        if (this.state.error) {
            return (
                <EbayPageNotice status="attention">
                    <EbayNoticeContent aria-live="polite">
                        <EbayPageNoticeTitle>
                            {genericErrorMessage}
                        </EbayPageNoticeTitle>
                    </EbayNoticeContent>
                </EbayPageNotice>
            );
        }

        return this.props.children;
    }
}
