import { CartClientState, Modules } from '../../../../../common/utils/hydration';

export const getModules = (state: CartClientState | undefined): Modules => state?.modules || {};

export const getTopModulesWithPrefix = (state: CartClientState | undefined, prefix: string) => {
    const modules = getModules(state) as Record<string, unknown>;
    const filteredModules: Record<string, unknown> = {};
    for (const moduleKey in modules) {
        if (Object.hasOwnProperty.call(modules, moduleKey)) {
            if (moduleKey.startsWith(prefix)) {
                filteredModules[moduleKey as keyof Modules] = modules[moduleKey];
            }
        }
    }
    return filteredModules;
};