import { Priority } from '@ebay/ui-core-react/ebay-button';

export const getButtonType = (buttonType = 'none' ): Priority => {
    switch (buttonType) {
        case 'PRIMARY':
            return 'primary';
        case 'SECONDARY':
            return 'secondary';
        case 'TERTIARY':
            return 'tertiary';
        default:
            return 'none';
    }
};