import { MouseEvent as ReactMouseEvent } from 'react';
import { Action } from '@ebay/experience-types-ebay';
import { dropXTTagsForGuestOTPAuthFlow } from '../../../../common/utils/auth-modal/util';
import { useSpinner } from '../../../../common/components/spinner';
import { useAjax } from '../../../../common/utils/ajax';
import { useFeatureFlagsData } from '../../../../common/utils/experiments';
import { manualTagDropper } from '../../../../../common/utils/experimentation/manual-tag-dropper';
import { navigateToUrl } from '../../../cart-summary/components/cart-summary-call-to-action/domain/services';
import { useAppMetaContextData } from '../../../../common/utils/app-meta-context';
import { CartStateCallback } from '../../../../common/types/cart-state-handler';
import { payOnlyThisSeller } from './domain/services';
import { isPayOnlyThisSeller, isRedirectToCheckout } from './domain/specifications';

export const useHandleAction = ({ onPayOnlyThisSeller }: { onPayOnlyThisSeller?: CartStateCallback }) => {
    const { appMeta } = useAppMetaContextData();
    const spinner = useSpinner();
    const makeAjaxCall = useAjax();
    const featureFlagsData = useFeatureFlagsData();

    return (event: MouseEvent | ReactMouseEvent, action?: Action) => {
        if (!action) {
            return;
        }

        if (isPayOnlyThisSeller(action)) {
            spinner.show();
            if (appMeta?.isGuest && featureFlagsData?.enableGuestOTPAuthFlow) {
                dropXTTagsForGuestOTPAuthFlow(featureFlagsData);
            }
            void payOnlyThisSeller(makeAjaxCall, action)
                .then(onPayOnlyThisSeller)
                .catch(error => {
                    console.error('[payOnlyThisSeller] Error in the request:', error);
                })
                .then(() => {
                    spinner.hide();
                });
        } else if (isRedirectToCheckout(action)) {
            if (featureFlagsData?.preRenderCheckoutFromEdge) {
                manualTagDropper(featureFlagsData.preRenderCheckoutFromEdge.treatmentId, featureFlagsData.qtags, appMeta?.pageId);

                if (featureFlagsData?.preRenderCheckoutFromEdge?.value) {
                    event.preventDefault();
                    action.URL = `${action.URL || ''}&edge=1`;
                    navigateToUrl(action.URL);
                }
            }
        }
    };
};
