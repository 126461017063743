import classNames from 'classnames';
import { TextDisplay } from '../../../text-display';
import type { PriceDetailsProps } from './types';

import './styles.less';

export function ItemPriceDetails({
    additionalPrices,
    discountMessage,
    originalPrice,
    price,
    priceChangePercentage
}: PriceDetailsProps) {
    if (!price && !originalPrice) {
        return null;
    }

    return (
        <div className="price-details">
            <div className={classNames({
                'price-details-with-discounts': priceChangePercentage
            })}
            >
                {price ? (
                    <div className="item-price font-title-3">
                        <TextDisplay data={price} />
                    </div>
                ) : null}

                {(additionalPrices || priceChangePercentage) ? (
                    <div
                        className={classNames('additional-prices', {
                            'additional-price-and-discounts': priceChangePercentage
                        })}
                    >
                        {additionalPrices?.map((additionalPrice, index) => (
                            <div key={index}>
                                <TextDisplay data={additionalPrice} />
                            </div>
                        ))}

                        {priceChangePercentage ? (
                            <div className="discount-prices">
                                {<TextDisplay data={priceChangePercentage} />}
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>

            {discountMessage ? (
                <div className="discount-message">
                    {<TextDisplay data={discountMessage} />}
                </div>
            ) : null}

            {originalPrice ? (
                <div className="original-price">
                    <TextDisplay data={originalPrice} />
                </div>
            ) : null}
        </div>
    );
}
