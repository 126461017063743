import { TextDisplay } from '../../common/components/text-display';
import { useI18n } from '../../common/utils/i18n/hooks';
import { buildEmptyCartModule } from '../../common/components/grid-line-item/utils/utils';
import { EmptyCartProps } from './types';
import { EmptyCartActions } from './components/empty-cart-actions';

import './styles.less';

export function EmptyCart({ emptyCart, buildModule = false }: EmptyCartProps) {
    const i18n = useI18n();

    if (!emptyCart && !buildModule) {
        return null;
    } else if (!emptyCart && buildModule) {
        emptyCart = buildEmptyCartModule(i18n);
    }

    return (
        <div className="empty-cart">
            {emptyCart?.emptyCartMessage && (
                <div className="font-title-3" aria-live="polite">
                    <TextDisplay data={emptyCart.emptyCartMessage} />
                </div>
            )}

            {emptyCart?.subMessages?.length && (
                <div className="sub-messages">
                    {emptyCart.subMessages.map((subMessage, index) => (
                        <TextDisplay key={index} data={subMessage} />
                    ))}
                </div>
            )}

            <EmptyCartActions actions={emptyCart?.actionMap} />
        </div>
    );
}
