import { useEffect, useState } from 'react';
import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { EbayButton } from '@ebay/ui-core-react/ebay-button';
import { EbayDialogHeader } from '@ebay/ui-core-react/ebay-dialog-base';
import { EbayLightboxDialog } from '@ebay/ui-core-react/ebay-lightbox-dialog';
import { getActionTrackingData } from '../../common/utils/tracking-utils';
import { useAppMetaContextData } from '../../common/utils/app-meta-context';
import { triggerCheckoutOTPAuthFlow } from '../../common/utils/auth-modal';
import { useI18n } from '../../common/utils/i18n';
import { usePageMask } from '../../common/components/page-mask';
import { getSigninLink } from './domain/specifications';
import * as tracking from './tracking';
import type { SignInChooserProps } from './types';

import './styles.less';

export function SignInChooser({ open, pageId, data }: SignInChooserProps) {
    const enableGuestOTPAuthFlow = data?.enableGuestOTPAuthFlow;
    const { appMeta, meta } = useAppMetaContextData();
    const isMobile = data?.isMobile;
    const locale = data?.locale;
    const i18n = useI18n();
    const pageMask = usePageMask();
    const [isOpen, setIsOpen] = useState(open);


    const continueToSigninTrackingData = getActionTrackingData({
        isGuest: appMeta?.isGuest,
        shoppingCartId: meta?.shoppingCartId,
        refActionName: 'CONTINUE_TO_SIGN_IN'
    });

    const continueAsGuestTrackingData = getActionTrackingData({
        isGuest: appMeta?.isGuest,
        shoppingCartId: meta?.shoppingCartId,
        refActionName: 'CONTINUE_AS_GUEST'
    });

    useEffect(() => {
        setIsOpen(open);
        pageMask.hide();
    }, [open, pageMask, data]);

    useEffect(() => {
        if (isOpen) {
            tracking.trackOpen(pageId);
        }
    }, [isOpen, pageId]);

    const close = () => {
        setIsOpen(false);
        tracking.trackClose(pageId);
    };

    const { baseUrl, returnUrl, gxoUrl, signInUrlBase } = data || {};
    if (!baseUrl || !returnUrl || !gxoUrl) {
        return null;
    }

    const signInLink = getSigninLink(baseUrl, returnUrl, gxoUrl);
    const handleSignInButtonClick = () => {
        if (signInLink) {
            tracking.trackSignInButtonClick(pageId);
            window.location.href = signInLink;
        }
    };

    const handleGuestButtonClick = () => {
        if (gxoUrl) {
            tracking.trackGuestCheckoutButtonClick(pageId);
            window.location.href = gxoUrl;
        }
    };

    if (enableGuestOTPAuthFlow) {
        triggerCheckoutOTPAuthFlow(signInUrlBase || signInLink, { isMobile, locale }, returnUrl, gxoUrl);
        return null;
    } else {
        return (
            <EbayLightboxDialog id="signin-chooser" animated={false} open={isOpen} onClose={close} a11yCloseText={i18n.getSignInChooserCloseDialog()} aria-label={i18n.getSignInChooserDialogAriaLabel()}>
                <EbayDialogHeader />
                <EbayTrackingClick trackingList={continueToSigninTrackingData} >
                    <EbayButton id="signin-btn" priority="primary" size="large" fluid onClick={handleSignInButtonClick}>
                        {i18n.getSignInChooserContinueToSignIn()}
                    </EbayButton>
                </EbayTrackingClick>

                <EbayTrackingClick trackingList={continueAsGuestTrackingData} >
                    <EbayButton id="gxo-btn" priority="secondary" size="large" fluid onClick={handleGuestButtonClick}>
                        {i18n.getSignInChooserContinueAsGuest()}
                    </EbayButton>
                </EbayTrackingClick>
            </EbayLightboxDialog>
        );
    }
}
