import { useEffect, useState } from 'react';
import {
    MappedSignals,
    transformGraphQlResponse
} from '@ebay/signals-components-provider/utils';
import { SignalsHookData, SignalsLoadingState, SignalsContextProviderProps } from '../types';
import {
    buildMappedSignalsBasedOnTreatment,
    dropXTTagsForFGQLSignals,
    getIsInFGQLSignalsControl,
    getIsInFGQLSignalsEP,
    getIsInFGQLSignalsTreatment,
    hasValidTagDroppingConditions
} from '../utils';
import { addPageLoadEventListener } from '../../../utils/dom';
import { useAjax } from '../../../utils/ajax';
import { getSignalsFromFGQL } from '../utils';
import { buildItemIdToSellerIdMap, isRawSignalsDataEmpty } from '../utils';
import { Signals } from '../../../../../common/utils/hydration';
import { useFeatureFlagsData } from '../../../utils/experiments';

const useCachedSignalsFunctions = () => {
    const makeAjaxCall = useAjax();
    const [oldListingIdsString, setOldListingIdsString] = useState<string>('');
    const [oldTransformedFgqlSignals, setOldTransformedFgqlSignals] = useState<Signals | undefined>();

    const getSignalsFromFGQLMemoized = async(listingIds: string[], itemIdToSellerId: Record<string, string>): Promise<Signals | undefined> => {
        const newListingIdsString = listingIds.join();
        if (oldListingIdsString === newListingIdsString) {
            return oldTransformedFgqlSignals;
        }

        const rawFGQLSignalsData = await getSignalsFromFGQL(makeAjaxCall, listingIds);
        const transformed = isRawSignalsDataEmpty(rawFGQLSignalsData) ? undefined
            : transformGraphQlResponse(rawFGQLSignalsData, itemIdToSellerId);
        setOldListingIdsString(newListingIdsString);
        setOldTransformedFgqlSignals(transformed);
        return transformed;
    };

    return { getSignalsFromFGQLMemoized };
};

export const useSignals = ({ cartState }: SignalsContextProviderProps): SignalsHookData => {
    const { getSignalsFromFGQLMemoized } = useCachedSignalsFunctions();

    const [isFirstFGQLRequest, setIsFirstFGQLRequest] = useState(true);
    const [loadingState, setLoadingState] = useState(SignalsLoadingState.Loading);
    const [mappedSignals, setMappedSignals] = useState<MappedSignals | undefined>();

    const featureFlagsData = useFeatureFlagsData();
    const vasSignals = cartState?.modules?.signals;
    const itemIdToSellerId = buildItemIdToSellerIdMap(cartState?.modules?.cartDetails);
    const listingIds = Object.keys(itemIdToSellerId);
    const listingIdsString = listingIds.join(',');

    const isFGQLCallLoading = loadingState === SignalsLoadingState.Loading;
    const isInFGQLSignalsEP = getIsInFGQLSignalsEP(featureFlagsData);
    const isInFGQLSignalsControl = getIsInFGQLSignalsControl(featureFlagsData);
    const isInFGQLSignalsTreatment = getIsInFGQLSignalsTreatment(featureFlagsData);

    const dropXTTagsOnlyOnce = () => {
        if (isFirstFGQLRequest) {
            setIsFirstFGQLRequest(false);
            dropXTTagsForFGQLSignals(featureFlagsData);
        }
    };

    useEffect(() => {
        let ignore = false;
        const getFGQLSignalsData = async(ignore: boolean) => {
            if (!ignore) {
                const fgqlSignals = await getSignalsFromFGQLMemoized(listingIds, itemIdToSellerId);
                if (!fgqlSignals || !hasValidTagDroppingConditions(fgqlSignals, vasSignals)) {
                    setLoadingState(SignalsLoadingState.DoneAndDataMissing);
                } else if (isInFGQLSignalsControl) {
                    dropXTTagsOnlyOnce();
                    setLoadingState(SignalsLoadingState.DoneAndDataMissing);
                } else if (isInFGQLSignalsTreatment) {
                    dropXTTagsOnlyOnce();
                    setLoadingState(SignalsLoadingState.DoneAndDataExists);
                    const mergedMappedSignals = buildMappedSignalsBasedOnTreatment(featureFlagsData, fgqlSignals, vasSignals);
                    setMappedSignals(mergedMappedSignals);
                } else {
                    setLoadingState(SignalsLoadingState.DoneAndDataMissing);
                }
            }
        };

        // If the user is in the EP (regardless of control or treatment) then we call FGQL.
        if (isInFGQLSignalsEP && listingIds.length) {
            addPageLoadEventListener(() => {
                void getFGQLSignalsData(ignore);
            });
        }
        return () => { ignore = true; };
    }, [vasSignals, listingIdsString]);

    return {
        isInFGQLSignalsEP,
        isInFGQLSignalsControl,
        isInFGQLSignalsTreatment,
        mappedSignals,
        isFGQLCallLoading
    };
};
