import { addWindowLoadEvent } from '../dom/dom';
import { TrackingData, RoverTrackingData, EventActionName } from './types';

export const flushTrackingEvents = () => {
    if (typeof window.postPlsUBTCALL === 'function') {
        window.postPlsUBTCALL(false); // flush pulsar events
    }
};

export function trackPulsar(trackingData: TrackingData) {
    if (!trackingData) {
        return;
    }
    if (typeof window.triggerTracking === 'function') {
        window.triggerTracking('pulsar', trackingData);
    } else {
        // Retry on page load in case triggerTracking doesn't exist yet
        addWindowLoadEvent(() => {
            if (typeof window.triggerTracking === 'function') {
                trackPulsar(trackingData);
            }
        });
    }
}

function trackImpression(trackingListItem: TrackingData) {
    trackPulsar(trackingListItem);
}

export function trackPageImpressions(trackingData: TrackingData[] | TrackingData) {
    trackImpressions(trackingData, 'IMPRESSION_TRACKING');
}

export function trackImpressions(trackingList: TrackingData[] | TrackingData, actionName?: EventActionName) {
    if (Array.isArray(trackingList)) {
        for (const trackingListItem of trackingList) {
            trackImpression(trackingListItem);
        }
    } else {
        trackImpression(trackingList);
    }
}

export function mergeTrackingData(source: TrackingData = {}, target: TrackingData = {}): TrackingData {
    const mergedEventProperty = { ...source.eventProperty, ...target.eventProperty };
    const mergedTrackingData = { ...source, ...target };
    mergedTrackingData.eventProperty = mergedEventProperty;
    return mergedTrackingData;
}

export function trackRover(roverData: RoverTrackingData) {
    if (typeof window.triggerTracking !== 'function' || !roverData) {
        return;
    }

    window.triggerTracking('rover', roverData);
}
