import { GetDynamicBannerParameters, Commitments, Listings, TotalAmount } from '@ebay/payments-graph-banner/server';
import { CartClientState, ShoppingCartListingSummary } from '../../../../../common/utils/hydration';

const getQuantity = (listingSummary: ShoppingCartListingSummary): number | undefined => {
    if (typeof listingSummary?.quantity?.value === 'number') {
        return listingSummary?.quantity?.value;
    }

    const quantity = listingSummary?.__sc?.quantityForm?.entryForm?.group?.[0]?.paramValue;
    if (quantity) {
        return typeof quantity === 'number' ? quantity : parseInt(quantity);
    }
    return undefined;
};

// These are for testing, they can be removed when we go to prod.
// const ALI_PAY_TESTING_DATA = { listingId: '140050472534', quantity: 10 };
// const EBAY_BALANCE_TESTING_DATA = { listingId: '140050472533', quantity: 10 };

const buildListingsAndCommitments = (cartClientState?: CartClientState): { commitments: Commitments[], listings: Listings[] } => {
    const commitments: Commitments[] = [];
    const listings: Listings[] = [];
    for (const sellerBucket of cartClientState?.modules?.cartDetails?.sellerBuckets || []) {
        for (const lineItem of sellerBucket?.lineItems || []) {
            for (const listingSummary of lineItem?.listingSummaries || []) {
                const listingId = listingSummary?.listingId;
                const transactionId = listingSummary?.__sc?.transactionId;
                const quantity = getQuantity(listingSummary);
                if (listingId) {
                    if (transactionId) {
                        commitments.push({
                            listingId,
                            listingTransactionId: transactionId
                        });
                    } else if (quantity) {
                        listings.push({
                            listingId,
                            listingVariationId: listingSummary?.variationId,
                            quantity
                        });
                    }
                }
            }
        }
    }
    return { listings, commitments };
};

const buildTotalAmount = (cartClientState?: CartClientState): TotalAmount | undefined => {
    const orderTotalAmount = cartClientState?.modules?.cartSummary?.total?.value?.value;
    if (typeof orderTotalAmount?.value === 'number' && typeof orderTotalAmount?.currency === 'string') {
        return {
            amount: orderTotalAmount.value,
            currency: orderTotalAmount.currency
        };
    }
    return undefined;
};

// Builds a DynamicBannerRequest object from the cartClientState or returns null if missing data.
export const buildDynamicBannerRequest = (cartClientState?: CartClientState): GetDynamicBannerParameters | null => {
    const { listings, commitments } = buildListingsAndCommitments(cartClientState);
    const totalAmount = buildTotalAmount(cartClientState);
    if (!totalAmount || (listings.length === 0 && commitments.length === 0)) {
        return null;
    }

    return {
        guest: cartClientState?.appMeta?.isGuest ?? false,
        totalAmount,
        listings,
        commitments
    };
};
