/* eslint sort-keys-fix/sort-keys-fix: "error"*/
export const TEST_IDS: { [key: string]: string } = {
    appCart: 'app-cart',
    bannerCard: 'banner-card',
    buyItNow: 'buy-it-now',
    callToActionBottom: 'cta-bottom',
    callToActionTop: 'cta-top',
    cartBucket: 'cart-bucket',
    cartItemLink: 'cart-item-link',
    cartRemoveItem: 'cart-remove-item',
    cartSummary: 'cart-summary',
    cartSummaryFormOfPayments: 'cart-summary-form-of-payments',
    cartSummaryTop: 'cart-summary-top',
    cartToSavedForLater: 'cart-to-sfl',
    continueShopping: 'continue-shopping',
    dialog: 'dialog',
    dialogMessage: 'dialog-message',
    dialogTitle: 'dialog-title',
    flyout: 'flyout',
    inlineAlerts: 'inline-alerts',
    listSummary: 'list-summary',
    mainTitle: 'main-title',
    menuOverlayButton: 'menu-oly-btn',
    moreDeals: 'more-deals',
    pageAlerts: 'page-alerts',
    pageMask: 'page-mask',
    quantityDropdown: 'qty-dropdown',
    quantityTextbox: 'qty-textbox',
    quantityTextboxAction: 'qty-textbox-action',
    requestTotalInfo: 'REQUEST_TOTAL_INFO',
    rewards: 'rewards',
    savedForLaterFindSimilarItems: 'sfl-find-similar-items',
    savedForLaterItemLink: 'sfl-item-link',
    savedForLaterLineItem: 'sfl-lineitem',
    savedForLaterRemoveItem: 'sfl-remove-item',
    savedForLaterToCart: 'sfl-to-cart',
    signIn: 'SIGN_IN',
    spinner: 'spinner',
    startShopping: 'start-shopping',
    surveyLink: 'SURVEY_LINK'
};
