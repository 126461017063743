import { EbayProgressSpinner } from '@ebay/ui-core-react/ebay-progress-spinner';
import { useEffect, useRef } from 'react';
import { TEST_IDS } from '../../utils/test-ids';
import { useI18n } from '../../utils/i18n';

import './styles.less';

export function Spinner() {
    const isSpinnerMounted = useRef<boolean>(false);
    const i18n = useI18n();
    const previousElement = useRef<HTMLElement | Element | null>(null);
    const spinnerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if ((spinnerRef?.current as HTMLDivElement)?.focus) {
            isSpinnerMounted.current = true;
            previousElement.current = document.activeElement;
            (spinnerRef?.current as HTMLDivElement)?.focus?.();
        }

        return () => {
            if (isSpinnerMounted?.current) {
                isSpinnerMounted.current = false;
                if ((previousElement?.current as HTMLElement)?.focus) {
                    (previousElement?.current as HTMLElement)?.focus?.();
                    previousElement.current = null;
                }
            }
        };
    }, [spinnerRef]);

    return (
        <div className="spinner-mask" tabIndex={0} ref={spinnerRef}>
            <EbayProgressSpinner size="large" aria-label={i18n.getSpinnerAriaLabel()} data-test-id={TEST_IDS.spinner} />
        </div>
    );
}
