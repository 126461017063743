import { TextualDisplay, Action } from '@ebay/experience-types-ebay';
import { EbayTextboxProps } from '@ebay/ui-core-react/ebay-textbox';
import { FC, Ref } from 'react';
import { ModifiedTextualEntry, ParamValueTypeForTextbox } from '../../../../common/utils/hydration';

export interface TextboxesProps {
    group?: ModifiedTextualEntry[];
    heading?: TextualDisplay;
    quantityRef?: Ref<FC<EbayTextboxProps>>;
    actions?: { [key: string]: TextualDisplay };
    textboxTestId?: string;
    textboxActionTestId?: string;
    onAction?: (data: Action) => void;
    onMinValue?: ({ paramKey, paramValue }: { paramKey: string, paramValue: ParamValueTypeForTextbox }) => void;
}

export type TextboxesState = ModifiedTextualEntry[];

export enum InputType {
    Number = 'number',
    Text = 'text'
}

export enum ParamValueType {
    NumberUnsigned = 'NUMBER_UNSIGNED',
    NumberSigned = 'NUMBER_SIGNED',
    DecimalUnsigned = 'DECIMAL_UNSIGNED',
    DecimalSigned = 'DECIMAL_SIGNED'
}

