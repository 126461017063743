import { GetDynamicBannerParameters } from '@ebay/payments-graph-banner/server';
import { DynamicBannerData, FGQLActions } from '../../../../../common/utils/hydration';
import { AjaxCallMaker } from '../../../utils/ajax';
import { GetDynamicBannerDataRequest } from '../types';

export async function getDynamicBannerFromFGQL(makeAjaxCall: AjaxCallMaker<GetDynamicBannerDataRequest>, dynamicBannerParams: GetDynamicBannerParameters): Promise<DynamicBannerData | undefined> {
    try {
        const body: GetDynamicBannerDataRequest = { action: FGQLActions.GetDynamicBanner, params: dynamicBannerParams };
        const data = await makeAjaxCall('/api/fgql', body, undefined, false);
        return data?.fgql?.dynamicBanner;
    } catch (e) {
        // We are doing nothing here as errors here should be logged on backend, so duplicate logging
        // isn't required.
        return undefined;
    }
}
