import { FieldAction } from '@ebay/experience-types-ebay';
import { ModifiedTextualSelection } from '../../../../../common/utils/hydration';

const SELECT = 'SELECT';
const SHOW = 'SHOW';

export function findSelectedOption(group?: ModifiedTextualSelection[], selectedValue?: string) {
    return group && group.find(option => option.paramValue === selectedValue);
}

export function shouldShowTextbox(fieldActions?: FieldAction []) {
    return fieldActions &&
        fieldActions.some(action => action.whenAct === SELECT && action.doReact === SHOW);
}