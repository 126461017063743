import { AjaxCallMaker } from '../ajax';
import { ClientLog, ClientLogData, ClientLogType, Logger } from './types';

export class ClientLogger implements Logger {
    constructor(private readonly makeAjaxCall: AjaxCallMaker<ClientLog>) {
        if (!makeAjaxCall) {
            throw new Error('[ClientLogger]: No "makeAjaxCall()" function passed');
        }
    }

    private log(data: ClientLog): void {
        void this.makeAjaxCall('/api/client-logging', {
            ...data,
            logName: `REACT_${data.logName}`
        }, /* logging */ true)
            .catch(() => {
                // Ignore logging error
            });
    }

    error(name: string, data?: ClientLogData) {
        this.log({
            ...data,
            logType: ClientLogType.Error,
            logName: name
        });
    }

    exception(name: string, data?: ClientLogData) {
        this.log({
            ...data,
            logType: ClientLogType.Exception,
            logName: name
        });
    }

    info(name: string, data?: ClientLogData) {
        this.log({
            ...data,
            logType: ClientLogType.Info,
            logName: name
        });
    }

    warning(name: string, data?: ClientLogData) {
        this.log({
            ...data,
            logType: ClientLogType.Warning,
            logName: name
        });
    }
}
