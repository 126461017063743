import { CartClientState } from '../../../../../../common/utils/hydration';
import { AjaxCallMaker } from '../../../../utils/ajax';
import { BulkAction, BulkActionType, CheckoutBulkItemsAction } from '../types';

export function containsLineItemIds(data?: BulkAction) {
    const lineItemIds = data?.params?.lineItemIds;
    if (Array.isArray(lineItemIds)) {
        return !!lineItemIds?.length;
    }
    return false;
}

export function containsBulkXoItems(data?: CheckoutBulkItemsAction) {
    const items = data?.params?.items;
    if (Array.isArray(items)) {
        return !!items?.length;
    }
    return false;
}

export function makeBulkAction(makeAjaxCall: AjaxCallMaker<BulkAction>, action?: BulkAction): Promise<CartClientState> {
    const data = action as BulkAction;
    // NOTE once more bulk action types are added we can add the loggers and handlers here
    switch (data?.actionType) {
        case BulkActionType.Remove:
            if (!containsLineItemIds(data)) {
                throw new Error('[REMOVE Bulk Action]: LineItemIds missing!');
            }
            break;
        default:
            break;
    }
    return makeAjaxCall('/api/bulk', data);
}

export function makeBulkCheckoutAction(makeAjaxCall: AjaxCallMaker<BulkAction>, payload?: CheckoutBulkItemsAction): Promise<CartClientState> {
    const data = payload as BulkAction;
    const actionType = data?.actionType;

    if (actionType === BulkActionType.Checkout) {
        if (!containsBulkXoItems(data as CheckoutBulkItemsAction)) {
            throw new Error('[CHECKOUT Bulk Action]: Items missing!');
        }
    }

    return makeAjaxCall('/api/bulk', data);
}