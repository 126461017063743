import { getCartPageId } from '../../../utils/tracking-utils';
import {
    ActionTrackingData,
    MODULE_TRACKING_DATA,
    TRACKING_DATA_MAPPER,
    TrackingData,
    trackPulsar
} from '../../../utils/tracking';

export function trackViewDynamicBanner(config?: ActionTrackingData) {
    const { isGuest, shoppingCartId } = config || {};
    const pageId = getCartPageId(isGuest);
    const trackingData = MODULE_TRACKING_DATA[TRACKING_DATA_MAPPER.DYNAMIC_BANNER_VIEW];
    const dynamicBannerViewTrackingData: TrackingData = {
        eventFamily: 'CART',
        eventAction: 'ACTN',
        operationId: String(pageId),
        flushImmediately: false,
        eventProperty: {
            actionKind: 'VIEW',
            sid: `p:${pageId}.mi:${trackingData?.mid}`,
            moduledtl: `mi:${trackingData?.mid}`,
            pge: pageId,
            ebc: shoppingCartId,
            efam: 'CART'
        }
    };

    trackPulsar(dynamicBannerViewTrackingData);
}
