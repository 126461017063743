import { FeatureFlagsData } from '../../common/utils/hydration/types';

export const parseBooleanFromValue = (value?: any): boolean => {
    if (typeof value === 'string') {
        return value === 'true' ? true : false;
    }
    return value === true;
};

export const shouldShowMerchOnEmptyCart = (featureFlagsData?: FeatureFlagsData): boolean => {
    if (featureFlagsData?.showEmptyCartMerch) {
        const showEmptyCartMerch = featureFlagsData?.showEmptyCartMerch?.value;
        if (typeof showEmptyCartMerch === 'object') {
            return parseBooleanFromValue(showEmptyCartMerch?.showRecommendations);
        }
    }
    return false;
};

export const showRecommendationsFirstOnEmptyCart = (featureFlagsData?: FeatureFlagsData): boolean => {
    if (featureFlagsData?.showEmptyCartMerch) {
        const showEmptyCartMerch = featureFlagsData?.showEmptyCartMerch?.value;
        if (typeof showEmptyCartMerch === 'object') {
            return parseBooleanFromValue(showEmptyCartMerch?.recommendationsFirst);
        }
    }
    return false;
};
