import { useEffect, useState, createContext, Dispatch, SetStateAction } from 'react';
import { PageMask } from './page-mask';
import { PageMaskContainerProps } from './types';

export const PageMaskContext = createContext<Dispatch<SetStateAction<boolean>>>(() => null);

export function PageMaskProvider({ children, onloadSpinner = false }: PageMaskContainerProps) {
    const [isLoading, setIsLoading] = useState(onloadSpinner);

    useEffect(() => {
        setIsLoading(onloadSpinner);
    }, [onloadSpinner]);

    return (
        <PageMaskContext.Provider value={setIsLoading}>
            <PageMask show={isLoading} onloadSpinner={onloadSpinner} />
            {children}
        </PageMaskContext.Provider>
    );
}
