import React, { useContext, } from 'react';
import { useBulkActions } from '../hooks';
import { BulkActionContextProviderProps, BulkActionsHookData } from '../types';

export const BulkActionsContext = React.createContext<BulkActionsHookData>({} as BulkActionsHookData);

export function BulkActionsContextProvider({ initialState, cartState, children, onBulkSelection }: BulkActionContextProviderProps) {
    const bulkActionsState = useBulkActions({ initialState, cartState, onBulkSelection });
    return (
        <BulkActionsContext.Provider value={bulkActionsState}>
            {children}
        </BulkActionsContext.Provider>
    );
}

export const useBulkActionsContext = (): BulkActionsHookData => {
    const bulkActionsState: BulkActionsHookData = useContext(BulkActionsContext);
    return bulkActionsState;
};