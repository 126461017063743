import { useEffect, useRef } from 'react';
import { ImageDisplay } from '../../../../common/components/image-display';
import { TextDisplay } from '../../../../common/components/text-display';
import { useAppMetaContextData } from '../../../../common/utils/app-meta-context';
import { BannerCardProps } from '../banner-card/types';
import { trackClickCobrandedAction, trackViewCobrandedAction } from '../banner-card/tracking';

import '../mini-banner-card/style.less';


export function MiniBannerCard({ bannerCard }: BannerCardProps) {
    const alreadyTrackedView = useRef(false);
    const { appMeta, meta } = useAppMetaContextData();

    useEffect(() => {
        if (bannerCard?.action && !alreadyTrackedView.current) {
            alreadyTrackedView.current = true;
            trackViewCobrandedAction(appMeta?.isGuest, meta?.shoppingCartId);
        }
    }, [bannerCard?.action, appMeta, meta]);

    if (!bannerCard?.action) {
        return null;
    }

    const { subTitle, slug, brandImage, action } = bannerCard;
    const handleClick = () => {
        trackClickCobrandedAction(appMeta?.isGuest, meta?.shoppingCartId);
    };

    return (
        <a target="_blank" href={action.URL} rel="noreferrer" onClick={handleClick}>
            <div className="mini-banner-card">
                <div className="mini-banner-content mini-banner-image">
                    {brandImage && (
                        <ImageDisplay
                            title={brandImage.title}
                            url={brandImage.URL}
                            lazyOnLoad
                        />
                    )}
                </div>

                <div className="mini-banner-content mini-banner-text">
                    <span className="main-text">
                        <TextDisplay data={subTitle} />&nbsp;
                    </span>
                    <span className="sub-text">
                        <TextDisplay data={slug} />
                    </span>
                </div>
            </div>
        </a>
    );

}