import { EbayIcon, Icon } from '@ebay/ui-core-react/ebay-icon';
import { TextDisplay } from '../../../../../common/components/text-display';
import { TEST_IDS } from '../../../../../common/utils/test-ids/test-ids';
import { handleAdjustmentActionClick } from '../domain/service';
import { getIconName } from './domain/specifications';
import { AdjustmentProps } from './types';


export function GroupAdjustment({ adjustment, clientTrackingData }: AdjustmentProps) {
    if (!adjustment) {
        return null;
    }

    const iconName = adjustment?.icon && getIconName(adjustment?.icon);
    const isHidden = Boolean(adjustment.action?.accessibilityText);

    return (
        <>
            <div className="group-adjustment">
                { iconName && (
                    <div className="group-adjustment-icon">
                        <EbayIcon className='adjustment-icon' name={iconName as Icon} />
                    </div>
                )}
                <div className="adjustment-content">
                    {adjustment.title ? (
                        <div className="adjustment-title">
                            <TextDisplay data={adjustment.title} />
                        </div>
                    ) : null}


                    {adjustment.message ? (adjustment.message.map((message, index) => (
                        <div key={index} className="adjustment-message">
                            <TextDisplay data={message} />
                        </div>
                    ))) : null}
                </div>


                {adjustment.amount ? (
                    <div aria-hidden={isHidden} className="adjustment-amount">
                        <TextDisplay data={adjustment.amount} />
                    </div>
                ) : null}

                { adjustment.action ? (
                    <div className='adjustment-action-icon'>
                        <a
                            href="#"
                            className='item-group-link'
                            onClick={(event: React.MouseEvent<Element, MouseEvent>) => handleAdjustmentActionClick(event, adjustment)}
                            data-test-id={TEST_IDS.moreDeals}
                            data-click={clientTrackingData && JSON.stringify(clientTrackingData[0])}
                        >
                            <EbayIcon name="chevronRight16" aria-hidden={isHidden} />
                        </a>
                    </div>
                ) : null }
            </div>

        </>
    );
}
