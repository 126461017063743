import { Banner, BannerTitle, BannerDescription } from '@ebay/payments-graph-banner/ui';
import { useDynamicBanner } from '../../hooks';
import { shouldRenderBanner } from '../../utils/render-utils';
import { DynamicBannerFallback } from '../dynamic-banner-fallback/dynamic-banner-fallback';
import { DynamicBannerProps } from './types';
import './styles.less';

export function DynamicBanner({
    cartState
}: DynamicBannerProps) {
    const banner = useDynamicBanner({ cartState });
    if (!shouldRenderBanner(banner)) return null;
    const {
        backgroundColor,
        title,
        description,
        icon
    } = banner;
    return (
        <Banner backgroundColor={backgroundColor} className="dynamic-banner" icon={icon}>
            <BannerTitle
                contentKey={title?.contentKey}
                fallback={(<DynamicBannerFallback fallbackHtml={title?.fallbackHtml} />)}
            />
            { !!description?.length && description.map(
                (description, key) => (
                    <BannerDescription
                        contentKey={description?.contentKey}
                        fallback={(<DynamicBannerFallback fallbackHtml={description?.fallbackHtml} />)}
                        key={key}
                    />
                )
            )}
        </Banner>
    );
}
