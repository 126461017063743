import { EbayButton } from '@ebay/ui-core-react/ebay-button';
import classNames from 'classnames';
import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { getUrl } from '../../utils/experience-service-types/action';
import { TextSpans } from '../text-spans';
import type { TextDisplayProps } from './types';
import './styles.less';

export function NavigationTextDisplay({
    className,
    clientTrackingData,
    data,
    disabled,
    fluid,
    labelId,
    lineItemId,
    onClick,
    openNewWindow,
    priority,
    testId,
    variant
}: TextDisplayProps) {
    if (!data) {
        return null;
    }

    const commonNavProps = {
        className,
        href: disabled ? undefined : getUrl(data.action),
        target: openNewWindow,
        'aria-disabled': disabled,
        'data-test-id': testId,
        'data-line-item-id': lineItemId,
        'data-track': JSON.stringify(clientTrackingData),
        priority
    };

    return (
        <EbayTrackingClick trackingList={clientTrackingData}>
            {variant === 'fake-btn' ? (
                <EbayButton
                    {...commonNavProps}
                    disabled={disabled}
                    fluid={fluid}>
                    <TextSpans textSpans={data.textSpans} clippedText={data.accessibilityText} />
                </EbayButton>
            ) : (
                <a
                    {...commonNavProps}
                    className={classNames( className, { 'link--disabled': disabled })}
                    onClick={event => {
                        if (disabled) {
                            event.preventDefault();
                        }
                        onClick?.(event, data.action);
                    }}>
                    <TextSpans textSpans={data.textSpans} clippedText={data.accessibilityText} labelId={labelId}/>
                </a>
            )}
        </EbayTrackingClick>
    );
}
