import { InfoBubble } from '../info-bubble';
import { TextDisplay } from '../text-display';
import { useAppMetaContextData } from '../../utils/app-meta-context';
import { getActionTrackingData } from '../../utils/tracking-utils';
import { TextDisplayWithInfoProps } from './types';



import './styles.less';

export function TextDisplayWithInfo({ data, itemId, ...rest }: TextDisplayWithInfoProps) {
    const { appMeta, meta } = useAppMetaContextData();
    if (!data) {
        return null;
    }

    let infoBubble = null;
    if (data?.infoBubble) {
        const clientTrackingData = getActionTrackingData({
            sellerId: rest?.sellerId,
            isGuest: appMeta?.isGuest,
            shoppingCartId: meta?.shoppingCartId,
            refActionName: rest?.testId?.toUpperCase()
        });

        infoBubble = <InfoBubble data={data.infoBubble} testId={rest?.testId} dataTrack={clientTrackingData}/>;
    }

    return <TextDisplay data={data} itemId={itemId} {...rest} infoBubble={infoBubble}/>;
}
