import { ChangeEvent } from 'react';
import { EbaySelect, EbaySelectOption } from '@ebay/ui-core-react/ebay-select';
import { EbayField, EbayLabel } from '@ebay/ui-core-react/ebay-field';
import { extractText } from '@ebay/ebayui-app-react/ebay-textual-display';
import { useUniqueId } from '../../utils/unique-id';
import { findSelectedOption, shouldShowTextbox } from './domain/specifications';
import type { DropdownProps, ModifiedField } from './types';

export function Dropdown({ group, label, selected, quantityRef, paramKeyValues, testId, onMaxValue, onAction }: DropdownProps) {
    const inputId = useUniqueId(`dropdown-${paramKeyValues?.lineItemId || ''}`);
    const handleChange = (value: string) => {
        const selectedOption = findSelectedOption(group, value);
        if (shouldShowTextbox(selectedOption?.fieldActions)) {
            if (onMaxValue) {
                onMaxValue({
                    paramKey: selectedOption?.paramKey,
                    paramValue: selectedOption?.paramValue
                });
            }
        } else {
            const actionData: ModifiedField = {};
            actionData.params = paramKeyValues || {};
            if (selectedOption && selectedOption.paramKey) {
                actionData.params[selectedOption.paramKey] = selectedOption.paramValue || '';
            }
            if (onAction) {
                onAction({
                    params: paramKeyValues
                });
            }
        }
    };

    return (
        <EbayField>
            <EbayLabel htmlFor={inputId}>
                { extractText(label) }
            </EbayLabel>
            <EbaySelect
                id={inputId}
                value={selected?.value}
                data-test-id={testId}
                ref={quantityRef}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                    const value = event.target.value;
                    handleChange(value);
                }}
            >
                {
                    group?.map((option, index) => (
                        <EbaySelectOption key={index} value={option.paramValue}>
                            { extractText(option.label) || option.paramValue }
                        </EbaySelectOption>
                    ))
                }
            </EbaySelect>
        </EbayField>
    );
}
