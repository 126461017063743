import { TextDisplay } from '../text-display';
import { useBulkActionsContext } from '../bulk-actions/context';
import { ImageDisplay } from '../image-display/image-display';
import { useAppMetaContextData } from '../../utils/app-meta-context/hooks';
import { SellerSignals } from '../signals';
import { useSignalsContext } from '../signals/context';
import { SellerBadgeProps } from './types';
import './styles.less';

export function SellerBadge({
    data,
    sellerId
}: SellerBadgeProps) {
    const { activeBulkActionsModule, cartBulkXoEnabled } = useBulkActionsContext();
    const { appMeta } = useAppMetaContextData();
    const { isInFGQLSignalsTreatment } = useSignalsContext();

    if (!data) {
        return null;
    }

    return (
        <h2 className="seller-badge-container seller-ctr text-truncate">
            <div className="seller-badge">
                <div className='seller-icon'>
                    <ImageDisplay
                        aria-hidden="true"
                        title={data?.logo?.title}
                        url={data?.logo?.URL}
                    />
                </div>
                <div className='seller-details'>
                    <div className='seller-name black-link'>
                        <TextDisplay
                            className='black-link'
                            data={data?.sellerName}
                            disabled={activeBulkActionsModule !== null && !cartBulkXoEnabled}
                            sellerId={sellerId}
                            openNewWindow={appMeta?.isMobile ? '_self' : '_blank'}
                        />
                        { isInFGQLSignalsTreatment && (
                            <SellerSignals
                                positiveFeedback={data?.positiveFeedback}
                                sellerId={sellerId}
                            />
                        ) }
                    </div>
                    { !isInFGQLSignalsTreatment && data?.positiveFeedback && (
                        <div className='seller-feedback'>
                            <TextDisplay data={data?.positiveFeedback} />
                        </div>
                    ) }
                </div>
            </div>
        </h2>
    );
}
