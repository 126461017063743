import { Message } from '@ebay/experience-types-ebay';
import { ModifiedValidationForTextbox, ParamValueTypeForTextbox } from '../../../../../common/utils/hydration';

const validateMinimum = (input: ParamValueTypeForTextbox, params: { [key: string]: unknown }) => {
    const minimum = parseInt(params.VALUE as string, 10);
    const inputNum = parseInt(input.toString(), 10);
    if (isNaN(inputNum)) {
        return false;
    } else if (!isNaN(minimum)) {
        return inputNum >= minimum;
    }
    return true;
};

const validateMaximum = (input: ParamValueTypeForTextbox, params: { [key: string]: unknown }) => {
    const maximum = parseInt(params.VALUE as string, 10);
    const inputNum = parseInt(input.toString(), 10);
    // Unlike in validateMinimum, we don't do input check here (return true if NaN)
    // since this is "more optional" and assuming service will handle this.
    if (!isNaN(inputNum) && !isNaN(maximum)) {
        return inputNum <= maximum;
    }
    return true;
};

const VALIDATIONS = {
    MORE_THAN_OR_EQUAL_TO: validateMinimum,
    LESS_THAN_OR_EQUAL_TO: validateMaximum
};

export const getValidationMessages = (value: ParamValueTypeForTextbox, validations?: ModifiedValidationForTextbox[]): Message [] => {
    const validationMessages: Message [] = [];
    validations?.map((validation: ModifiedValidationForTextbox) => {
        const validationType = validation.validationType;
        const params = validation.params;
        const message = validation.message;
        const validateFn = validationType && VALIDATIONS[validationType];
        if (typeof validateFn === 'function') {
            if (params && message && !validateFn(value, params)) {
                validationMessages.push(message);
            }
        }
    });
    return validationMessages;
};
