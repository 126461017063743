import { TextDisplay } from '../../../text-display';
import type { ItemLogisticDetailsProps } from './types';

import './styles.less';

export function ItemLogisticsDetails({ logisticsDetails }: ItemLogisticDetailsProps) {
    if (!logisticsDetails?.length) {
        return null;
    }

    return (
        <div className="logistics-details">
            {logisticsDetails.map((logisticsDetail, index) => (
                <div key={index}>
                    <TextDisplay data={logisticsDetail} />
                </div>
            ))}
        </div>
    );
}
