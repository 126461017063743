export enum BulkActionType {
    Remove = 'BULK_REMOVE_SELECTED',
    Checkout = 'BULK_CHECKOUT_SELECTED'
}
export type BulkActionParamsTypes = {
    lineItemIds?: string[];
};

export interface RemoveBulkItemsAction {
    actionType: BulkActionType;
    params: BulkActionParamsTypes;
}

export interface CheckoutBulkItem {
    itemId?: string;
    quantity?: string;
    transactionId?: string;
    variationId?: string;
}

export interface CheckoutBulkItems {
    lineItem?: CheckoutBulkItem;
}

export interface CheckoutBulkItemsParamTypes {
    items?: CheckoutBulkItems[];
    shoppingCartId?: string;
}

export interface CheckoutBulkItemsAction {
    actionType: BulkActionType;
    params: CheckoutBulkItemsParamTypes;
}

// NOTE new types for future actions can be added here: type | type | ...
export type BulkAction = RemoveBulkItemsAction;
