import { Action } from '@ebay/experience-types-ebay';
import { CartClientState } from '../../../../../../common/utils/hydration';
import { AjaxCallMaker } from '../../../../../common/utils/ajax';
import { AddToCartRequest, RemoveItemRequest } from '../types';
import { containsLineItemId } from './specifications';

export async function addBackToCart(makeAjaxCall: AjaxCallMaker<AddToCartRequest>, action?: Action): Promise<CartClientState> {
    const data = action?.params as AddToCartRequest;
    if (!containsLineItemId(data)) {
        throw new Error('[addBackToCart]: LineItemId missing!');
    }

    return makeAjaxCall('/api/s2c', data);
}

export async function removeItem(makeAjaxCall: AjaxCallMaker<RemoveItemRequest>, action?: Action): Promise<CartClientState> {
    const data = action?.params as RemoveItemRequest;
    if (!containsLineItemId(data)) {
        throw new Error('[removeItem]: LineItemId missing!');
    }

    return makeAjaxCall('/api/rm', data);
}