import { NavigationType } from './types';

export function isElementInViewport(element: HTMLElement | null, margin = 0): boolean {
    if (!element) {
        return false;
    }

    const rect = element.getBoundingClientRect();

    return (
        rect.top + margin >= 0 &&
          rect.left + margin >= 0 &&
          rect.bottom - margin <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right - margin <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function getPageLoadEventName(): 'load' | 'pageshow' {
    return 'onpageshow' in window ? 'pageshow' : 'load';
}

export function isPageLoadEventFired() {
    // "complete" means that the document and all sub-resources
    // have finished loading. The state indicates that the load
    // event is about to fire.
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
    return document.readyState === 'complete';
}

export const addPageLoadEventListener = (callback: () => void) => {
    if (isPageLoadEventFired()) {
        callback();
    } else {
        window.addEventListener(getPageLoadEventName(), callback);
    }
};

/**
 * Add the page load event handler but executes the callback in case the event was already fired
 * This issue is more visible on iOS devices when used in a useEffect()
 */
export function addWindowLoadEvent(handler: () => void) {
    if (typeof handler !== 'function') {
        return;
    }

    if (isPageLoadEventFired()) {
        handler();
    } else {
        window.addEventListener(getPageLoadEventName(), handler);
    }
}

export function addWindowUnLoadEvent(handler: () => void) {
    if (typeof handler !== 'function') {
        return;
    }
    window.addEventListener('visibilitychange', () => {
        if (window.document.visibilityState === 'hidden') {
            handler();
        }
    });
}

export function removeWindowLoadEvent(handler: () => void) {
    window.removeEventListener(getPageLoadEventName(), handler);
}

/**
* Listener for back event for safari || firefox to refresh and break bfcache
*/
export function refreshPageOnBackButton() {
    window.addEventListener('pageshow', (event) => {
        if (event.persisted || window.performance?.navigation?.type === NavigationType.BackForward) {
            window.location.reload();
        }
    });
}

export function lazyLoad(fn: () => void) {
    const lazyLoad = window.requestIdleCallback
                ||
                (() => setTimeout(fn, 5000));

    return lazyLoad(fn);
}
