import { CartClientState } from '../../../../common/utils/hydration/types';
import { ClientLogger } from '../client-logger';
import { lazyLoad } from '../dom';
import { Resources, ResourceResponseObject } from './types';


export function handlePrediction(pageResources: object, clientLogger: ClientLogger) {
    for (const pageResource in pageResources) {
        const resources: string[] = pageResources[pageResource as keyof typeof pageResources];
        resources?.forEach((resource: string) => {
            if (resource && (resource?.includes?.('.js') || resource?.includes?.('.css'))) {
                void fetch(resource, { method: 'GET' }).catch((error: unknown) =>
                {
                    if (error instanceof Error) {
                        clientLogger.error('UNABLE_TO_FETCH_XO_RESOURCES', {
                            name: 'UNABLE_TO_FETCH_XO_RESOURCES',
                            message: `problem in loading xo resources -> ${error.message}`
                        });
                    } else {
                        clientLogger.error('UNABLE_TO_FETCH_XO_RESOURCES', {
                            name: 'UNABLE_TO_FETCH_XO_RESOURCES',
                            message: `problem in loading xo resources -> ${String(error)}`
                        });
                    }

                });
            }
        });
    }
}


export function prefetchXOResource(initialState: CartClientState, clientLogger: ClientLogger) {
    const { appMeta, appConfig } = initialState;
    const profile = appMeta?.isMobile ? 'mobile' : 'desktop';
    const prefetchURL = appConfig?.xoPrefetchURL;
    prefetchURL && lazyLoad(() => {
        fetch(prefetchURL, { method: 'GET' })
            .then(async(response: Response) => {
                if (response.status === 200) {
                    const resourcesList = await response.json() as ResourceResponseObject;
                    const pageResources: Resources = resourcesList && resourcesList[`ryp_member_${profile}`];
                    if (pageResources && Object.keys(pageResources).length > 0) {
                        handlePrediction(pageResources, clientLogger);
                    }
                } else {
                    throw new Error('UNABLE_TO_FETCH_XO_RESOURCES');
                }
            })
            .catch(() => {
                clientLogger.error('UNABLE_TO_FETCH_XO_RESOURCES', {
                    name: 'UNABLE_TO_FETCH_XO_RESOURCES',
                    message: 'problem in loading xo resources'
                });
            });
    });
}


