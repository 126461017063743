import { Action } from '@ebay/experience-types-ebay';
import { BulkActionItemsMap } from '../../../../../common/components/bulk-actions/types';
import { isListingSummaryDisabled } from '../../../../../common/components/bulk-actions/utils/utils';
import { ShoppingCartListingSummary } from '../../../../../../common/utils/hydration/types';

export const createGoToCheckoutParams = (action: Action) => {
    const params = action.params || {} as { [key: string]: string | number };
    params.carttime = new Date().getTime();
    return params;
};

export const getItemIdsFromListingSummary = (lineItemSummaries: ShoppingCartListingSummary[] = []) => {
    return lineItemSummaries?.map((lineItemSummary) => lineItemSummary?.listingId).join(',');
};

export const createGoToBulkCheckoutUrl = (baseUrl = '', bulkActionsMap: BulkActionItemsMap, shoppingCartId = '') => {
    // Reference: https://github.corp.ebay.com/eBayMobile/feature_erds/blob/main/ShoppingCart/BulkProceedToXo.md#example-checkout-request-body
    const itemsMap = Array.from(bulkActionsMap.values());
    const itemsAvailableForCheckout = itemsMap.filter((entry) => !isListingSummaryDisabled(entry?.lineItemSummaries));

    if (baseUrl && shoppingCartId && itemsAvailableForCheckout?.length > 0) {
        const itemIds = itemsAvailableForCheckout.map((entry) => getItemIdsFromListingSummary(entry?.lineItemSummaries) || entry?.itemId).join(',');
        return `https://pay.${baseUrl}/rxo?pagename=ryp&shoppingcartid=${shoppingCartId}&action=create&items=${itemIds}`;
    }
    return '';
};
