import { FeatureFlagExperimentResponseObject } from '@ebay/experiments';

export interface QtagsProcessedData {
    [key: string]: string | undefined | null;
}

export type TrackingEventPayload = { [name: string]: string | object };

export enum SignalsExperimentTreatments {
    Control = '0',
    TreatmentOne = '1',
    TreatmentTwo = '2',
    TreatmentThree = '3'
}

export interface EnableFGQLSignalsFeatureFlagExperimentResponseObject extends FeatureFlagExperimentResponseObject {
    value?: {
        'web.xoBundledSignals'?: SignalsExperimentTreatments;
    };
}

export interface EnableDynamicBannerFeatureFlagExperimentResponseObject extends FeatureFlagExperimentResponseObject {
    value?: {
        xoBundledDynamicFopBanner?: string;
    };
}

export interface CartBulkXOFeatureFlagExperimentResponseObject extends FeatureFlagExperimentResponseObject {
    'CART_BULK_XO'?: string;
}

export interface QuickViewFeatureFlagExperimentResponseObject extends FeatureFlagExperimentResponseObject {
    value?: {
        ENABLE_CART_QUICK_VIEW?: string;
    };
}

export interface FeatureFlagsData {
    shoppingCartBulkActionsTourTip?: boolean;
    displaySummaryPaymentIcons?: boolean;
    treatmentIds?: string[];
    qtags?: QtagsProcessedData;
    cartBulkXoEnabled?: CartBulkXOFeatureFlagExperimentResponseObject;
    reactEnablePreloadHtml?: boolean;
    dWebOrderSummaryOptimization?: boolean;
    dWebBannerReposition?: boolean;
    preRenderCheckoutFromEdge?: FeatureFlagExperimentResponseObject;
    enableGuestOTPAuthFlow?: FeatureFlagExperimentResponseObject;
    mobileEMBGWithGreyBackgroundSummary?: boolean;
    showEmptyCartMerch?: FeatureFlagExperimentResponseObject;
    enableItemGrouping?: boolean;
    enableQuickViewMobileMember?: QuickViewFeatureFlagExperimentResponseObject;
    enableQuickViewMobileGuest?: QuickViewFeatureFlagExperimentResponseObject;
    enableQuickViewDesktopMember?: QuickViewFeatureFlagExperimentResponseObject;
    enableQuickViewDesktopGuest?: QuickViewFeatureFlagExperimentResponseObject;
    enableFGQLSignals?: EnableFGQLSignalsFeatureFlagExperimentResponseObject;
    enableDynamicBanner?: EnableDynamicBannerFeatureFlagExperimentResponseObject;
    openLinksInNewTab?: boolean;
}
