import { EbayButton } from '@ebay/ui-core-react/ebay-button';
import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { getTrackingList } from '../../../utils/experience-service-types/action';
import { TextSpans } from '../../text-spans';
import { isDisabledButton } from '../utils';
import { BulkActionNames } from '../types';
import { BulkActionsData } from '../../../../../common/utils/hydration';
import { BulkActionTourtipInactiveContent } from '../bulk-actions-tourtip-content';
import { useBulkActionsContext } from '../context';
import type { BulkActionInactiveContentProps } from '../types';
import './bulk-actions-desktop.less';

export function BulkActionInactiveContent({
    activeState,
    activeBulkActionsModule,
    shoppingCartBulkActionsTourTip = false,
    data,
    handleAction,
    handleOnTourtipCollapse,
    moduleName,
    shouldRenderTourtip = false
}: BulkActionInactiveContentProps) {
    const bulkActionContext = useBulkActionsContext();
    const { cartBulkXoEnabled } = bulkActionContext;

    const {
        enableBulkMode,
    }: BulkActionsData = data;

    // This is to not rely on service data when User is on CartBulkXOEnabled EP
    const showBulkActionsInactiveAction = enableBulkMode && !cartBulkXoEnabled;
    if (!data || Object.keys(data).length === 0 || !showBulkActionsInactiveAction) {
        return null;
    }

    return showBulkActionsInactiveAction && (
        <EbayTrackingClick trackingList={getTrackingList(enableBulkMode?.action)}>
            {
                // TODO remove this tourtip logic once done with the EP
                shoppingCartBulkActionsTourTip && shouldRenderTourtip ? (
                    <BulkActionTourtipInactiveContent data={data} pointer="top-left" onCollapse={handleOnTourtipCollapse} overlayStyle={{ top: 50, left: 30 }}>
                        <EbayButton
                            aria-label={enableBulkMode?.accessibilityText}
                            data-test-id={BulkActionNames.EnableBulkMode}
                            priority='secondary'
                            onClick={(event: React.MouseEvent<Element, MouseEvent> ) => handleAction(event, enableBulkMode.action)}
                            disabled={isDisabledButton(activeState, moduleName, activeBulkActionsModule)}
                        >
                            <TextSpans textSpans={enableBulkMode?.textSpans}/>
                        </EbayButton>
                    </BulkActionTourtipInactiveContent>
                ) : (
                    <EbayButton
                        aria-label={enableBulkMode?.accessibilityText}
                        data-test-id={BulkActionNames.EnableBulkMode}
                        priority='secondary'
                        onClick={(event: React.MouseEvent<Element, MouseEvent> ) => handleAction(event, enableBulkMode.action)}
                        disabled={isDisabledButton(activeState, moduleName, activeBulkActionsModule)}
                    >
                        <TextSpans textSpans={enableBulkMode?.textSpans}/>
                    </EbayButton>
                )}
        </EbayTrackingClick>
    );
}