import { PaymentMethodsIcons } from './types';

export const getFOPIconName = (iconName = ''): PaymentMethodsIcons => {
    switch (iconName) {
        case 'AMEX':
            return PaymentMethodsIcons.Amex;
        case 'APPLE_PAY':
            return PaymentMethodsIcons.ApplePay;
        case 'DISCOVER':
            return PaymentMethodsIcons.Discover;
        case 'GOOGLE_PAY':
            return PaymentMethodsIcons.GooglePay;
        case 'MASTERCARD':
            return PaymentMethodsIcons.MasterCard;
        case 'PAYPAL':
            return PaymentMethodsIcons.Paypal;
        case 'VISA':
            return PaymentMethodsIcons.Visa;
        default:
            return PaymentMethodsIcons.GenericCard;
    }
};