import { programBadgesTypes } from '@ebay/signals-components-provider/program-badges';
import {
    getSignalByIdAndType,
    ItemIdToSellerIdMap, MappedSignals, signalsTypes
} from '@ebay/signals-components-provider/utils';
import { GetSignalsFGQLResponse } from '@ebay/payments-graph-client/signals';
import {
    CartDetails,
    SignalMap,
    Signals
} from '../../../../../common/utils/hydration';

// GraphQL has item signals with the key only as ITEM_ID as they don't care about variation id. But, VAS item
// signals do care about item id. We prioritize VAS, but if those don't exist then we fall back to FGQL.
export function getProgramSignalByIds(mappedSignals: MappedSignals, listingId: string, variationId = '') {
    const listingIdAndVariationId = `${listingId}-${variationId}`;
    const vasSignal = getSignalByIdAndType(listingIdAndVariationId, signalsTypes.PROGRAM_BADGE, mappedSignals);
    if (!vasSignal?.signalName || !vasSignal?.Component) {
        return getSignalByIdAndType(listingId, signalsTypes.PROGRAM_BADGE, mappedSignals);
    }
    return vasSignal;
}

export function buildProgramBadgeLocalizedText(signalsBundle: Record<string, string>, signalName?: string, isMobile?: boolean) {
    if (programBadgesTypes.TOP_RATED_SELLER === signalName) {
        return signalsBundle[isMobile ? `${signalName}_SHORT` : signalName];
    }
    return signalName ? signalsBundle?.[signalName] : undefined;
}

export function isTopRatedSeller(signalName?: string) {
    return signalName === programBadgesTypes.TOP_RATED_SELLER;
}

export function isTopRatedPlus(signalName?: string) {
    return signalName === programBadgesTypes.TOP_RATED_PLUS;
}

export function buildItemIdToSellerIdMap(cartDetails?: CartDetails): ItemIdToSellerIdMap {
    const itemIdToSellerId: ItemIdToSellerIdMap = {};
    for (const sellerBucket of cartDetails?.sellerBuckets || []) {
        const sellerId = sellerBucket.seller?.sellerName?.textSpans?.[0]?.text;
        for (const lineItem of sellerBucket?.lineItems || []) {
            for (const listingSummary of lineItem?.listingSummaries || []) {
                if (listingSummary?.listingId && sellerId) {
                    itemIdToSellerId[listingSummary.listingId] = sellerId;
                }
            }
        }
    }
    return itemIdToSellerId;
}

function mergeSignals(fgqlSignalMap?: SignalMap, vasSignalMap?: SignalMap): SignalMap | undefined {
    if (!vasSignalMap) { return fgqlSignalMap; }
    if (!fgqlSignalMap) { return vasSignalMap; }
    return { ...fgqlSignalMap, ...vasSignalMap };
}

export function mergeSignalsState(fgqlSignals: Signals, vasSignals?: Signals): Signals {
    if (!vasSignals) { return fgqlSignals; }
    if (!fgqlSignals) { return vasSignals; }
    return {
        itemSignals: mergeSignals(fgqlSignals?.itemSignals, vasSignals?.itemSignals),
        sellerSignals: mergeSignals(fgqlSignals?.sellerSignals, vasSignals?.sellerSignals)
    };
}

export function isRawSignalsDataEmpty(rawSignalsData?: GetSignalsFGQLResponse) {
    return !rawSignalsData?.listingsV2ByIds?.listings
        ?.some((listing) => listing?.signalsPlacements?.length > 0);
}

export function doesFgqlSignalsHaveTopRatedSeller(fgqlSignals?: Signals) {
    return Object.values(fgqlSignals?.sellerSignals || {}).some(signals =>
        signals?.some(signal => isTopRatedSeller(signal?.name))
    );
}

export function doesFgqlSignalsHaveTopRatedPlus(fgqlSignals?: Signals) {
    return Object.values(fgqlSignals?.itemSignals || {}).some(signals =>
        signals?.some(signal => isTopRatedPlus(signal?.name))
    );
}

// This function isn't really needed, but it makes the functions in experimentation.ts very readable.
export function doesVasSignalsExist(vasSignals?: Signals) {
    return !!vasSignals;
}

export function removeTopRatedSellerFromFgqlSignals(fgqlSignals: Signals): Signals {
    if (!fgqlSignals?.sellerSignals) {
        return fgqlSignals;
    }

    const fgqlSellerSignalEntries = Object.entries(fgqlSignals.sellerSignals);
    for (const [key, signals] of fgqlSellerSignalEntries) {
        fgqlSignals.sellerSignals[key] = signals?.filter(signal => !isTopRatedSeller(signal?.name));
    }

    return fgqlSignals;
}

export function removeTopRatedPlusFromFgqlSignals(fgqlSignals: Signals): Signals {
    if (!fgqlSignals?.itemSignals) {
        return fgqlSignals;
    }

    const fgqlItemSignalEntries = Object.entries(fgqlSignals.itemSignals);
    for (const [key, signals] of fgqlItemSignalEntries) {
        fgqlSignals.itemSignals[key] = signals?.filter(signal => !isTopRatedPlus(signal?.name));
    }

    return fgqlSignals;
}
