import { GroupAdjustment } from '../adjustments/index';
import { useAppMetaContextData } from '../../../../common/utils/app-meta-context/hooks';
import { getActionTrackingData } from '../../../../common/utils/tracking-utils';
import { CartBucketLineItem } from './cart-bucket-line-item';
import type { CartBucketLineItemGroupProps } from './types';

import './styles.less';

export function CartBucketLineItemGroup({
    lineItems,
    lineItemGrouping,
    disabledBucket,
    onBuyItNow,
    onQuantityUpdate,
    onSavedForLater,
    onRemoveItem,
    sellerId,
    firstLineItem,
    seller
}: CartBucketLineItemGroupProps) {
    const sellerLineItemsCache = new Map(lineItems?.map((data) => [data.lineItemId, data]));
    const { appMeta, meta } = useAppMetaContextData();

    if (!lineItemGrouping) {
        return null;
    }

    const groupAdjustment = lineItemGrouping?.adjustment;
    const clientTrackingData = getActionTrackingData({
        action: groupAdjustment?.action,
        sellerId,
        isGuest: appMeta?.isGuest,
        shoppingCartId: meta?.shoppingCartId,
    });

    return (
        <>
            <GroupAdjustment
                adjustment={groupAdjustment}
                clientTrackingData={clientTrackingData}
            />
            <ul className="cart-bucket__vendor-list">
                {
                    lineItemGrouping?.lineItemIds?.map((lineItemId, index) => {
                        const lineItem = sellerLineItemsCache.get(String(lineItemId));
                        return (
                            <li key={lineItemId}>
                                <CartBucketLineItem
                                    lineItem={lineItem}
                                    disabledBucket={disabledBucket}
                                    onBuyItNow={onBuyItNow}
                                    onQuantityUpdate={onQuantityUpdate}
                                    onRemoveItem={onRemoveItem}
                                    onSavedForLater={onSavedForLater}
                                    sellerId={sellerId}
                                    firstLineItem={firstLineItem}
                                    seller={seller}
                                />
                            </li>
                        );
                    })
                }
            </ul>
        </>
    );
}