import { AppContext, CartClientState, SignInPageUrlObject } from '../../../../common/utils/hydration';
import { CartStateCallback } from '../../../common/types/cart-state-handler';
import { SignInChooserData } from '../../../modules/signin-chooser';
import { redirectIfRequired } from './services';
import { shouldShowSignInChooser } from './specifications';

export function createSignInChooserData(cartState?: CartClientState, signInPageUrlObj?: SignInPageUrlObject, appContext?: AppContext): SignInChooserData {
    if (!shouldShowSignInChooser(cartState)) {
        return {};
    }

    const meta = cartState?.meta;
    const screenFlowDestination = meta?.screenFlowDestination;
    const baseUrl = signInPageUrlObj?.baseUrl;
    const signInUrlBase = signInPageUrlObj?.signInPageUrlBase;

    const RETURN_OPERATION = screenFlowDestination?.params?.RETURN_OPERATION;
    const guestCheckoutEligible = screenFlowDestination?.params?.guestCheckoutEligible || '';
    const sellerName = screenFlowDestination?.params?.sellerName || '';
    const optimizedMemberUrl = screenFlowDestination?.params?.OPTIMIZED_MEMBER_URL;
    const optimizedGuestUrl = screenFlowDestination?.params?.OPTIMIZED_GUEST_URL;
    const windowLocation = window.location.origin + window.location.pathname;
    let returnUrl = '';
    let gxoUrl = '';
    if (optimizedMemberUrl && optimizedGuestUrl) {// XOAPP-41922-shopping cartOptimization
        returnUrl = optimizedMemberUrl;
        gxoUrl = optimizedGuestUrl;
    } else {
        returnUrl = windowLocation;
        returnUrl = returnUrl.charAt(returnUrl.length - 1) !== '/' ? `${returnUrl}/` : returnUrl;
        gxoUrl = `${returnUrl}api/xo?guestCheckoutEligible=${guestCheckoutEligible}`;
        if (RETURN_OPERATION === 'PAY_ONLY_THIS_SELLER') {
            returnUrl += `api/pots?sellerName=${sellerName}`;
            gxoUrl = `${returnUrl}&guestCheckoutEligible=${guestCheckoutEligible}`;
        } else if (RETURN_OPERATION === 'CHECK_OUT_CART') {
            returnUrl += 'api/xo';
        }
    }

    return {
        baseUrl,
        enableGuestOTPAuthFlow: appContext?.enableGuestOTPAuthFlow,
        isMobile: appContext?.isMobile,
        locale: appContext?.locale,
        returnUrl,
        signInUrlBase,
        gxoUrl
    };
}

export function createCartStateHandler(stateUpdate: CartStateCallback, appContext?: AppContext, signInPageUrlObj?: SignInPageUrlObject): CartStateCallback {
    return function cartStateUpdate(cartState) {
        if (!redirectIfRequired(cartState, appContext, signInPageUrlObj)) {
            stateUpdate(cartState);
        }
    };
}
