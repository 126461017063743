import React, { useContext, } from 'react';
import { useQuickView } from '../hooks';
import { QuickViewContextProviderProps, QuickViewHookData } from '../types';

export const QuickViewContext = React.createContext<QuickViewHookData>({} as QuickViewHookData);

export function QuickViewContextProvider({ cartState, children }: QuickViewContextProviderProps) {
    const quickViewState = useQuickView({ cartState });
    return (
        <QuickViewContext.Provider value={quickViewState}>
            {children}
        </QuickViewContext.Provider>
    );
}

export const useQuickViewContext = (): QuickViewHookData => {
    return useContext(QuickViewContext);
};
