import { AppMetaContextProvider } from '../../../common/utils/app-meta-context';
import { FeatureFlagsDataProvider } from '../../../common/utils/experiments';
import { DeviceTypeProvider } from '../../../common/utils/device-type';
import { BulkActionsContextProvider } from '../../../common/components/bulk-actions/context';
import { LineItemContextProvider } from '../../../common/components/grid-line-item/context';
import { I18nProvider } from '../../../common/utils/i18n';
import { MappedSignalsContextProvider } from '../../../common/utils/signals/context';
import { SignalsContextProvider } from '../../../common/components/signals/context';
import { PageMaskProvider } from '../../../common/components/page-mask';
import { FocusOnActionContextProvider } from '../../../common/utils/focus-on-action/context';
import { QuickViewContextProvider } from '../../../common/components/quick-view/context';
import { ContextProviderProps } from './types';

export function ContextProvider({
    cartState,
    children,
    deviceType = 'desktop',
    initialState,
    isRedirectRequired,
    onBulkSelection
}: ContextProviderProps) {
    return (
        <FocusOnActionContextProvider cartState={cartState}>
            <I18nProvider initialState={initialState}>
                <AppMetaContextProvider initialState={initialState}>
                    <DeviceTypeProvider deviceType={deviceType} >
                        <LineItemContextProvider cartState={cartState} deviceMode={deviceType}>
                            <MappedSignalsContextProvider cartState={cartState}>
                                <BulkActionsContextProvider initialState={initialState} cartState={cartState} onBulkSelection={onBulkSelection}>
                                    <FeatureFlagsDataProvider initialState={initialState}>
                                        <QuickViewContextProvider cartState={cartState}>
                                            <SignalsContextProvider cartState={cartState}>
                                                <PageMaskProvider onloadSpinner={
                                                    isRedirectRequired &&
                                                    initialState?.meta?.onloadSpinner
                                                }>
                                                    {children}
                                                </PageMaskProvider>
                                            </SignalsContextProvider>
                                        </QuickViewContextProvider>
                                    </FeatureFlagsDataProvider>
                                </BulkActionsContextProvider>
                            </MappedSignalsContextProvider>
                        </LineItemContextProvider>
                    </DeviceTypeProvider>
                </AppMetaContextProvider>
            </I18nProvider>
        </FocusOnActionContextProvider>
    );
}
