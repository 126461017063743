import { createContext } from 'react';
import { MappedSignals } from '@ebay/signals-components-provider/utils';
import { useSignalsMapper } from '../hooks';
import { MappedSignalsContextProviderProps } from './types';

export const MappedSignalsContext = createContext<MappedSignals>({});

export function MappedSignalsContextProvider({ cartState, children }: MappedSignalsContextProviderProps) {
    const signals = cartState?.modules?.signals;
    const mappedSignals = useSignalsMapper(signals);
    return (
        <MappedSignalsContext.Provider value={mappedSignals as MappedSignals}>
            {children}
        </MappedSignalsContext.Provider>
    );
}