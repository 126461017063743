import { LoadingItemCard } from './ads-loading-item-card';

export function Loading() {
    return (
        <div className="merch-loading-state" data-testid="loading-container">
            <div className="merch-loading-skeleton" data-testid="skeleton">
                <div className="merch-loading merch-loading--title" />
                <ul className="merch-loading-list-container">
                    <LoadingItemCard />
                    <LoadingItemCard />
                    <LoadingItemCard />
                    <LoadingItemCard />
                    <LoadingItemCard />
                </ul>
            </div>
        </div>
    );
}
