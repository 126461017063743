import { useSignalsContext } from '../context';
import { ItemSignalsProps } from '../types';
import { useI18n } from '../../../utils/i18n';
import { getProgramSignalByIds } from '../utils';

export function ItemSignals({
    listingId,
    variationId
}: ItemSignalsProps) {
    const { getSignalsLocales } = useI18n();
    const { mappedSignals, isFGQLCallLoading } = useSignalsContext();

    if (isFGQLCallLoading) {
        return (
            <div className="item-signals silhouettes">
                <div className="silhouette-box">
                    <div className="silhouette-box silhouette-loading" />
                </div>
            </div>
        );
    } else if (!listingId || !mappedSignals) {
        return null;
    }

    const signalsBundleText = getSignalsLocales();
    const mappedProgramBadge = getProgramSignalByIds(mappedSignals, listingId, variationId);
    const ProgramBadgeComponent = mappedProgramBadge?.Component;
    if (!ProgramBadgeComponent || !mappedProgramBadge?.signalName) {
        return null;
    }

    const programBadgeLocalizedText = signalsBundleText?.[mappedProgramBadge.signalName];
    return (
        <ProgramBadgeComponent
            localizedText={programBadgeLocalizedText}
            className="item-signals"
        />
    );
}
