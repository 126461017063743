import { TextDisplay } from '../../../text-display';
import type { ItemLogisticsCostsProps } from './types';

import './styles.less';

export function ItemLogisticsCosts({ logisticsCosts }: ItemLogisticsCostsProps) {
    if (!logisticsCosts?.length) {
        return null;
    }

    return (
        <div className="logistics-costs">
            {logisticsCosts.map((logisticsCost, index) => (
                <div key={index}>
                    <TextDisplay data={logisticsCost} />
                </div>
            ))}
        </div>
    );
}
