import { Action } from '@ebay/experience-types-ebay';
import { TEST_IDS, TEST_INFO_KEYS } from '../../../../common/utils/test-ids';
import { useAjax } from '../../../../common/utils/ajax';
import { LineItem as GridLineItem } from '../../../../common/components/grid-line-item';
import { LineItemActionType } from '../../../../common/components/grid-line-item/components/line-item-actions';
import { SpinnerContainer } from '../../../../common/components/spinner';
import { isLineItemDisabled } from '../../../cart-bucket/components/line-item/utils';
import { extractActionsFromLineItemSFL } from './utils';
import { addBackToCart, removeItem } from './domain/services';
import { SavedForLaterLineItemProps } from './types';

import './styles.less';

export function SavedForLaterLineItem({ lineItem, onAddBackToCart, onRemoveItem }: SavedForLaterLineItemProps) {
    const makeAjaxCall = useAjax();
    if (!lineItem) {
        return null;
    }

    const savedForLaterLineItemInfo = JSON.stringify({
        [TEST_INFO_KEYS.itemId]: lineItem.listingSummaries?.[0]?.listingId,
        [TEST_INFO_KEYS.itemVarId]: lineItem.listingSummaries?.[0]?.variationId
    });
    const lineItemActions = extractActionsFromLineItemSFL(lineItem);
    /*
        In SavedForLater case, listingSumaries is ALWAYS an array of length 1.
        listingSummaries can be an array of greater length only in cart details
    */

    const handleAction = (action?: Action): Promise<void> => {
        switch (action?.name ) {
            case LineItemActionType?.AddBackToCart:
                return addBackToCart(makeAjaxCall, action)
                    .then(onAddBackToCart)
                    .catch((error: Error) => {
                        console.error('[addBackToCart] Error in the request', error?.message);
                    });
            case LineItemActionType?.RemoveItem:
                return removeItem(makeAjaxCall, action)
                    .then(onRemoveItem)
                    .catch((error: Error) => {
                        console.error('[savedForLater] Error in the request', error?.message);
                    });
            default:
                return Promise.resolve();
        }
    };

    return (
        <li className='saved-for-later-line-item' data-test-id={TEST_IDS.savedForLaterLineItem} data-test-info={savedForLaterLineItemInfo}>
            <SpinnerContainer>
                <GridLineItem actions={lineItemActions} disabled={isLineItemDisabled(lineItem)} lineItem={lineItem} moduleName='SAVE_FOR_LATER' onAction={handleAction}/>
            </SpinnerContainer>
        </li>
    );
}
