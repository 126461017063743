import { createContext } from 'react';
import { ClientLocales } from '../../../../common/utils/hydration';
import { I18nProviderProps } from './types';

export const I18nContext = createContext<ClientLocales>({});

export function I18nProvider({ initialState, children }: I18nProviderProps) {
    const i18n = initialState?.i18n || {};
    return (
        <I18nContext.Provider value={i18n}>
            {children}
        </I18nContext.Provider>
    );
}
