import {
    EbayInfotip,
    EbayInfotipContent
} from '@ebay/ui-core-react/ebay-infotip';
import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { trackPulsar } from '../../../utils/tracking';
import { InfoBubbleProps } from '../types';
import { TextDisplay } from '../../../components/text-display';
import { TEST_IDS } from '../../../utils/test-ids';

import '../styles.less';

const POINTER = 'top';
const VARIANT = 'default';

export function InfoBubble({
    data,
    testId,
    dataTrack
}: InfoBubbleProps) {
    if (!data || !data.messageText || !data.title) {
        return null;
    }

    const { messageText, messageDismiss, bubbleIcon } = data;

    const a11yCloseText = messageDismiss?.accessibilityText || '';

    return (
        <EbayTrackingClick trackingList={dataTrack}>
            <EbayInfotip
                a11yCloseText={a11yCloseText}
                pointer={POINTER}
                variant={VARIANT}
                data-test-id={testId}
                aria-label={bubbleIcon?.accessibilityText}
                data-track={JSON.stringify(dataTrack)}
                onExpand={() => {
                    if (dataTrack && Array.isArray(dataTrack)) {
                        trackPulsar(dataTrack[0]);
                    }
                }}
            >
                <EbayInfotipContent data-test-id={TEST_IDS.flyout}>
                    {
                        messageText.map((entry, key) => (
                            <p key={key}>
                                <TextDisplay data={entry} testId={TEST_IDS.dialog} />
                            </p>
                        ))
                    }
                </EbayInfotipContent>
            </EbayInfotip>
        </EbayTrackingClick>
    );

}
