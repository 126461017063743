import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { getTrackingList, getUrl, isNavigationType, isOperationType, shouldOpenLinkInANewPage } from '../../utils/experience-service-types/action';
import { getActionTrackingData } from '../../utils/tracking-utils';
import { useAppMetaContextData } from '../../utils/app-meta-context';
import { TextSpansProps } from './types';


export function TextSpans({ textSpans, clippedText, doAction, testId, errMsgId, labelId, clientTrackingData, linkAriaHidden }: TextSpansProps) {
    const { appMeta, meta } = useAppMetaContextData();

    if (!textSpans?.length) {
        return null;
    }
    return (
        <span data-test-id={testId}>
            {textSpans.map((textSpan, index) => {
                let trackingList = clientTrackingData ? clientTrackingData : getTrackingList(textSpan.action);
                if (textSpan?.action && !trackingList) {
                    trackingList = getActionTrackingData({
                        action: textSpan?.action,
                        isGuest: appMeta?.isGuest,
                        shoppingCartId: meta?.shoppingCartId,
                        refActionName: testId?.toUpperCase()
                    });
                }

                if (isNavigationType(textSpan.action)) {
                    const target = shouldOpenLinkInANewPage(textSpan.action) ? '_blank' : undefined;
                    return (
                        <EbayTrackingClick key={index} trackingList={trackingList}>
                            <a href={getUrl(textSpan.action)} target={target} title={textSpan.accessibilityText} data-test-id={testId} data-track={JSON.stringify(trackingList)} aria-hidden={linkAriaHidden}>
                                {textSpan.text}
                            </a>
                        </EbayTrackingClick>
                    );
                } else if (isOperationType(textSpan.action)) {
                    return (
                        <EbayTrackingClick key={index} trackingList={trackingList}>
                            <a
                                href='#'
                                title={textSpan.accessibilityText}
                                data-test-id={testId}
                                data-track={JSON.stringify(trackingList)}
                                aria-hidden={linkAriaHidden}
                                onClick={(e) => {
                                    if (typeof doAction === 'function') {
                                        doAction(textSpan?.action);
                                    }
                                }}>
                                {textSpan.text}
                            </a>
                        </EbayTrackingClick>
                    );
                }
                const textStyleClass = textSpan.styles?.filter(style => style !== 'DEFAULT').join(' ');
                const a11yText = textSpan.accessibilityText || clippedText;

                if (a11yText) {
                    return (
                        <EbayTrackingClick key={index} trackingList={trackingList}>
                            <span className="clipped">{a11yText}</span>
                            <span className={textStyleClass} aria-hidden="true">
                                {textSpan.text}
                            </span>
                        </EbayTrackingClick>
                    );
                }

                return (
                    <EbayTrackingClick key={index} trackingList={trackingList}>
                        <span className={textStyleClass} id={errMsgId}>
                            {
                                labelId ? (
                                    <label htmlFor={labelId}>
                                        {textSpan.text}
                                    </label>
                                ) :
                                    textSpan.text
                            }
                        </span>
                    </EbayTrackingClick>
                );
            })}
        </span>
    );
}
