import { TEST_IDS } from '../../../../common/utils/test-ids';
import { UserAgreement } from './user-agreement';
import { UserAgreementsProps } from './types';

import './styles.less';

export function UserAgreements({ userAgreements = [] }: UserAgreementsProps) {
    if (!userAgreements?.length) {
        return null;
    }

    return (
        <div className="user-agreements" data-test-id={TEST_IDS.userAgreements}>
            {userAgreements.map((data, index) => ( <UserAgreement key={index} userAgreement={data} /> ))}
        </div>
    );
}
