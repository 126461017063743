export enum PaymentMethodsIcons {
    Amex = 'amex24Colored',
    ApplePay = 'applePay24Colored',
    Discover = 'discover24Colored',
    GooglePay = 'googlePay24Colored',
    MasterCard = 'mastercard24Colored',
    Paypal = 'paypal24Colored',
    Visa = 'visa24Colored',
    GenericCard = 'genericCard24Colored'
}
