import { useEffect, useRef, useState } from 'react';
import { addPageLoadEventListener } from '../../../utils/dom';
import {
    UseDynamicBannerHookProps
} from '../types';
import { buildDynamicBannerRequest } from '../utils/request-builder';
import { getDynamicBannerFromFGQL } from '../utils/fgql-dynamic-banner';
import { useAjax } from '../../../utils/ajax';
import { DynamicBannerData } from '../../../../../common/utils/hydration';
import { trackViewDynamicBanner } from '../utils/tracking';
import { shouldRenderBanner } from '../utils/render-utils';
import {
    dropXTTagsForFGQLDynamicBanner,
    getIsInFGQLDynamicBannerEP,
    getIsInFGQLDynamicBannerEPTreatment
} from '../utils/experimentation';
import { useFeatureFlagsData } from '../../../utils/experiments';

export function useDynamicBanner({ cartState }: UseDynamicBannerHookProps): DynamicBannerData {
    const makeAjaxCall = useAjax();
    const featureFlagsData = useFeatureFlagsData();
    const hasEmittedViewTracking = useRef<boolean>(false);
    const hasDroppedTags = useRef<boolean>(false);
    const [dynamicBanner, setDynamicBannerData] = useState<DynamicBannerData>(cartState?.fgql?.dynamicBanner || {});
    const trackingConfig = { isGuest: cartState?.appMeta?.isGuest, shoppingCartId: cartState?.meta?.shoppingCartId };
    const isInTreatment = getIsInFGQLDynamicBannerEPTreatment(featureFlagsData);

    const emitViewTrackingOnce = () => {
        if (!hasEmittedViewTracking.current) {
            trackViewDynamicBanner(trackingConfig);
            hasEmittedViewTracking.current = true;
        }
    };

    const dropBannerXtTagsOnce = () => {
        if (!hasDroppedTags.current) {
            dropXTTagsForFGQLDynamicBanner(featureFlagsData, cartState?.appMeta?.isGuest);
            hasDroppedTags.current = true;
        }
    };

    useEffect(() => {
        let ignore = false;
        const dynamicBannerRequest = buildDynamicBannerRequest(cartState);
        const getFGQLBannerData = async(ignore: boolean) => {
            if (!ignore && dynamicBannerRequest) {
                const fgqlBanner = await getDynamicBannerFromFGQL(makeAjaxCall, dynamicBannerRequest);
                // Drop EP Tags and Tracking if banner data is available.
                if (shouldRenderBanner(fgqlBanner)) {
                    dropBannerXtTagsOnce();
                    if (isInTreatment) {
                        emitViewTrackingOnce();
                    }
                }

                // We update the data on treatment even if we don't render the banner
                if (isInTreatment) {
                    setDynamicBannerData(fgqlBanner || {});
                }
            }
        };

        if (getIsInFGQLDynamicBannerEP(featureFlagsData)) {
            addPageLoadEventListener(() => {
                void getFGQLBannerData(ignore);
            });
        }
        return () => { ignore = true; };
    }, [cartState?.modules?.cartSummary, cartState?.modules?.cartDetails]);

    return dynamicBanner;
}
