import type { BulkActionsTitleProps } from './types';

export function BulkActionsTitle({
    title,
}: BulkActionsTitleProps) {

    if (!title) {
        return null;
    }

    return <h2 className='cart-bulk-actions__title'>{title}</h2>;

}