import { LineItemContextHookData, LineItemContextProviderProps } from '../types';
import { getTopModulesWithPrefix } from './utils';

export function useLineItem({ cartState, deviceMode }: LineItemContextProviderProps): LineItemContextHookData {
    const itemSpecificTopLevelModules = getTopModulesWithPrefix(cartState, 'itemSpecifics_');
    return {
        itemSpecificTopLevelModules,
        deviceMode
    };
}
