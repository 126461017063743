import { Suspense } from 'react';
import { useQuickViewContext } from '../../context';
import { QuickViewPopupLazy } from '../quick-view-popup-lazy';
import './quick-view.less';

export function QuickView() {
    const {
        shouldShowQuickViewPopup,
        getCurrentQuickViewData,
        setShouldShowQuickViewAction,
        closeQuickView
    } = useQuickViewContext();
    if (!shouldShowQuickViewPopup()) {
        return null;
    }

    const { listingId, variationId } = getCurrentQuickViewData() || {};
    return (
        <Suspense>
            <QuickViewPopupLazy
                isOpen={!!listingId}
                listingId={listingId}
                onClose={closeQuickView}
                onRender={setShouldShowQuickViewAction}
                variationId={variationId}
            />
        </Suspense>
    );
}
