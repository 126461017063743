import { MessageType, Notification } from '../../../../../common/utils/hydration';

export function createFatalErrorAlert(genericErrorMessage: string): Notification[] {
    return [
        {
            messageType: MessageType.Error,
            message: [
                {
                    textSpans: [
                        {
                            text: genericErrorMessage
                        }
                    ]
                }
            ],
            accessibilitySeverity: 'Error'
        }
    ];
}
