import classNames from 'classnames';
import { TextualDisplayWithInfoBubble } from '../../../../../common/utils/hydration';
import { TextDisplayWithInfo } from '../../../../common/components/text-display-with-info';
import { InlineSilhouette } from '../../../../common/components/inline-silhouette/inline-silhouette';
import { SilhouetteTypes } from '../../../../common/components/inline-silhouette/types';
import { useBulkActionsContext } from '../../../../common/components/bulk-actions/context/context';
import { CartSummaryLineItemProps } from './types';

import './styles.less';

/* This method is to split the items count coming from service response in compactCartSummary field
   In case of bulk xo, as items count will be dynamic , we can not use the fixed count from response */
export const buildLabelWithoutItemCount = (itemLabelWithItemCount: TextualDisplayWithInfoBubble) => {
    const summaryLabel = itemLabelWithItemCount.textSpans?.[0]?.text;

    return summaryLabel ? summaryLabel.replace(/\(.*?\)/g, '') : '';
};

export function CartSummaryLineItem({ cartSummaryItem, tdClass, quantity, bulkXoOrderTotal, shouldSplitItemsCountFromLabel, showItemDiscountsPricelinesLoadingState, showItemPricelinesLoadingState }: CartSummaryLineItemProps) {
    const bulkActionsContext = useBulkActionsContext();
    const { pricingCostSummary = {}, costSummary = {}, cartBulkXoEnabled } = bulkActionsContext;
    if (!cartSummaryItem) {
        return null;
    }

    let showItemTotalVals = null;
    const itemPricelinesLoadingState = showItemDiscountsPricelinesLoadingState;
    if (cartBulkXoEnabled) {
        if (cartSummaryItem?.type === 'DISCOUNTS' || cartSummaryItem?.type === 'ITEM_TOTAL') {
            if (showItemDiscountsPricelinesLoadingState) {
                showItemTotalVals = (<InlineSilhouette type={SilhouetteTypes.Text} />);
            } else {
                showItemTotalVals = pricingCostSummary?.totalAppliedPromotions ? pricingCostSummary?.totalAppliedPromotions : (<TextDisplayWithInfo data={cartSummaryItem.value} />);
                if (cartSummaryItem?.type === 'DISCOUNTS') {
                    showItemTotalVals = pricingCostSummary?.totalAppliedPromotions;
                } else if (cartSummaryItem?.type === 'ITEM_TOTAL') {
                    showItemTotalVals = pricingCostSummary?.itemSubtotalPrice;
                } else {
                    showItemTotalVals = (<TextDisplayWithInfo data={cartSummaryItem.value} />);
                }
            }
        } else {
            if (showItemPricelinesLoadingState) {
                showItemTotalVals = (<InlineSilhouette type={SilhouetteTypes.Text} />);
            } else {
                showItemTotalVals = <TextDisplayWithInfo data={cartSummaryItem.value} />;
                if (Object.keys(costSummary).length) {
                    if (cartSummaryItem?.type === 'ITEM_TOTAL') {
                        showItemTotalVals = costSummary?.itemSubtotalPrice;
                    } else if (cartSummaryItem?.type === 'DISCOUNTS') {
                        showItemTotalVals = costSummary?.totalAppliedPromotions;
                    } else if (cartSummaryItem?.type === 'SUBTOTAL') {
                        showItemTotalVals = costSummary?.totalPrice;
                    } else if (cartSummaryItem?.type === 'SHIPPING') {
                        showItemTotalVals = costSummary?.shippingPrice;
                    } else {
                        showItemTotalVals = (<TextDisplayWithInfo data={cartSummaryItem.value} />);
                    }
                }
            }
        }
    }

    if (!showItemTotalVals) {
        showItemTotalVals = (<TextDisplayWithInfo data={cartSummaryItem.value} />);
    }

    let itemLabelWithoutItemCount = (<TextDisplayWithInfo data={cartSummaryItem.label} />);
    if (cartSummaryItem?.label && cartSummaryItem?.type === 'ITEM_TOTAL' && quantity) {
        itemLabelWithoutItemCount = (
            <>
                { buildLabelWithoutItemCount(cartSummaryItem?.label) }
                <span> ({quantity}) </span>
            </>

        );
    }

    return (
        <>
            <div className="cart-summary-line-item">
                <div className={tdClass}>
                    { itemLabelWithoutItemCount }
                </div>
                <div className={classNames('val-col', tdClass)} data-test-id={cartSummaryItem.type}>
                    {showItemTotalVals}
                </div>
            </div>
            {
                !itemPricelinesLoadingState && cartSummaryItem?.secondaryValue ? (
                    <div className="cart-summary-line-item cart-summary-secondary-value">
                        <TextDisplayWithInfo data={cartSummaryItem.secondaryValue} />
                    </div>
                ) : null
            }

        </>
    );
}