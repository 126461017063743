import { forwardRef, Ref } from 'react';
import { isNavigationType, isOperationType } from '../../utils/experience-service-types/action';
import { TextSpans } from '../text-spans';
import { useAppMetaContextData } from '../../utils/app-meta-context';
import { getActionTrackingData } from '../../utils/tracking-utils';
import { NavigationTextDisplay } from './navigation-text-display';
import { OperationTextDisplay } from './operation-text-display';
import type { TextDisplayProps } from './types';

import './styles.less';

export const TextDisplay = forwardRef(({
    data,
    disabled,
    doAction,
    className,
    variant,
    fluid,
    openNewWindow,
    priority,
    testId,
    labelId,
    lineItemId,
    onClick,
    errMsgId,
    infoBubble,
    itemId,
    sellerId,
    linkAriaHidden,
}: TextDisplayProps, ref?: Ref<HTMLButtonElement>) => {
    const { appMeta, meta } = useAppMetaContextData();

    if (!data) {
        return null;
    }

    const { action } = data;
    const clientTrackingData = getActionTrackingData({
        action,
        itemId: itemId || labelId,
        itemReferenceId: lineItemId,
        sellerId: sellerId,
        isGuest: appMeta?.isGuest,
        shoppingCartId: meta?.shoppingCartId,
        refActionName: (testId?.toUpperCase().startsWith('SFL')) && action && action.name
            ? `SFL_${action?.name}`
            : testId
    });

    let content = (
        <TextSpans
            textSpans={data.textSpans}
            clippedText={data.accessibilityText}
            doAction={doAction}
            testId={testId}
            labelId={labelId}
            errMsgId={errMsgId}
            clientTrackingData={action && clientTrackingData}
            linkAriaHidden={linkAriaHidden}/>
    );

    if (isNavigationType(data.action)) {
        content = (
            <NavigationTextDisplay
                data={data}
                disabled={disabled}
                className={className}
                variant={variant}
                fluid={fluid}
                openNewWindow={openNewWindow}
                testId={testId}
                labelId={labelId}
                lineItemId={lineItemId}
                clientTrackingData={clientTrackingData}
                onClick={onClick}
                priority={priority}
            />
        );
    } else if (isOperationType(data.action)) {
        content = (
            <OperationTextDisplay
                data={data}
                disabled={disabled}
                className={className}
                variant={variant}
                priority={priority}
                testId={testId}
                labelId={labelId}
                lineItemId={lineItemId}
                onClick={onClick}
                ref={ref}
                clientTrackingData={clientTrackingData}
            />
        );
    }

    const classContainer = infoBubble ? 'text-display-with-info' : 'text-display-span';
    return (
        <span className={classContainer} data-click={infoBubble && clientTrackingData && JSON.stringify(clientTrackingData[0])}>
            {content}
            {infoBubble}
        </span>
    );

});

TextDisplay.displayName = 'TextDisplay';
