import { Action } from '@ebay/experience-types-ebay';
import { Fragment } from 'react';
import { VerticalLine } from '../../../vertical-line';
import { TextDisplay } from '../../../text-display';
import { TextDisplayWithInfo } from '../../../text-display-with-info';
import { useBulkActionsContext } from '../../../bulk-actions/context';
import { shouldNotRenderLineItemActions } from '../../../bulk-actions/utils';
import { LineItemActionsProps } from './types';
import './styles.less';

const FAKE_LINK = 'fake-link';

export function LineItemActions({ actions, onAction }: LineItemActionsProps) {
    const bulkActionsContext = useBulkActionsContext();
    const shouldNotRenderActions = shouldNotRenderLineItemActions(bulkActionsContext);

    if (!actions || shouldNotRenderActions) {
        return null;
    }

    function handleAction(action?: Action) {
        if (onAction) {
            onAction(action);
        }
    }

    return (
        <div className='line-item-foot'>
            {
                actions.filter(Boolean).map((action, index: number) => (
                    <Fragment key={index}>
                        { index > 0 && <VerticalLine/> }
                        { action?.data?._type === 'TextualDisplayWithInfoBubble' && (
                            <TextDisplayWithInfo
                                onClick={(event, savedForLaterAction) => handleAction(action?.data?.action)}
                                data={action?.data}
                                variant={FAKE_LINK}
                                testId={action?.testId}
                            />
                        )}
                        { action?.data?._type === 'TextualDisplay' && (
                            <TextDisplay
                                onClick={(event, savedForLaterAction) => handleAction(action?.data?.action)}
                                data={action?.data}
                                variant={FAKE_LINK}
                                testId={action?.testId}
                            />
                        )}
                    </Fragment>
                ))
            }
        </div>
    );
}