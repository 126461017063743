import { Icon } from '@ebay/experience-types-ebay';
import { Adjustment } from '../../../../../../../common/utils/hydration';
import { I18nHook } from '../../../../../../common/utils/i18n/types';

export function getIconName(icon: Icon): string | undefined {
    switch (icon?.name) {
        case 'INFORMATION_FILLED_SMALL':
            return 'informationFilled16';
        case 'PROMOTION_SMALL':
            return 'promotion16';
        case 'CONFIRMATION_SMALL':
        case 'CONFIRMATION_FILLED_SMALL':
            return 'confirmationFilled16';
        default:
            return '';
    }
}

export function getItemGroupingA11yContent(adjustment?: Adjustment, lineItemCount = 0, content?: I18nHook): string | undefined {
    const itemGroupContentBundle = content?.getCartItemGroupingA11y();
    const isApplied = adjustment?.applied;

    if (!adjustment) {
        return itemGroupContentBundle?.itemNotEligibleForDiscount;
    }

    if (isApplied) {
        if (lineItemCount > 1) {
            return itemGroupContentBundle?.itemsWithDiscountApplied;
        } else if (lineItemCount === 1) {
            return itemGroupContentBundle?.itemWithDiscountApplied;
        }
    } else {
        if (lineItemCount > 1) {
            return itemGroupContentBundle?.itemsEligibleForDiscount;
        } else if (lineItemCount === 1) {
            return itemGroupContentBundle?.itemEligibleForDiscount;
        }
    }

    return itemGroupContentBundle?.itemNotEligibleForDiscount;
}
