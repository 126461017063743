import { createContext, useContext } from 'react';
import { UseFocusOnActionHookData, UseFocusOnActionContextProviderProps } from './types';
import { useFocusOnAction } from './hooks/use-focus-on-action';

export const FocusOnActionContext = createContext<UseFocusOnActionHookData>({} as UseFocusOnActionHookData);

export function FocusOnActionContextProvider({ cartState, children }: UseFocusOnActionContextProviderProps) {
    const focusOnActionState = useFocusOnAction({ cartState });
    return (
        <FocusOnActionContext.Provider value={focusOnActionState}>
            {children}
        </FocusOnActionContext.Provider>
    );
}
export const useFocusOnActionContext = (): UseFocusOnActionHookData => {
    const focusOnActionState = useContext(FocusOnActionContext);
    return focusOnActionState;
};
