import { createContext, ReactElement } from 'react';
import { CartClientState } from '../../../../common/utils/hydration';
import { AjaxContextType, AjaxProviderProps } from './types';

export const AjaxContext = createContext<AjaxContextType>({});

export const getAjaxContext = (initialState?: CartClientState): AjaxContextType => ({
    srt: initialState?.sessionToken,
    timeout: initialState?.appConfig?.ajaxTimeout
});

export function AjaxProvider({ initialState, children }: AjaxProviderProps): ReactElement {
    const data = getAjaxContext(initialState);

    return (
        <AjaxContext.Provider value={data}>
            { children }
        </AjaxContext.Provider>
    );
}