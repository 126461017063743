import { EbayIcon } from '@ebay/ui-core-react/ebay-icon';
import { EbayInlineNotice, EbayNoticeContent } from '@ebay/ui-core-react/ebay-inline-notice';
import { EbayPageNotice, EbayPageNoticeFooter } from '@ebay/ui-core-react/ebay-page-notice';
import { TEST_IDS } from '../../utils/test-ids';
import { AlertContent } from './components/alert-content/alert-content';
import { getAlertStatus } from './domain/specifications';
import { AlertLevel, AlertsProps } from './types';

export function Alerts({ notifications, errorState, level }: AlertsProps) {
    if (!notifications?.length) {
        return null;
    }

    return (
        <>
            {notifications?.map((notification, index) => {
                if (level === AlertLevel.Inline) {
                    return (
                        <EbayInlineNotice
                            key={index}
                            status={getAlertStatus(notification)}
                            aria-label={notification.accessibilitySeverity || ''}
                            data-test-id={TEST_IDS.inlineAlerts}
                            role="region"
                            aria-live="polite">
                            <EbayNoticeContent>
                                <AlertContent
                                    level={level}
                                    notification={notification}
                                    errorState={errorState}
                                />
                            </EbayNoticeContent>
                        </EbayInlineNotice>
                    );
                }

                return (
                    <EbayPageNotice
                        aria-label={notification.accessibilitySeverity || ''}
                        data-test-id={TEST_IDS.pageAlerts}
                        key={index}
                        status={getAlertStatus(notification)}>
                        <EbayNoticeContent>
                            <AlertContent
                                level={level}
                                notification={notification}
                                errorState={errorState} />
                        </EbayNoticeContent>

                        {notification.action && (
                            <EbayPageNoticeFooter>
                                <a href={notification.action.URL} tabIndex={-1} aria-hidden="true">
                                    <EbayIcon name="chevronRight16" />
                                </a>
                            </EbayPageNoticeFooter>
                        )}
                    </EbayPageNotice>
                );
            })}
        </>
    );
}
