import { EbayIcon } from '@ebay/ui-core-react/ebay-icon';
import { useEffect, useState } from 'react';
import { InlineSilhouette, SilhouetteTypes } from '../../../pres/common/components/inline-silhouette';
import { AdsListings } from '../ads';
import { I18nHook } from '../../common/utils/i18n/types';
import { AdsPlacementId, AppMeta } from '../../../common/utils/hydration';
import { useAppMetaContextData } from '../../common/utils/app-meta-context';
import { useI18n } from '../../common/utils/i18n';
import { EmptyCartActions } from '../empty-cart/components/empty-cart-actions';
import { useFeatureFlagsData } from '../../common/utils/experiments/hooks';
import { showRecommendationsFirstOnEmptyCart } from '../../utils/experimentation-utils';
import { EmptyCartWithWatchlistProps } from './types';

import './styles.less';

function getEmptyCartSecondaryMessages(i18n: I18nHook, appMeta: AppMeta, isWatchlistPresent: boolean, isRecommendationsPresent: boolean): Array<string> {
    const secondaryMessages: Array<string> = [];

    if (isRecommendationsPresent && isWatchlistPresent) {
        secondaryMessages.push(i18n?.getEmptyCartWithWatchlist?.()?.emptyCartMemberPrimaryText);
    }
    else if (!isRecommendationsPresent && isWatchlistPresent) {
        secondaryMessages.push(i18n?.getEmptyCartWithWatchlist?.()?.emptyCartMemberSecondaryText);
    }
    else if (isRecommendationsPresent && !isWatchlistPresent) {
        secondaryMessages.push(i18n?.getEmptyCartWithWatchlist?.()?.emptyCartGuestMainText);
    }

    if (appMeta?.isGuest) {
        secondaryMessages.push(i18n?.getEmptyCartWithWatchlist?.()?.emptyCartguestSecondaryText);
    }

    return secondaryMessages;
}


export function EmptyCartWithWatchlist( { emptyCart }: EmptyCartWithWatchlistProps) {
    const i18n = useI18n();
    const featureFlagData = useFeatureFlagsData();
    const { appMeta } = useAppMetaContextData();
    const recommendationsFirstOnEmptyCart = showRecommendationsFirstOnEmptyCart(featureFlagData);

    const [secondaryMessages, setSecondaryMessages] = useState<Array<string>>([]);
    const [isWatchlistPresent, setIsWatchlistPresent] = useState(false);
    const [isRecommendationsPresent, setIsRecommendationsPresent] = useState(false);
    const [merchCallbackCount, setMerchCallbackCount] = useState(0);

    const watchlistPlacementId = appMeta?.isMobile ? AdsPlacementId.MobileWatchlist : AdsPlacementId.DesktopWatchlist;
    const recommendationsPlacementId = appMeta?.isMobile ? AdsPlacementId.MobileRecommendation : AdsPlacementId.DeskopRecommendation;

    const onWatchlistRender = (data?: any) => {
        setMerchCallbackCount(merchCallback => merchCallback + 1);
        setIsWatchlistPresent(Boolean(data));
    };

    const onRecommendationsRender = (data?: any) => {
        setMerchCallbackCount(merchCallback => merchCallback + 1);
        setIsRecommendationsPresent(Boolean(data));
    };

    useEffect(() => {
        if (merchCallbackCount >= 2) {
            // Update secondary messages based on the response of watchlist and/or recommendations render event
            const updatedSecondaryMessages = getEmptyCartSecondaryMessages(i18n, appMeta as AppMeta, isWatchlistPresent, isRecommendationsPresent);
            setSecondaryMessages(updatedSecondaryMessages);
        }
    }, [merchCallbackCount]);

    if (!emptyCart) {
        return null;
    }

    return (
        <div className='empty-cart empty-cart-with-watchlist'>
            <div className='empty-cart-icon'>
                <EbayIcon name='cart64'/>
            </div>
            {
                <div className='primary-message'>
                    {i18n?.getEmptyCartWithWatchlist?.()?.emptyCartHeading}
                </div>
            }

            {merchCallbackCount < 2 && (
                <div className='empty-cart-merch-content-silhouette'>
                    <InlineSilhouette type={SilhouetteTypes.Text} />
                </div>
            )}

            {secondaryMessages?.length > 0 && (
                <div className='secondary-messages'>
                    {secondaryMessages?.map((mess, index) =>
                        <div key ={index}>{mess}</div>)}
                </div>
            )}

            {
                <EmptyCartActions actions={emptyCart?.actionMap} />
            }

            <div className='empty-cart-merch'>
                {
                    recommendationsFirstOnEmptyCart ?
                        (
                            <>
                                <AdsListings placementId={recommendationsPlacementId}
                                    renderWithoutAdsData
                                    onRender={onRecommendationsRender}
                                />
                                <AdsListings placementId={watchlistPlacementId}
                                    renderWithoutAdsData
                                    onRender={onWatchlistRender}
                                />
                            </>
                        )
                        :
                        (
                            <>
                                <AdsListings placementId={watchlistPlacementId}
                                    renderWithoutAdsData
                                    onRender={onWatchlistRender}
                                />
                                <AdsListings placementId={recommendationsPlacementId}
                                    renderWithoutAdsData
                                    onRender={onRecommendationsRender}
                                />
                            </>
                        )
                }

            </div>

        </div>
    );
}