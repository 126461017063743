import { useContext, useCallback } from 'react';
import { AjaxContext } from './context';
import { makeAjaxCall } from './ajax';
import { AjaxContextType, AjaxCallMaker } from './types';

export function useAjax<RequestData>(): AjaxCallMaker<RequestData> {
    const { srt, timeout }: AjaxContextType = useContext(AjaxContext);
    return useCallback((url: string, data?: RequestData, logging?: boolean, reloadOnFailure?: boolean, httpRequestObject?: XMLHttpRequest) => {
        return makeAjaxCall({ url, data, srt, timeout, logging, reloadOnFailure, httpRequestObject });
    }, [srt, timeout]);

}