import { Action, TextualDisplay } from '@ebay/experience-types-ebay';
import { TextualDisplayWithInfoBubble } from '../../../../../../common/utils/hydration';

export enum LineItemActionType {
    AddBackToCart = 'ADD_BACK_TO_CART',
    BuyItNow = 'BUY_IT_NOW',
    EditQuantity = 'EDIT_QUANTITY',
    GoToSimilarItems = 'GO_TO_SIMILAR_ITEMS',
    RemoveItem = 'REMOVE_ITEM',
    SaveForLater = 'SAVE_FOR_LATER'
}

export type TextualDisplayActionData = TextualDisplay | TextualDisplayWithInfoBubble;

export interface LineItemActionsProps {
    actions?: ActionFromLineItemDataType[];
    onAction?: (action?: Action) => void;
}

export type ActionFromLineItemDataType = {
    data?: TextualDisplayActionData;
    testId?: string;
} | undefined;