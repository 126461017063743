import classNames from 'classnames';
import { SellerInformation } from '../../common/components/seller-information';
import { SpinnerContainer } from '../../common/components/spinner';
import { TEST_IDS, TEST_INFO_KEYS } from '../../common/utils/test-ids';
import { useBulkActionsContext } from '../../common/components/bulk-actions/context';
import { useFeatureFlagsData } from '../../common/utils/experiments/hooks';
import { useI18n } from '../../common/utils/i18n';
import { shouldNotRenderLineItemActions } from '../../common/components/bulk-actions/utils';
import { SellerBadge } from '../../common/components/seller-badge';
import { ActionsMenu } from './components/actions-menu';
import { Adjustments } from './components/adjustments';
import { CartBucketLineItem } from './components/line-item';
import { CartBucketLineItemGroup } from './components/line-item/cart-bucket-line-item-grouping';
import { createMenuActions } from './domain/factories';
import { getItemGroupingA11yContent } from './components/adjustments/group-adjustment/domain/specifications';
import type { CartBucketProps } from './types';

import './styles.less';


export function CartBucket({ sellerBucket, onBuyItNow, onQuantityUpdate, onPayOnlyThisSeller, onSavedForLater, onRemoveItem, firstCartBucket }: CartBucketProps) {
    const bulkActionContext = useBulkActionsContext();
    const featureFlagsData = useFeatureFlagsData();
    const i18n = useI18n();

    if (!sellerBucket?.lineItems?.length) {
        return null;
    }
    const shouldNotRenderAction = shouldNotRenderLineItemActions(bulkActionContext);
    const menu = createMenuActions(sellerBucket);
    const sellerId = sellerBucket.seller?.sellerName?.textSpans?.[0]?.text;
    const cartBucketInfo = JSON.stringify({
        [TEST_INFO_KEYS.sellerId]: sellerId
    });

    const showSellerInfoBadge = sellerBucket?.seller?.logo && sellerBucket?.seller?.sellerName;
    const showItemGrouping = featureFlagsData?.enableItemGrouping && Array.isArray(sellerBucket?.lineItemGrouping) && sellerBucket?.lineItemGrouping.length > 0;

    return (
        <div
            className={classNames('cart-bucket', sellerBucket.disabled && 'cart-bucket--disabled', showItemGrouping && 'cart-bucket-with-group')}
            data-test-id={TEST_IDS.cartBucket}
            data-test-info={cartBucketInfo}>
            <SpinnerContainer>
                <div className="cart-bucket-head">
                    {
                        showSellerInfoBadge ? (
                            <SellerBadge data={sellerBucket?.seller} sellerId={sellerId}/>
                        ) : <SellerInformation data={sellerBucket.seller} sellerId={sellerId}/>
                    }

                    {menu && !shouldNotRenderAction && (
                        <ActionsMenu
                            title={menu.title}
                            actions={menu.actions}
                            cancelAction={menu.cancelAction}
                            onPayOnlyThisSeller={onPayOnlyThisSeller}
                            sellerId={sellerId}
                        />
                    )}
                </div>

                {
                    !showItemGrouping && (
                        <ul className="cart-bucket__vendor-list">
                            {sellerBucket.lineItems.map((lineItem, index) => (
                                <li key={lineItem.lineItemId}>
                                    <CartBucketLineItem
                                        lineItem={lineItem}
                                        disabledBucket={sellerBucket.disabled}
                                        onBuyItNow={onBuyItNow}
                                        onQuantityUpdate={onQuantityUpdate}
                                        onRemoveItem={onRemoveItem}
                                        onSavedForLater={onSavedForLater}
                                        sellerId={sellerId}
                                        firstLineItem={firstCartBucket && index === 0}
                                        seller={sellerBucket?.seller}
                                    />
                                </li>
                            ))}
                        </ul>
                    )
                }
                {
                    showItemGrouping && sellerBucket?.lineItemGrouping?.map((lineItemGroup, index) => {
                        const groupingA11yText = getItemGroupingA11yContent(
                            lineItemGroup?.adjustment,
                            lineItemGroup?.lineItemIds?.length,
                            i18n);
                        return (
                            <div key={index} className={classNames('cart-bucket-lineitem-grouping')} aria-label={groupingA11yText}>
                                <CartBucketLineItemGroup
                                    lineItemGrouping={lineItemGroup}
                                    lineItems={sellerBucket?.lineItems}
                                    disabledBucket={sellerBucket.disabled}
                                    onBuyItNow={onBuyItNow}
                                    onQuantityUpdate={onQuantityUpdate}
                                    onRemoveItem={onRemoveItem}
                                    onSavedForLater={onSavedForLater}
                                    sellerId={sellerId}
                                    firstLineItem={firstCartBucket && index === 0}
                                    seller={sellerBucket?.seller}
                                />
                            </div>
                        );
                    })
                }

                <Adjustments adjustments={sellerBucket.adjustments} />
            </SpinnerContainer>
        </div>
    );
}
