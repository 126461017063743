import { LineItem } from '../../../../../common/utils/hydration';
import { ActionFromLineItemDataType, LineItemActionType } from '../../../../common/components/grid-line-item/components/line-item-actions';
import { TEST_IDS } from '../../../../common/utils/test-ids';

export function extractActionsFromLineItemCart(lineItem: LineItem): ActionFromLineItemDataType[] {
    let outputActions: ActionFromLineItemDataType[] = [];
    const moduleActions = lineItem?.listingSummaries?.[0]?.__sc?.actions;
    const saveForLaterData = moduleActions?.[LineItemActionType.SaveForLater] && {
        data: moduleActions?.[LineItemActionType.SaveForLater],
        testId: TEST_IDS.cartToSavedForLater
    };
    const removeItemData = moduleActions?.[LineItemActionType.RemoveItem] && {
        data: moduleActions?.[LineItemActionType.RemoveItem],
        testId: TEST_IDS.cartRemoveItem
    };
    const buyItNowItemData = moduleActions?.[LineItemActionType.BuyItNow] && {
        data: moduleActions?.[LineItemActionType.BuyItNow],
        testId: TEST_IDS.buyItNow
    };
    outputActions = [buyItNowItemData, saveForLaterData, removeItemData];
    return outputActions;
}

export function isLineItemDisabled(lineItem: LineItem | undefined): boolean {
    return !!lineItem?.listingSummaries?.[0]?.__sc?.disabled;
}
