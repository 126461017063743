import { memo } from 'react';
import { EbayAdsPlacement } from '@ebay/nuts-ui-react';
import { useEffect, useState } from 'react';
import { addWindowLoadEvent, removeWindowLoadEvent } from '../../common/utils/dom';
import { useAjax } from '../../common/utils/ajax';
import { AdsData } from '../../../common/utils/hydration';
import { Loading } from './ads-loading-state';
import { fetchAds } from './domain/services';
import { AdsErrorBoundary } from './ads-error-boundary';
import type { AdsListingsProps } from './types';
import type { HtmlModule } from '@ebay/nuts-js-client/src/server';

import './styles.less';

export const AdsListings = memo(({ adsData, placementId, onRender, renderWithoutAdsData, _adsListingsForStorybook }: AdsListingsProps) => {
    const makeAjaxCall = useAjax<AdsData>();
    const [isLoading, setIsLoading] = useState(!_adsListingsForStorybook);
    const [adsListings, setAdsListings] = useState<HtmlModule | undefined | null>(_adsListingsForStorybook);
    const showListings = adsData?.listingIds?.length || renderWithoutAdsData;

    const onComplete = (data?: any) => {
        if (typeof onRender == 'function') {
            onRender(data);
        }
    };

    useEffect(() => {
        if (!placementId || _adsListingsForStorybook || !showListings) {
            return;
        }

        setIsLoading(true);

        const windowLoadHandler = () => {
            void fetchAds(makeAjaxCall, {
                ...adsData,
                placementId
            })
                .then((data) => {
                    setAdsListings(data);
                    onComplete(data);
                })
                .then(() => {
                    setIsLoading(false);
                });
        };

        addWindowLoadEvent(windowLoadHandler);

        return () => {
            removeWindowLoadEvent(windowLoadHandler);
        };
    }, [placementId, adsData, makeAjaxCall, _adsListingsForStorybook]);


    if (!placementId || !showListings || (!isLoading && !adsListings)) {
        return null;
    }

    return (
        <AdsErrorBoundary>
            <div className="merch-container">
                {isLoading ? <Loading /> : (
                    adsListings && (
                        <EbayAdsPlacement
                            module={adsListings}
                            placementId={placementId}
                        />
                    )
                )}
            </div>
        </AdsErrorBoundary>
    );
}, (preProps, nextProps) => {
    // Update the ads only when the listing ids list changed the amount of items
    return preProps.adsData?.listingIds?.length === nextProps.adsData?.listingIds?.length;
});

AdsListings.displayName = 'AdsListings';
