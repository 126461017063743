import { createContext } from 'react';
import { DeviceTypeData } from '../../../../common/utils/hydration/types';
import { DeviceTypeProps } from './types';

export const DeviceTypeContext = createContext<DeviceTypeData>({});

export function DeviceTypeProvider({ children, deviceType }: DeviceTypeProps) {
    return (
        <DeviceTypeContext.Provider value={{ deviceType }}>
            {children}
        </DeviceTypeContext.Provider>
    );
}
