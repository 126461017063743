// Client cart Tracking PageId
export const MEMBER_CART_PAGE_ID = 4451299;
export const GUEST_CART_PAGE_ID = 4465166;

export const TRACKING_DATA_MAPPER = {
    BANNER_CARD: 'BANNER_CARD',
    BUY_IT_NOW: 'BUY_IT_NOW',
    CART_EMBG_LEARN_MORE: 'CART_EMBG_LEARN_MORE',
    CHECK_OUT_CART: 'CHECK_OUT_CART',
    CHECK_OUT_CART_BOTTOM: 'CHECK_OUT_CART_BOTTOM',
    PAY_ONLY_THIS_SELLER: 'PAY_ONLY_THIS_SELLER',
    CONTINUE_SHOPPING: 'CONTINUE_SHOPPING',
    EDIT_QUANTITY: 'EDIT_QUANTITY',
    EMPTY_CART_SIGN_IN: 'EMPTY_CART_SIGN_IN',
    GO_TO_SIMILAR_ITEMS: 'GO_TO_SIMILAR_ITEMS',
    GO_TO_SURVEY: 'GO_TO_SURVEY',
    MORE_OPTIONS: 'MORE_OPTIONS',
    CLOSE_ACTION_MENU: 'MORE_OPTIONS_CANCEL',
    REDIRECT_TO_CHECKOUT: 'REDIRECT_TO_CHECKOUT',
    REDIRECT_TO_CHECKOUT_BOTTOM: 'REDIRECT_TO_CHECKOUT_BOTTOM',
    REMOVE_ITEM: 'REMOVE_ITEM',
    REQUEST_TOTAL: 'REQUEST_TOTAL',
    REQUEST_TOTAL_INFO: 'REQUEST_TOTAL_INFO',
    SAVE_FOR_LATER: 'SAVE_FOR_LATER',
    VIEW_ITEM: 'VIEW_ITEM',
    SELLER_LANDING_PAGE: 'SELLER_LANDING_PAGE',
    SFL_ADD_BACK_TO_CART: 'SFL_ADD_BACK_TO_CART',
    SFL_GO_TO_SIMILAR_ITEMS: 'SFL_GO_TO_SIMILAR_ITEMS',
    SFL_REMOVE_ITEM: 'SFL_REMOVE_ITEM',
    SFL_SELLER_LANDING_PAGE: 'SFL_SELLER_LANDING_PAGE',
    SFL_VIEW_ITEM: 'SFL_VIEW_ITEM',
    SIGN_IN: 'SIGN_IN',
    VIEW_OFFER_ELIGIBLE_ITEMS: 'VIEW_OFFER_ELIGIBLE_ITEMS',
    CONTINUE_TO_SIGN_IN: 'CONTINUE_TO_SIGN_IN',
    CONTINUE_AS_GUEST: 'CONTINUE_AS_GUEST',
    OPEN_QUICK_VIEW: 'OPEN_QUICK_VIEW',
    CLOSE_QUICK_VIEW: 'CLOSE_QUICK_VIEW',
    DYNAMIC_BANNER_EBAY_BALANCE_LEARN_MORE: 'DYNAMIC_BANNER_EBAY_BALANCE_LEARN_MORE',
    DYNAMIC_BANNER_VIEW: 'DYNAMIC_BANNER_VIEW',
};

export const DEFAULT_CLICK_TRACKING_DATA = {
    eventFamily: 'CART',
    eventAction: 'ACTN',
    flushImmediately: false,
};

export const MODULE_TRACKING_DATA = {
    [TRACKING_DATA_MAPPER.BANNER_CARD]: {
        mid: 48394,
        lid: 59047
    },
    [TRACKING_DATA_MAPPER.BUY_IT_NOW]: {
        mid: 152686,
        lid: 176520
    },
    [TRACKING_DATA_MAPPER.CART_EMBG_LEARN_MORE]: {
        mid: 152688,
        lid: 162850
    },
    [TRACKING_DATA_MAPPER.CHECK_OUT_CART]: {
        mid: 152688,
        lid: 162377
    },
    [TRACKING_DATA_MAPPER.CHECK_OUT_CART_BOTTOM]: {
        mid: 152688,
        lid: 165170
    },
    [TRACKING_DATA_MAPPER.CONTINUE_SHOPPING]: {
        mid: 152688,
        lid: 165130
    },
    [TRACKING_DATA_MAPPER.EDIT_QUANTITY]: {
        mid: 152686,
        lid: 166857
    },
    [TRACKING_DATA_MAPPER.EMPTY_CART_SIGN_IN]: {
        mid: 164498,
        lid: 178339
    },
    [TRACKING_DATA_MAPPER.GO_TO_SIMILAR_ITEMS]: {
        mid: 152686,
        lid: 172477
    },
    [TRACKING_DATA_MAPPER.GO_TO_SURVEY]: {
        mid: 152908,
        lid: 166862
    },
    [TRACKING_DATA_MAPPER.MORE_OPTIONS]: {
        mid: 152686,
        lid: 167941
    },
    [TRACKING_DATA_MAPPER.CLOSE_ACTION_MENU]: {
        mid: 152686,
        lid: 167942
    },
    [TRACKING_DATA_MAPPER.PAY_ONLY_THIS_SELLER]: {
        mid: 152686,
        lid: 162378
    },
    [TRACKING_DATA_MAPPER.REDIRECT_TO_CHECKOUT]: {
        mid: 152688,
        lid: 162377
    },
    [TRACKING_DATA_MAPPER.REDIRECT_TO_CHECKOUT_BOTTOM]: {
        mid: 152688,
        lid: 165170
    },
    [TRACKING_DATA_MAPPER.REMOVE_ITEM]: {
        mid: 152686,
        lid: 164874
    },
    [TRACKING_DATA_MAPPER.REQUEST_TOTAL]: {
        mid: 152686,
        lid: 166860
    },
    [TRACKING_DATA_MAPPER.REQUEST_TOTAL_INFO]: {
        mid: 152686,
        lid: 166861
    },
    [TRACKING_DATA_MAPPER.SAVE_FOR_LATER]: {
        mid: 152686,
        lid: 164872
    },
    [TRACKING_DATA_MAPPER.SELLER_LANDING_PAGE]: {
        mid: 152686,
        lid: 166859
    },
    [TRACKING_DATA_MAPPER.SIGN_IN]: {
        mid: 152690,
        lid: 178339
    },
    [TRACKING_DATA_MAPPER.VIEW_ITEM]: {
        mid: 152686,
        lid: 164875
    },
    [TRACKING_DATA_MAPPER.SFL_ADD_BACK_TO_CART]: {
        mid: 152687,
        lid: 164873
    },
    [TRACKING_DATA_MAPPER.SFL_GO_TO_SIMILAR_ITEMS]: {
        mid: 152687,
        lid: 172477
    },
    [TRACKING_DATA_MAPPER.SFL_REMOVE_ITEM]: {
        mid: 152687,
        lid: 164874
    },
    [TRACKING_DATA_MAPPER.SFL_SELLER_LANDING_PAGE]: {
        mid: 152687,
        lid: 166859
    },
    [TRACKING_DATA_MAPPER.SFL_VIEW_ITEM]: {
        mid: 152687,
        lid: 164875
    },
    [TRACKING_DATA_MAPPER.VIEW_OFFER_ELIGIBLE_ITEMS]: {
        mid: 152686,
        lid: 166858
    },
    [TRACKING_DATA_MAPPER.CONTINUE_TO_SIGN_IN]: {
        mid: 166018,
        lid: 180658
    },
    [TRACKING_DATA_MAPPER.CONTINUE_AS_GUEST]: {
        mid: 166018,
        lid: 180660
    },
    [TRACKING_DATA_MAPPER.OPEN_QUICK_VIEW]: {
        mid: 152686,
        lid: 181938
    },
    [TRACKING_DATA_MAPPER.CLOSE_QUICK_VIEW]: {
        mid: 152686,
        lid: 181939
    },
    [TRACKING_DATA_MAPPER.DYNAMIC_BANNER_EBAY_BALANCE_LEARN_MORE]: {
        mid: 170278,
        lid: 186817
    },
    [TRACKING_DATA_MAPPER.DYNAMIC_BANNER_VIEW]: {
        mid: 170278
    }
};
