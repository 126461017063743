import { Action } from '@ebay/experience-types-ebay';
import React, { Dispatch, ReactElement, ReactNode } from 'react';
import { BulkActionsData, LineItem, CartClientState, ShoppingCartListingSummary, Seller, Shopper } from '../../../../common/utils/hydration';
import { CartStateCallback } from '../../types/cart-state-handler';
import { BulkAction } from './actions/types';

export enum BulkActionNames {
    BulkEditSelectAll = 'BULK_EDIT_SELECT_ALL',
    BulkRemoveSelected = 'BULK_REMOVE_SELECTED',
    DisableBulkMode = 'DISABLE_BULK_MODE',
    EnableBulkMode = 'ENABLE_BULK_MODE'
}
export type BulkActionsModules = string | null;

export enum BulkActionsActiveState { Active = 'active', Disabled = 'disabled', Initial = 'initial' }
export interface BulkActionsSelectedItem {
    lineItemId?: string;
}

export interface BulkActionsDataMap extends LineItem {
    itemId?: string;
    quantity?: number;
    transactionId?: string;
    variationId?: string;
    displayPrice?: number;
    lineItemSummaries?: ShoppingCartListingSummary[];
    seller?: Seller;
}

export type BulkActionsSetPricingCostSummary = Dispatch<React.SetStateAction<PricingCostSummary>>;

export type BulkActionsSetCostSummary = Dispatch<React.SetStateAction<PricingCostSummary>>;

export type BulkActionsSelectedItems = BulkActionsSelectedItem[];

export type BulkActionItemsMap = Map<string, BulkActionsDataMap>;

export type BulkActionsSetSelectAllCheckboxChecked = Dispatch<React.SetStateAction<boolean>>;

export type BulkActionsSetTourtipManuallyClosed = Dispatch<React.SetStateAction<boolean>>;

export type BulkActionsSetSelectedItems = Dispatch<React.SetStateAction<BulkActionsSelectedItems>>;

export type BulkActionActiveModuleChange = (module: BulkActionsModules) => void;

export type BulkActionSelectAll = (lineItemIds: LineItem[], isChecked: boolean) => void;

export type BulkActionCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, lineItemId: string, activeBulkActionsModule: BulkActionsModules, listingSummaryQuantity: number, itemDisabled?: boolean, transactionId?: string, variantionId?: string, itemId?: string, displayPrice?: number, lineItemSummaries?: ShoppingCartListingSummary[], seller?: Seller) => void;

export type BulkActionsGetPriceDetailsFromService = (itemMap: BulkActionItemsMap) => void;

export interface BulkActionsProps {
    moduleName: BulkActionsModules;
    data: BulkActionsData;
    title?: string;
    lineItems?: BulkActionsSelectedItems;
    onBulkAction: (action?: BulkAction) => Promise<void>;
    selectedItems?: BulkActionsSelectedItems;
}
export interface BulkActionsContentProps extends BulkActionsProps {
    activeState: BulkActionsActiveState.Active | BulkActionsActiveState.Disabled | BulkActionsActiveState.Initial;
    buttonQuantitySelected?: number;
    activeBulkActionsModule: BulkActionsModules;
    bulkActionItemsMap: BulkActionItemsMap;
    editable?: boolean;
    handleAction: (event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<HTMLInputElement>, action: Action | undefined) => void;
    selectedBulkActionItems?: BulkActionsSelectedItems;
    selectAllCheckboxChecked: boolean;
}
export interface BulkActionEditableContentProps {
    activeState: BulkActionsActiveState.Active | BulkActionsActiveState.Disabled | BulkActionsActiveState.Initial;
    buttonQuantitySelected: number;
    bulkActionItemsMap: BulkActionItemsMap;
    data: BulkActionsData;
    handleAction: (event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<HTMLInputElement>, action: Action | undefined) => void;
    selectAllCheckboxChecked: boolean;
    moduleName: BulkActionsModules;
}
export interface BulkActionInactiveContentProps {
    activeState: BulkActionsActiveState.Active | BulkActionsActiveState.Disabled | BulkActionsActiveState.Initial;
    activeBulkActionsModule: BulkActionsModules;
    data: BulkActionsData;
    handleOnTourtipCollapse: () => void;
    children?: React.ReactNode;
    shoppingCartBulkActionsTourTip?: boolean;
    shouldRenderTourtip?: boolean;
    moduleName: BulkActionsModules;
    handleAction: (event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<HTMLInputElement>, action: Action | undefined) => void;
}

export type PricingCostSummary = {
    itemSubtotalPrice?: string;
    totalAppliedPromotions?: string;
    totalPrice?: string;
    shippingPrice?: string;
};

export interface BulkActionsHookData {
    allItemsMap?: BulkActionItemsMap;
    activeBulkActionsModule: BulkActionsModules;
    bulkActionItemsMap: BulkActionItemsMap;
    bulkXoOrderTotal: string;
    buttonQuantitySelected: number;
    cartBulkXoEnabled?: boolean;
    costSummary?: PricingCostSummary;
    pricingCostSummary?: PricingCostSummary;
    isSessionCTADisabled: () => boolean;
    onBulkActionActiveModuleChange: BulkActionActiveModuleChange;
    onBulkActionCheckboxChange: BulkActionCheckboxChange;
    onClearBulkActionMap: () => void;
    onSelectAllClick: BulkActionSelectAll;
    quantityTotal: number;
    resetState: () => void;
    showItemDiscountsPricelinesLoadingState?: boolean;
    showItemPricelinesLoadingState?: boolean;
    selectAllCheckboxChecked: boolean;
    selectAllClicked?: boolean;
    selectedBulkActionItems?: BulkActionsSelectedItems;
    setSelectAllCheckboxChecked: BulkActionsSetSelectAllCheckboxChecked;
    setSelectedBulkActionItems: BulkActionsSetSelectedItems;
    setShouldRenderBulkActionsTourtip: Dispatch<React.SetStateAction<boolean>>;
    setTourtipManuallyClosed: BulkActionsSetTourtipManuallyClosed;
    shoppingCartBulkActionsTourTip?: boolean;
    shouldRenderBulkActionsTourtip?: boolean;
    handleBulkActionServiceCall?: () => void;

    setPricingCostSummary?: BulkActionsSetPricingCostSummary;
    setCostSummary?: BulkActionsSetCostSummary;
}
export interface BulkActionContextProviderProps {
    children?: ReactNode;
    initialState?: CartClientState;
    cartState?: CartClientState;
    onBulkSelection?: CartStateCallback;
}

export interface BulkActionsTourtipOverlayStyle {
    top?: string | number;
    right?: string | number;
    bottom?: string | number;
    left?: string | number;
}
export interface BulkActionTourtipInactiveContentProps {
    children?: ReactElement;
    data?: BulkActionsData;
    pointer: 'left-top' | 'left-bottom' | 'top-left' | 'top-right' | 'right-top' | 'right-bottom' | 'bottom-right' | 'bottom-left';
    onCollapse?: () => void;
    overlayStyle?: BulkActionsTourtipOverlayStyle;
}

export interface BulkItemQtyAndPriceDetails {
    bulkXoQuantity?: number;
    bulkXoDisplayPrice?: number;
    quantityTotal?: number;
    bulkActionItemsMap?: BulkActionItemsMap;
    allItemsMap?: BulkActionItemsMap;
}

export interface BulkSelectRequestPayload {
    lineItemId?: string;
    selected?: boolean;
}

export interface BulkSelectRequest {
    selections?: BulkSelectRequestPayload[];
    selectAll?: boolean;
}

// Dynamic Bulk XO - Item Pricelines types
export interface CurrencyValue {
    value?: number;
    convertedFromValue?: number;
    convertedFromCurrency?: string;
    currency?: string;
}

export interface PricelinesCostSummary {
    itemSubtotalPrice?: CurrencyValue;
    totalAppliedPromotions?: CurrencyValue;
    totalPrice?: CurrencyValue;
}

export interface ProFormaOrderDetails {
    referenceId?: string;
    shopper?: Shopper;
    sellerProFormaOrders?: any;
    pricingDetails?: any;
    costSummary?: PricelinesCostSummary;
}

export interface ProFormaOrder {
    proFormaOrder?: ProFormaOrderDetails;
}

export interface PriceLinesDetails {
    pricelines: ProFormaOrder;
    result?: string;
}

export interface CartSummaryPricelines {
    modules: PriceLinesDetails;
}