import { TextualDisplay } from '@ebay/experience-types-ebay';
import { CartStateCallback } from '../../../../common/types/cart-state-handler';

export interface ActionsMenuProps {
    className?: string;
    title?: TextualDisplay;
    actions?: TextualDisplay[];
    cancelAction?: TextualDisplay;
    onPayOnlyThisSeller?: CartStateCallback;
    sellerId?: string;
}

export enum Actions {
    PayOnlyThisSeller = 'PAY_ONLY_THIS_SELLER',
    RedirectToCheckout = 'REDIRECT_TO_CHECKOUT'
}

export interface PayOnlyThisSellerRequest {
    sellerName?: string;
}
