import classNames from 'classnames';
import { forwardRef, Ref } from 'react';
import { EbayButton } from '@ebay/ui-core-react/ebay-button';
import { EbayTrackingClick } from '@ebay/ebayui-app-react/ebay-tracking';
import { TextSpans } from '../text-spans';
import type { TextDisplayProps } from './types';
import type { MouseEvent as ReactMouseEvent } from 'react';

export const OperationTextDisplay = forwardRef(({
    data,
    disabled = false,
    className,
    variant,
    priority,
    testId,
    lineItemId,
    onClick,
    clientTrackingData
}: TextDisplayProps, ref?: Ref<HTMLButtonElement>) => {
    if (!data) {
        return null;
    }

    const handleClick = (event: MouseEvent | ReactMouseEvent) => {
        if (typeof onClick === 'function') {
            onClick(event, data.action);
        }
    };

    return (
        <EbayTrackingClick trackingList={clientTrackingData}>
            {variant === 'fake-link' ? (
                <button
                    className={classNames(className, 'fake-link')}
                    type="button"
                    data-test-id={testId}
                    data-line-item-id={lineItemId}
                    disabled={disabled}
                    aria-label={data.accessibilityText}
                    onClick={handleClick}
                    ref={ref}
                    data-track={JSON.stringify(clientTrackingData)}>
                    <TextSpans textSpans={data.textSpans} />
                </button>
            ) : (
                <EbayButton
                    className={className}
                    type="button"
                    priority={priority}
                    data-test-id={testId}
                    data-line-item-id={lineItemId}
                    disabled={disabled}
                    aria-label={data.accessibilityText}
                    onClick={handleClick}
                    data-track={JSON.stringify(clientTrackingData)}>
                    <TextSpans textSpans={data.textSpans} />
                </EbayButton>
            )}
        </EbayTrackingClick>
    );
});

OperationTextDisplay.displayName = 'OperationTextDisplay';
