import { EbayIcon } from '@ebay/ui-core-react/ebay-icon';
import { TextDisplay } from '../../../../../common/components/text-display';
import { getIconClass } from './domain/specifications';
import { AdjustmentProps, BrandingType } from './types';

export function Adjustment({ adjustment }: AdjustmentProps) {
    if (!adjustment) {
        return null;
    }

    const iconClass = getIconClass(adjustment.icon?.name as BrandingType);
    const isHidden = Boolean(adjustment.action?.accessibilityText);

    return (
        <>
            {adjustment.applied ? <EbayIcon className="adjustment-confirmation-icon" name="confirmation24" /> : null}

            <div className="adjustment-content">
                {iconClass ? (
                    <div className="adjustment-icon">
                        <span className={iconClass} />
                    </div>
                ) : null}

                {adjustment.title ? (
                    <div className="adjustment-title">
                        <TextDisplay data={adjustment.title} />
                    </div>
                ) : null}

                {adjustment.message ? (adjustment.message.map((message, index) => (
                    <div key={index} className="adjustment-message">
                        <TextDisplay data={message} />
                    </div>
                ))) : null}
            </div>

            {adjustment.amount ? (
                <div aria-hidden={isHidden} className="adjustment-amount">
                    <TextDisplay data={adjustment.amount} />
                </div>
            ) : null}

            {adjustment.action ? (
                <EbayIcon name="chevronRight16" aria-hidden={isHidden} />
            ) : null}
        </>
    );
}
