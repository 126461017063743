import { QtagsProcessedData, TrackingEventPayload } from './types';

export const manualTagDropper = (treatmentId = '', qTags = {} as QtagsProcessedData, pageId = '') => {
    if (typeof window !== 'undefined' && treatmentId) {
        const today = new Date(Date.now());
        const data = {
            eventAction: 'TAGXT',
            eventFamily: 'EXPRMT',
            timestamp: today.toUTCString(),
            p: pageId,
            xt: treatmentId,
            '!xt': treatmentId,
            ...qTags
        } as TrackingEventPayload;
        window?._plsUBTTQ?.push?.(['customEvts', 'CART', 'ACTN', data]);
    }
};