import { TEST_IDS } from '../../common/utils/test-ids';
import { TextDisplay } from '../../common/components/text-display';
import { SurveyLinkProps } from './types';

import './styles.less';

export function SurveyLink({ survey }: SurveyLinkProps) {
    if (!survey?.surveyDetails?.length) {
        return null;
    }

    return (
        <>
            {survey.surveyDetails.map((surveyLink, index) => (
                <div key={index} id="survey-link" className="survey-link">
                    <TextDisplay
                        data={surveyLink}
                        variant="fake-link"
                        openNewWindow="_blank"
                        testId={TEST_IDS.surveyLink} />
                </div>
            ))}
        </>
    );
}
